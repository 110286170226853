import React from "react";
import { withStyles, WithStyles, createStyles } from "@material-ui/core";
import { photos } from "../data/photos";
import Gallery, { PhotoProps } from "react-photo-gallery";
import Lightbox from "react-images";

interface GalleryWithoutStylesProps extends WithStyles<typeof styles> {}

const GalleryWithoutStyles: React.FunctionComponent<
  GalleryWithoutStylesProps
> = (props: GalleryWithoutStylesProps) => {
  const [currentImage, setCurrentImage]: [
    number,
    (currentImage: number) => void
  ] = React.useState<number>(0);

  const [lightboxOpen, setLightboxOpen]: [
    boolean,
    (lightboxOpen: boolean) => void
  ] = React.useState<boolean>(false);

  return (
    <div>
      <Gallery
        photos={photos}
        onClick={(
          event: React.MouseEvent,
          photos: {
            index: number;
            next: PhotoProps | null;
            photo: PhotoProps;
            previous: PhotoProps | null;
          }
        ) => {
          setCurrentImage(photos.index);
          setLightboxOpen(true);
        }}
      />
      <Lightbox
        images={photos}
        onClose={() => {
          setCurrentImage(0);
          setLightboxOpen(false);
        }}
        onClickPrev={() => setCurrentImage(currentImage - 1)}
        onClickNext={() => setCurrentImage(currentImage + 1)}
        currentImage={currentImage}
        isOpen={lightboxOpen}
      />
    </div>
  );
};

const styles = createStyles({});

export default withStyles(styles)(GalleryWithoutStyles);
