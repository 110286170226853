import React from "react";
import Layout from "../components/Layout";
import Map from "../components/Map";
import PageTitle from "../components/PageTitle";
import Card from "../components/Card";
import {
  Grid,
  createStyles,
  WithStyles,
  withStyles,
  Typography,
  Link,
} from "@material-ui/core";
import Address from "../components/Address";

interface ContactProps extends WithStyles<typeof styles> {}

const Contact = (props: ContactProps) => {
  const { classes } = props;
  return (
    <Layout>
      <PageTitle text="Contact" />
      <Grid container spacing={10} className={classes.flex}>
        <Grid item xs={12} md={9}>
          <Card withPadding={true}>
            <div className={classes.minHeight}>
              <Typography color="primary">
                The Phoenix is located just outside Cobham, Surrey. Old lane can
                be reached from junction 10 off the M25, follow the slip road
                onto the A3 towards Guildford and take the turning marked Ockham
                onto Old Lane.
              </Typography>
              <br />
              <Typography color="primary">
                Pass the Black Swan pub and turn left onto the unmade road when
                you see the sign for Surrey Poultry. When the track forks, stay
                right and go through the entrance into the club car park.
              </Typography>
              <br />
              <Typography color="primary">
                For new membership enquiries email us at{" "}
                <Link href="mailto:enquiries@phoenixcpc.co.uk">
                  <Typography color="secondary">
                    enquiries@phoenixcpc.co.uk
                  </Typography>
                </Link>
              </Typography>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card withPadding={true}>
            <div className={classes.minHeight}>
              <Address
                lines={[
                  "The Phoenix CPC",
                  "Old Lane",
                  "East Horsley",
                  "Cobham",
                  "Surrey",
                  "KT11 1NL",
                ]}
              />
            </div>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Map
              lat={51.299945866090304}
              lng={-0.4239101676827204}
              defaultZoom={13}
            />
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

const styles = createStyles({
  flex: {
    display: "flex",
  },
  minHeight: {
    minHeight: "140px",
    color: "red",
  },
});

export default withStyles(styles)(Contact);
