import React from "react";
import ReactPlayer from "react-player";
import { withRouter, RouteComponentProps } from "react-router";
import { useLocalStorageState } from "../hooks/localStorageHook";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";

interface SplashProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<{}> {}

const Splash: React.FunctionComponent<SplashProps> = ({
  classes,
  history,
}: SplashProps) => {
  const [hasBeenHereBefore, setHasBeenHereBefore] = useLocalStorageState<
    boolean
  >("hasBeenHereBefore", false);

  const redirect = () => {
    history.push("/home");
  };

  const onEnded = () => {
    setHasBeenHereBefore(true);

    redirect();
  };

  const onError = (error: any) => {
    console.error(error);

    redirect();
  };

  return (
    <div className="player-wrapper">
      <ReactPlayer
        className={classes.reactPlayer}
        url={process.env.PUBLIC_URL + "/videos/phoenix.mp4"}
        muted
        playing
        width="100%"
        height="100%"
        onEnded={onEnded}
        onError={onError}
      />
    </div>
  );
};

const styles = createStyles({
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "black",
  },
});

export default withRouter(withStyles(styles)(Splash));
