import React from "react";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography } from "@material-ui/core";
import background from "../images/footer.jpg";

interface AppFooterProps extends WithStyles<typeof styles> {}

const AppFooter = (props: AppFooterProps) => {
  const classes = props.classes;

  return (
    <React.Fragment>
      <Paper className={classes.footerContainer}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.container}>
              <div className={classes.grow1} />
              <div>
                <Typography noWrap>
                  &copy; Phoenix Clay Pigeon Club {new Date().getFullYear()}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

const styles = createStyles({
  footerContainer: {
    // backgroundImage:
    //  "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiP…AwLCAtMjM2LjAwMDAwMCkgIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=), linear-gradient(175deg, #FF5821, #6d5050)",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  container: {
    display: "flex",
    height: "250px",
    padding: "30px",
  },
  grow1: {
    flexGrow: 1,
  },
});

const Footer = withStyles(styles)(AppFooter);

export default Footer;
