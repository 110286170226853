import React from "react";
import AuthenticatedLayout from "../components/AuthenticatedLayout";
import PageTitle from "../components/PageTitle";
import { createStyles, Link, WithStyles, withStyles } from "@material-ui/core";
import Card from "../components/Card";
import whatsappQrCode from "../images/whatsAppQRCode.png";

interface WhatsAppProps extends WithStyles<typeof styles> {}

const WhatsApp = ({ classes }: WhatsAppProps) => {
  return (
    <AuthenticatedLayout>
      <PageTitle text="WhatsApp" />
      <Card withPadding={true}>
        <img src={whatsappQrCode} alt="qr_code" className={classes.qrcode} />
        <p>
          Click{" "}
          <Link href="https://chat.whatsapp.com/GsUpKdU7cTn4xYu8jds5i6">
            <span>here</span>
          </Link>{" "}
          or scan the QR code with your phone to join our Members WhatsApp
          community.
        </p>
      </Card>
    </AuthenticatedLayout>
  );
};

const styles = createStyles({
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
  },
  qrcode: {
    minWidth: "20%",
    maxWidth: 350,
    minHeight: "20%",
    maxHeight: 350,
  },
});

export default withStyles(styles)(WhatsApp);
