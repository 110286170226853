import React from "react";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import { createStyles, Link, WithStyles, withStyles } from "@material-ui/core";
import Card from "../components/Card";
import ad from "../images/advert_a5.jpg";

interface NewMembersProps extends WithStyles<typeof styles> {}

const NewMembers = ({ classes }: NewMembersProps) => {
  return (
    <Layout>
      <PageTitle text="New Members" />
      <Card withPadding={true}>
        <Link href="mailto:enquiries@phoenixcpc.co.uk">
          <img src={ad} alt="Advert" className={classes.center} />
        </Link>
      </Card>
    </Layout>
  );
};

const styles = createStyles({
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
  },
});

export default withStyles(styles)(NewMembers);
