import React from "react";
import {
  Typography,
  createStyles,
  WithStyles,
  withStyles
} from "@material-ui/core";
import classes from "*.module.css";

const styles = createStyles({
  inline: {
    display: "inline-block"
  },
  spacer: {
    marginLeft: 20
  }
});

interface PageTitleProps extends WithStyles<typeof styles> {
  text: string;
  render?: React.FC<any>;
}

const PageTitle: React.FunctionComponent<PageTitleProps> = ({
  classes,
  text,
  render
}) => {
  return (
    <React.Fragment>
      <div className={classes.inline}>
        <Typography component="h2" variant="h3" gutterBottom>
          {text}
        </Typography>
      </div>
      <div className={`${classes.inline} ${classes.spacer}`}>
        {render != undefined && render({})}
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(PageTitle);
