import React from "react";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import { useLocalStorageState } from "../hooks/localStorageHook";
import { Button } from "@material-ui/core";

interface ClearLocalStorageProps {}

export const ClearLocalStorage: React.FunctionComponent<ClearLocalStorageProps> = (
  props: ClearLocalStorageProps
) => {
  const [hasBeenHereBefore, setHasBeenHereBefore] = useLocalStorageState<
    boolean
  >("hasBeenHereBefore", false);
  const [
    authenticatedTimestamp,
    setAuthenticatedTimestamp
  ] = useLocalStorageState<number>("authenticatedTimestamp", 0);

  //   let success: boolean = true;
  //   try {
  //     setHasBeenHereBefore(false);
  //   } catch (error) {
  //     console.log(error);

  //     success = false;
  //   }

  return (
    <Layout>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setHasBeenHereBefore(false);
          setAuthenticatedTimestamp(0);
        }}
      >
        Clear Local Storage
      </Button>
    </Layout>
  );
};
