import React from "react";
import AuthenticatedLayout from "../components/AuthenticatedLayout";
import PageTitle from "../components/PageTitle";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import MembershipFormDownload from "../components/MembershipFormDownload";

interface MembershipProps extends WithStyles<typeof styles> {}

const Membership = (props: MembershipProps) => {
  return (
    <AuthenticatedLayout>
      <PageTitle text="Membership" />

      <MembershipFormDownload />
    </AuthenticatedLayout>
  );
};

const styles = createStyles({});

export default withStyles(styles)(Membership);
