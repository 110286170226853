import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", "CLASS 01/01/20", "PERCENTAGE", "Raw Average", "No. shoots", "Total score", "Jan 5th", "Jan 19th", "Feb 2nd", "Feb 16th", "Mar 1st", "Mar 15th", "Apr 5th", "Apr 19th", "May 3rd", "May 17th", "June 7th", "June 21st", "July 5th", "July 19th", "Aug 2nd", "Aug 16th", "Sept 6th", "Sept 20th", "Oct 4th", "Oct 18th", "Nov 1st", "Nov 15th", "Dec 6th", "Dec 20th"];

export const results: (string | number | undefined)[][] = [
["ALLEN JOHN", "C", 38.8, 19.4, 5, 97, 20, , 25, , 12, , , , , , , , , 23, , , , 17, , , , , , ]
,["BARROW DAVID", "NM", 51.3, 25.6, 6, 154, , , 37, , 24, , , , , , 27, , , , 27, , 23, , , 16, , , , ]
,["BEALING DAVID", "NM", 58.0, 29.0, 3, 87, , , , , , , , , , , , , , , , , , 28, , 30, 29, , , ]
,["BIRD ARTHUR", "C", 36.7, 18.3, 3, 55, 13, , , , , , , , , , , , , , , , , 21, , , 21, , , ]
,["CAMERON DEAN", "B", 65.0, 32.5, 9, 292, 29, 42, , , , 29, , , , , 33, 30, , , , , 31, , 43, 25, 30, , , ]
,["CATLING RICHARD", "B", 53.0, 26.5, 2, 53, 23, , 30, , , , , , , , , , , , , , , , , , , , , ]
,["COLE BERNARD", "A", 60.0, 30.0, 12, 360, 31, 27, , , 29, , , , , , 36, 30, 34, 26, 30, 25, 35, 24, , 33, , , , ]
,["CUNNINGHAM SAM", "A", 62.7, 31.3, 3, 94, , 39, 34, , 21, , , , , , , , , , , , , , , , , , , ]
,["DAWSON PETER", "A", 66.2, 33.1, 13, 430, 36, 34, 38, 34, 32, 31, , , , , 32, 37, , , , 26, 31, 32, 38, , 29, , , ]
,["DE SILVA RICKY", "B", 54.8, 27.4, 17, 466, 24, 25, 33, 32, 22, 23, , , , , 21, 20, 30, 29, 33, 28, 29, 27, 31, 30, 29, , , ]
,["DUFFELL ERIC", "A", 71.0, 35.5, 12, 426, 30, , 42, , , , , , , , 34, 38, 32, 34, 30, 40, 41, , 44, 30, 31, , , ]
,["DUKE NEIL", "A", 60.3, 30.2, 9, 271, 29, , , , 30, , , , , , 30, 31, , , 28, , 26, 30, 37, 30, , , , ]
,["ELYSEE MICHAEL", "B", 62.0, 31.0, 1, 31, , , , , , , , , , , , , , , , , , , 31, , , , , ]
,["ESCOTT PAUL", "C", 50.0, 25.0, 1, 25, , , , , , , , , , , , , , , , , , , , 25, , , , ]
,["ESCOTT RICHARD", "AAA", 73.5, 36.7, 17, 625, 31, 45, 38, 33, 34, 37, , , , , 30, 38, 34, 30, 43, 42, 36, 39, 42, 42, 31, , , ]
,["ESCOTT TIM", "AAA", 80.9, 40.5, 15, 607, 36, , 42, 39, 40, 41, , , , , , 43, 48, 41, 41, 43, 40, 34, 43, 36, 40, , , ]
,["FARAZAMAND J", "A", 77.0, 38.5, 2, 77, 34, 43, , , , , , , , , , , , , , , , , , , , , , ]
,["FARAZAMAND A", "B", 64.0, 32.0, 2, 64, 30, 34, , , , , , , , , , , , , , , , , , , , , , ]
,["FIANDER PETER", "B", 51.0, 25.5, 4, 102, 26, , 33, , , 25, , , , , 18, , , , , , , , , , , , , ]
,["GRIMMER SIMON", "NM", 59.7, 29.8, 10, 298, , , , , 31, 18, , , , , , , 29, 32, , 31, 36, 35, 38, 28, 20, , , ]
,["HEALEY LES", "AAA", 83.4, 41.7, 15, 626, 36, 44, 44, 43, 39, , , , , , , 40, 44, 43, 43, 34, 38, 47, 46, 41, 44, , , ]
,["HICKMAN EDWARD", "C", 46.3, 23.2, 6, 139, 22, , 24, , , , , , , , 19, , , , , , , 17, 31, , 26, , , ]
,["HICKMAN JACK (Colt)", "C", 46.0, 23.0, 1, 23, , , , , , , , , , , , , , , , , , 23, , , , , , ]
,["HOLLOWAY NICK", "A", 57.1, 28.5, 10, 285, , , , , , , , , , , 30, 32, 31, 23, 30, 30, 34, 27, , 21, 27, , , ]
,["HOOLEY PETER", "B", 54.6, 27.3, 5, 136, , 29, , , 30, , , , , , , , , , , 26, 26, 25, , , , , , ]
,["HUMPHREYS CLIFF", "C", 36.0, 18.0, 1, 18, 18, , , , , , , , , , , , , , , , , , , , , , , ]
,["HUTCHINGS WILLIAM", "B", 59.5, 29.8, 13, 387, 24, 36, 35, , 26, 18, , , , , 26, , 33, 29, 27, 28, , , 41, 35, 29, , , ]
,["HUTCHINGS LYNNE", "C", 33.0, 16.5, 12, 198, 17, 20, 27, , , 17, , , , , 12, , 10, 13, 14, 19, , , 16, 15, 18, , , ]
,["JACKSON COLIN", "C", 47.3, 23.6, 12, 284, 23, 26, 25, 29, 17, 14, , , , , , , , , 22, 25, 31, 24, , 25, 23, , , ]
,["JAY CHRIS", "AA", 74.3, 37.2, 13, 483, 33, 35, 40, 39, , , , , , , 38, 38, 41, , , 23, 40, 34, 48, 40, 34, , , ]
,["JOHNSON MICHAEL", "A", 61.5, 30.8, 12, 369, 33, 30, 35, 30, 25, 25, , , , , , , , 33, , 34, , 34, 32, 30, 28, , , ]
,["KING JONATHAN", "NM", 47.0, 23.5, 8, 188, , , 31, 22, , , , , , , 26, , , , 26, , 20, 16, , 24, 23, , , ]
,["MARCHETTI ALAN", "B", 64.0, 32.0, 2, 64, , , 34, , , , , , , , , , , 30, , , , , , , , , , ]
,["OFFEN COLIN", "AA", 68.0, 34.0, 5, 170, , 42, 39, , 40, , , , , , , , , , 22, , , , , 27, , , , ]
,["O'DRISCOLL GRAHAM", "B", 51.8, 25.9, 8, 207, 24, 31, , , 20, 22, , , , , , , , 25, 28, 24, , , , 33, , , , ]
,["O'GORMAN ROY", "AA", 63.6, 31.8, 5, 159, 30, , 34, , , , , , , , , , , , 29, 28, , , , 38, , , , ]
,["O'NEAL J", "NM", 28.0, 14.0, 2, 28, , , , , , , , , , , , , , , , , , 14, , , 14, , , ]
,["PAYNE BARRY", "A", 62.1, 31.0, 6, 186, , 33, , , , , , , , , , 40, 34, 28, , , 29, , , , 22, , , ]
,["PITT TIM", "AA", 74.3, 37.1, 1, 37, , , , , , , , , , , , , , , , , 37, , , , , , , ]
,["PRIOR NICK", "B", 62.3, 31.1, 3, 93, 32, 30, , , , , , , , , , , , , , , 31, , , , , , , ]
,["PVK", "A", 78.5, 39.3, 4, 157, , , 44, 34, , , , , , , , , , , 32, , , , 47, , , , , ]
,["SCOTLAND DAVID", "A", 40.0, 20.0, 4, 80, 21, , 20, , 18, , , , , , , , , , , 21, , , , , , , , ]
,["SHARP RUSSELL", "A", 63.9, 31.9, 16, 511, 40, 32, 32, 25, 33, , , , , , 32, 30, 30, 34, 34, 28, 30, 33, 40, 28, 30, , , ]
,["STANLEY ALEX", "C", 46.0, 23.0, 1, 23, , 23, , , , , , , , , , , , , , , , , , , , , , ]
,["STANLEY WARWICK ", "C", 34.4, 17.2, 9, 155, , 20, , 15, , , , , , , , , , 18, 16, 13, 15, , 21, 16, 21, , , ]
,["SULLIVAN MARTIN", "A", 67.2, 33.6, 12, 403, , , 38, 28, , 32, , , , , " ", 38, 31, 38, 32, 33, , 28, 44, 27, 34, , , ]
,["TAYLOR PETER", "A", 68.0, 34.0, 1, 34, 34, , , , , , , , , , , , , , , , , , , , , , , ]
,["THOMAS DUNCAN", "A", 63.1, 31.6, 15, 474, 37, 39, 38, 35, , , , , , , 30, 33, 20, 30, 25, 30, 29, 33, 38, 31, 26, , , ]
,["TRUIN FRANK", "A", 74.0, 37.0, 3, 111, 32, 35, 44, , , , , , , , , , , , , , , , , , , , , ]
,["TRUSTON MATT", "A", 61.1, 30.5, 12, 367, , 34, 33, , , 27, , , , , 29, 30, 29, 30, 34, , 29, , 41, 26, 25, , , ]
,["TURNER CARLY", "B", 52.0, 26.0, 10, 260, 21, , 39, , 16, , , , , , 26, 17, 31, , , 27, , 29, , 31, 23, , , ]
,["TURNER MICHAEL", "C", 68.0, 34.0, 1, 34, , , , , , , , , , , , 34, , , , , , , , , , , , ]
,["VARINDER DHIR", "C", 32.5, 16.2, 5, 81, 13, 24, , , , , , , , , , , , , , , 17, , , 18, 9, , , ]
,["VAUGHAN-PAYNE RICHARD", "NM", 23.3, 11.7, 3, 35, , , , , , , , , , , , , , 14, , , , 13, , 8, , , , ]
,["VOWLER ALAN", "C", 47.8, 23.9, 9, 215, 25, 36, , 24, 12, , , , , , , , , , 23, 19, , , 37, 25, 14, , , ]
,["WHITE ROGER", "C", 43.1, 21.6, 17, 367, 17, 26, 24, 23, 19, 15, , , , , 23, 22, 27, 17, 19, 19, 24, 19, 34, 23, 16, , , ]
,["WILLIAMS SID", "B", 50.6, 25.3, 10, 253, 22, 27, 37, 34, 15, 22, , , , , , , , , , 22, 30, 19, , , 25, , , ]
,["WOODHAMS FRED", "B", 52.0, 26.0, 1, 26, 26, , , , , , , , , , , , , , , , , , , , , , , ]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 36, "average": 27.0, "high": 40.0, "low": 13.0},
{"numberOfEntrants": 29, "average": 32.4, "high": 45.0, "low": 20.0},
{"numberOfEntrants": 31, "average": 34.5, "high": 44.0, "low": 20.0},
{"numberOfEntrants": 17, "average": 30.5, "high": 43.0, "low": 15.0},
{"numberOfEntrants": 23, "average": 25.4, "high": 40.0, "low": 12.0},
{"numberOfEntrants": 16, "average": 24.8, "high": 41.0, "low": 14.0},
{"numberOfEntrants": 20, "average": 27.6, "high": 38.0, "low": 12.0},
{"numberOfEntrants": 19, "average": 32.7, "high": 43.0, "low": 17.0},
{"numberOfEntrants": 18, "average": 31.6, "high": 48.0, "low": 10.0},
{"numberOfEntrants": 22, "average": 28.2, "high": 43.0, "low": 13.0},
{"numberOfEntrants": 24, "average": 28.7, "high": 43.0, "low": 14.0},
{"numberOfEntrants": 26, "average": 27.6, "high": 43.0, "low": 13.0},
{"numberOfEntrants": 26, "average": 30.3, "high": 41.0, "low": 15.0},
{"numberOfEntrants": 27, "average": 26.7, "high": 47.0, "low": 13.0},
{"numberOfEntrants": 23, "average": 37.5, "high": 48.0, "low": 16.0},
{"numberOfEntrants": 32, "average": 27.7, "high": 42.0, "low": 8.0},
{"numberOfEntrants": 30, "average": 25.7, "high": 44.0, "low": 9.0},
];
