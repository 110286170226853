import React from "react";
import AuthenticatedLayout from "../components/AuthenticatedLayout";
import PageTitle from "../components/PageTitle";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import Gallery from "../components/Gallery";

interface PhotosProps extends WithStyles<typeof styles> {}

const Photos = (props: PhotosProps) => {
  return (
    <AuthenticatedLayout>
      <PageTitle text="Photos" />

      <Gallery />
    </AuthenticatedLayout>
  );
};

const styles = createStyles({});

export default withStyles(styles)(Photos);
