import React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  Typography,
} from "@material-ui/core";

interface AddressProps extends WithStyles<typeof styles> {
  lines: string[];
}

const Address: React.FunctionComponent<AddressProps> = (
  props: AddressProps
) => {
  const { classes } = props;

  const lines = props.lines.map((line: string, index: number) => (
    <React.Fragment key={index}>
      <Typography noWrap color="primary">
        {line}
      </Typography>
    </React.Fragment>
  ));

  return <div>{lines}</div>;
};

const styles = createStyles({});

export default withStyles(styles)(Address);
