import React from "react";
import { withStyles, createStyles, Typography } from "@material-ui/core";
import PageTitle from "../components/PageTitle";
import Card from "../components/Card";
import Layout from "../components/Layout";
import { RouteComponentProps } from "react-router";
import { useLocalStorageState } from "../hooks/localStorageHook";

interface LogoutProps extends RouteComponentProps<{}> {}

const styles = createStyles({});

const INITIAL_TIMEOUT = 5;
const INTERVAL = 1000;

const Logout = ({ history }: LogoutProps) => {
  const [countdown, setCountdown] = React.useState<number>(INITIAL_TIMEOUT);
  const [
    authenticatedTimestamp,
    setAuthenticatedTimestamp,
  ] = useLocalStorageState<number>("authenticatedTimestamp", 0);

  React.useEffect(() => {
    setAuthenticatedTimestamp(0);

    const updateCountdown = () => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    };

    const token = window.setInterval(updateCountdown, INTERVAL);

    return () => window.clearInterval(token);
  }, []);

  React.useEffect(() => {
    if (countdown === 0) {
      history.push("/home");
    }
  }, [countdown]);

  return (
    <Layout>
      <PageTitle text="Logout" />

      <Card withPadding>
        <Typography>
          You have been logged out and will be redirected to home in {countdown}
          ...
        </Typography>
      </Card>
    </Layout>
  );
};

export default withStyles(styles)(Logout);
