import React from "react";
import {
  WithStyles,
  createStyles,
  withStyles,
  colors,
} from "@material-ui/core";
import "../../node_modules/react-vis/dist/style.css";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  DiscreteColorLegend,
} from "react-vis";
import _ from "lodash";
import { Column, ColumnType } from "../data/results";
import { ResultsBase, ShootStat } from "../types/model";
import Card from "./Card";
import ResizeObserver from "resize-observer-polyfill";
import { getHeaderColumns } from "../services/resultsService";
import chroma from "chroma-js";

const spectralColors = chroma.scale("Spectral");

const MIN_HEIGHT: number = 50;
const MAX_HEIGHT: number = 350;
const MIN_WIDTH: number = 450;

interface DataSeriesProps {
  name: string;
  animation: { damping: number; stiffness: number };
  opacity: number;
  getNull: (value: any) => boolean;
  color: string;
  data: { x: string; y: string | number }[];
}

// This object structure is compatible with DiscreteColorLegend
interface LegendItem {
  name: string;
  title: string;
  color: string;
}

interface StatConfig extends LegendItem {
  name: "low" | "high" | "average" | "numberOfEntrants";
}

const statsConfigs: StatConfig[] = [
  { name: "high", color: "#ff2323", title: "High Score" },
  { name: "average", color: "#ff6f0f", title: "Average Score" },
  { name: "low", color: "#fff723", title: "Low Score" },
  { name: "numberOfEntrants", color: "#B5B5B7", title: "Number of Entrants" },
];

interface HeightWidth {
  height: number;
  width: number;
}

const seriesPropTemplate = {
  animation: { damping: 8, stiffness: 200 },
  opacity: 0.8,
  getNull: (value: any) => {
    return value.y != "";
  },
};

interface ResultsGraphProps extends WithStyles<typeof styles>, ResultsBase {
  stats: ShootStat[];
  filters: string[];
}

const ResultsGraph: React.FunctionComponent<ResultsGraphProps> = ({
  classes,
  columns,
  rowData,
  stats,
  filters,
}) => {
  const numberOfHeaderColumns: number = _.filter(
    columns,
    (x: Column) => x.type === ColumnType.header
  ).length;

  const resultsColumns: Column[] = _.slice(columns, numberOfHeaderColumns);
  const headerColumns: Column[] = getHeaderColumns(columns);

  let filteredResults = _.reverse(
    _.sortBy(
      rowData.filter((row: (string | number | undefined)[]) =>
        _.some(
          filters,
          (filter: string) => String(row[0]).toLowerCase().indexOf(filter) != -1
        )
      ),
      (row: (string | number | undefined)[]) => row[3]
    )
  );

  const dataSeriesProps: DataSeriesProps[] = statsConfigs.map(
    ({ name, color }: StatConfig) =>
      Object.assign(
        {
          name: name,
          color: filteredResults && filteredResults.length > 0 ? "grey" : color,
          data: stats.map((stat: ShootStat, index: number) => ({
            x: resultsColumns[index].header,
            y: stat[name],
          })),
        },
        seriesPropTemplate
      )
  );

  const individualSeriesColors = spectralColors.colors(
    filteredResults.length - 1
  );

  const individualSeriesProps: DataSeriesProps[] = filteredResults.map(
    (row: (string | number | undefined)[], index: number) => {
      const shootResults = row.slice(headerColumns.length);

      return Object.assign(
        {
          name: String(row[0]),
          color: individualSeriesColors[index],
          data: _.range(0, stats.length).map((index: number) => {
            const cell =
              shootResults.length > index ? shootResults[index] : undefined;

            return {
              x: resultsColumns[index].header,
              y: cell !== undefined ? cell : "",
            };
          }),
        },
        seriesPropTemplate
      );
    }
  );

  const dataSeriesLegendItems: LegendItem[] = dataSeriesProps.map(
    (series: DataSeriesProps) => ({
      name: series.name,
      title: series.name,
      color: series.color,
    })
  );

  const individualSeriesLegendItems: LegendItem[] = individualSeriesProps.map(
    (series: DataSeriesProps) => ({
      name: series.name,
      title: series.name,
      color: series.color,
    })
  );

  const [heightWidth, setHeightWidth] = React.useState<HeightWidth>({
    height: MAX_HEIGHT,
    width: 900,
  });

  React.useEffect(() => {
    const debounced = _.debounce(() => {
      const ro = new ResizeObserver((entries) => {
        for (const entry of entries) {
          //there is only one entry - entry.target.tagName===BODY
          const { height, width } = entry.contentRect;
          const calculatedHeight: number =
            height > MAX_HEIGHT
              ? MAX_HEIGHT
              : Math.max(height - 150, MIN_HEIGHT);
          const calculatedWidth: number =
            width > MIN_WIDTH ? width - 110 : MIN_WIDTH;
          setHeightWidth({
            height: calculatedHeight,
            width: calculatedWidth,
          });
        }
      });

      ro.observe(document.body);
    }, 500);

    return () => debounced.cancel();
  }, []);

  return (
    <div className={classes.root}>
      <Card withPadding>
        <div
          style={{
            overflow: "auto",
          }}
        >
          <DiscreteColorLegend
            orientation="horizontal"
            items={dataSeriesLegendItems}
          />
          <DiscreteColorLegend
            orientation="horizontal"
            items={individualSeriesLegendItems}
          />
          <XYPlot
            width={heightWidth.width}
            height={heightWidth.height}
            xType="ordinal"
          >
            <HorizontalGridLines />
            {dataSeriesProps.map((props, index) => {
              return <LineSeries {...props} key={`data_series_${index}`} />;
            })}

            {individualSeriesProps.map((props, index) => {
              return (
                <LineSeries {...props} key={`individual_series_${index}`} />
              );
            })}

            <XAxis
              style={{
                line: { stroke: "white" },
                text: { stroke: "none", fill: "white", fontWeight: 600 },
              }}
              tickLabelAngle={-35}
            />
            <YAxis
              style={{
                line: { stroke: "white" },
                text: { stroke: "none", fill: "white", fontWeight: 600 },
              }}
            />
          </XYPlot>
        </div>
      </Card>
    </div>
  );
};

const styles = createStyles({
  root: {
    "& .rv-discrete-color-legend.horizontal": {
      marginLeft: "30px",
    },
    "& .rv-discrete-color-legend-item__title": {
      color: "white",
    },
  },
});

export default withStyles(styles)(ResultsGraph);
