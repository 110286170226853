import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  Card as MaterialCard
} from "@material-ui/core";
import { Omit } from "../utils/typeUtils";

interface CardProps
  extends WithStyles<typeof styles>,
    Omit<CardProps, "classes"> {
  withPadding?: boolean;
  children?: React.ReactNode;
}

const Card: React.FunctionComponent<CardProps> = (props: CardProps) => {
  const classes = props.classes;

  return (
    <MaterialCard
      className={`${classes.root} ${props.withPadding ? classes.padding : ""} `}
    >
      {props.children}
    </MaterialCard>
  );
};

const styles = createStyles({
  root: {
    marginBottom: "15px",
    borderRadius: "10px",
    transition: "box-shadow .3s",
    "&:hover": {
      boxShadow: "0 0 11px rgba(11,11,11,1)"
    },
    "&:focus": {
      boxShadow: "0 0 11px rgba(11,11,11,1)"
    }
  },
  padding: {
    padding: "30px"
  }
});

export default withStyles(styles)(Card);
