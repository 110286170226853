const GALLERY_BASE_URL: string = "/photos/gallery/";

const getImageUrl = (index: number) =>
  process.env.PUBLIC_URL + `${GALLERY_BASE_URL}${index}.jpg`;

const getPhotoCount = () => photoRatios.length;

const photoRatios = [
  { width: 2, height: 3 }, //0
  { width: 3, height: 3 }, //1
  { width: 3, height: 4 }, //2
  { width: 4, height: 3 }, //3
  { width: 4, height: 3 }, //4
  { width: 4, height: 3 }, //5
  { width: 3, height: 4 }, //6
  { width: 5, height: 3 }, //7
  { width: 4, height: 4 }, //8
  { width: 4, height: 4 }, //9
  { width: 3, height: 3 }, //10
  { width: 3, height: 4 }, //11
  { width: 3, height: 4 }, //12
  { width: 4, height: 3 }, //13
  { width: 3, height: 3 }, //14
  { width: 3, height: 4 }, //15
  { width: 3, height: 4 }, //16
  { width: 3, height: 4 }, //17
  { width: 4, height: 3 }, //18
];

export const photos = (() =>
  Array.from(Array(getPhotoCount()).keys()).map((index: number) => {
    return Object.assign(
      {
        src: getImageUrl(index),
        // srcSet: [
        //   "https://source.unsplash.com/2ShvY8Lf6l0/500x375 500w",
        //   "https://source.unsplash.com/2ShvY8Lf6l0/800x600 800w",
        //   "https://source.unsplash.com/2ShvY8Lf6l0/1024x768 1024w",
        //   "https://source.unsplash.com/2ShvY8Lf6l0/1600x1200 1600w"
        // ],
        // sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
        // width: 4,
        // height: 3
      },
      photoRatios[index]
    );
  }))();
