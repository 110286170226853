import React from "react";

import { useLocalStorageState } from "../hooks/localStorageHook";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";

interface WithPasswordProps extends RouteComponentProps<{}> {
  children?: React.ReactNode;
}

const WithPassword = ({ children, location }: WithPasswordProps) => {
  const [
    authenticatedTimestamp,
    setAuthenticatedTimestamp
  ] = useLocalStorageState<number>("authenticatedTimestamp", 0);

  const isAuthenticated: boolean =
    authenticatedTimestamp !== 0 &&
    Date.now() - authenticatedTimestamp < 1000 * 60 * 60 * 2; //2 hours

  if (!isAuthenticated && authenticatedTimestamp > 0) {
    setAuthenticatedTimestamp(0);
  }

  return (
    <div>
      {!isAuthenticated ? (
        <Redirect to={`/login?return=${location.pathname}`} />
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </div>
  );
};

export default withRouter(WithPassword);
