import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", "CLASS 01/08/22", "PERCENTAGE", "Raw Average", "No. shoots", "Total score", "2nd Jan", "16th Jan", "6th Feb", "20th Feb", "6th Mar", "20th Mar", "3rd Apr", "17th Apr", "1st May", "15th May", "5th June", "19th June", "3rd July", "17th July", "7th Aug", "21st Aug", "4th Sept", "18th Sept", "2nd Oct", "16th oct", "6th Nov", "20th Nov", "4th Dec", "18th Dec"];

export const results: (string | number | undefined)[][] = [
["ALLEN JOHN", "B", 44.3, 22.2, 13, 288, , 27, 27, 15, 21, , 9, , , , 28, 20, , 27, 21, , 20, , , 22, , 28, 23, ]
,["ALLEN JAMES", "A", 53.5, 26.8, 4, 107, , , 42, , 27, 21, , , , , , , , , , 17, , , , , , , , ]
,["BARROW DAVID", "C", 42.0, 21.0, 4, 84, 18, , , , , , , , 19, , , 24, , , 23, , , , , , , , , ]
,["BEALING DAVID", "A", 65.3, 32.7, 3, 98, , , , , 34, , , , 31, , , 33, , , , , , , , , , , , ]
,["BIRD ARTHUR", "B", 58.0, 29.0, 1, 29, , , 29, , , , , , , , , , , , , , , , , , , , , ]
,["BROOKS DAVID", "C", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["BROWN DAVID", "AA", 76.0, 38.0, 2, 76, , , , , , , , , , , 36, , , , 40, , , , , , , , , ]
,["CAMERON DEAN", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["CATLING RICHARD", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["COLE BERNARD", "B", 54.7, 27.3, 21, 574, 22, 28, 31, , 22, 23, 19, 27, 34, , 33, 27, 27, 25, 29, 35, 23, 28, 32, 29, , 28, 30, 22]
,["CULLINGHAM SAM", "A", 64.0, 32.0, 11, 352, 24, 33, 32, 31, , , , 32, , , , , , , , , , , 37, 30, 29, 38, 33, 33]
,["CULLINGHAM T", "C", 21.0, 10.5, 2, 21, , 7, , , , , , , , , , , , , , , , , , , , 14, , ]
,["DAWSON PETER", "A", 58.3, 29.2, 19, 554, 22, 32, 32, 29, 20, 27, 17, , 33, , 34, 33, 25, 25, 22, 31, , 24, , 36, 36, 40, 36, ]
,["DE SILVA RICKY", "B", 48.7, 24.3, 16, 389, 19, 35, 30, , , , 15, 25, 28, 19, 28, 25, , 23, 24, 25, 23, 20, , , , , 21, 29]
,["DHIR VARINDER", "A", 52.3, 26.1, 19, 496, 15, 29, 38, , 27, , 15, 27, , 20, 34, 24, , 19, 17, 25, 25, 16, 41, 22, , 32, 34, 36]
,["DUFFELL ERIC", "AA", 69.9, 34.9, 13, 454, 26, 42, , 28, , , , , 33, , 37, 38, 34, 35, 32, , , , 40, , , 38, 39, 32]
,["DUKE NEIL", "B", 55.6, 27.8, 16, 445, 21, 37, , 27, 27, 25, 24, , , , 33, 30, 22, , 27, 21, 28, , 41, 27, 24, 31, , ]
,["ESCOTT PAUL", "A", 53.6, 26.8, 5, 134, , , , , 23, , , , , , , , 30, , , , 22, 28, , , , , 31, ]
,["ESCOTT RICHARD", "AA", 72.3, 36.1, 20, 723, , 43, 44, 36, 30, 36, 31, 35, 38, 34, 35, 40, 40, , 36, 38, 30, , 39, 33, 35, 36, , 34]
,["ESCOTT TIM", "AAA", 77.5, 38.8, 20, 775, , 46, 42, 36, 36, 34, 31, , 44, 39, 39, 40, 37, , 36, , 38, 31, 46, 38, 39, 44, 38, 41]
,["GAYWOOD LINDA", "C", 35.3, 17.7, 15, 265, 7, 27, 28, 16, , , 11, 14, 24, 15, , 16, , 17, , 18, 16, 11, , 23, , , 22, ]
,["FARAZAMAND A", "B", 59.0, 29.5, 2, 59, , 35, , , , , , , 24, , , , , , , , , , , , , , , ]
,["FARAZAMAND J", "B", 59.3, 29.7, 3, 89, 33, 26, , , , , , , 30, , , , , , , , , , , , , , , ]
,["HEALEY LES", "AAA", 75.6, 37.8, 22, 832, 33, 43, 45, 38, 43, 29, 26, 40, , 33, 40, , 32, 29, 37, 41, 40, 34, 46, 49, 35, 42, 32, 45]
,["HICKMAN EDWARD", "B", 45.0, 22.5, 2, 45, 16, , 29, , , , , , , , , , , , , , , , , , , , , ]
,["HOLLOWAY NICK", "B", 55.8, 27.9, 8, 223, 20, , , , 23, 34, , , , , , , 31, 26, , , 26, , , 27, , 36, , ]
,["HOOLEY PETER", "B", 46.0, 23.0, 8, 184, , , , , , , 23, , , , , 25, 23, 24, , 22, 29, , , 26, , , , 12]
,["HUMPHREYS CLIFF", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["HUTCHINGS WILLIAM", "A", 62.1, 31.0, 22, 683, 23, 39, 37, 33, 35, 29, 22, 21, 34, 26, 33, 40, 31, 33, , 27, 32, 20, 41, 33, 31, 35, 28, ]
,["HUTCHINGS LYNNE", "C", 31.3, 15.7, 18, 282, 10, 12, 29, 17, , 17, 5, 14, 17, 18, , 13, 13, 18, , 10, 15, 13, , 14, 23, , 24, ]
,["JACKSON COLIN", "C", 41.0, 20.5, 15, 308, 19, , 29, , 23, 30, , 20, 23, 16, , 21, , , 14, 22, , 17, , , 17, 22, 16, 19]
,["JAY CHRIS", "AA", 67.8, 33.9, 11, 373, , , , , , 37, 25, 34, , 34, 39, 36, , 36, , , 32, 33, , , 29, 38, , ]
,["JOHNSON MICHAEL", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["KING JONATHAN", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["MANUEL RAYNOR", "U", 70.1, 35.0, 5, 175, , , , , , , , , , , , , , , , 38, 28, 31, , , , , 31, 47]
,["MARCHETTI ALAN", "B", 50.7, 25.3, 3, 76, , 28, , , , , , , , , , , , 21, 27, , , , , , , , , ]
,["OFFEN COLIN", "A", 53.3, 26.7, 3, 80, , , , , 33, , , , , , , , 29, , , , 18, , , , , , , ]
,["O'CONNELL PAUL", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["O'CONNOR JOHN", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["O'DRISCOLL GRAHAM", "A", 59.4, 29.7, 11, 327, 20, 38, 38, , , , 24, 23, 26, , , , 30, 35, 21, , , , 44, , , , , 28]
,["O'GORMAN ROY", "B", 55.8, 27.9, 5, 139, , 30, , , , , , , 29, , , , 29, , , , , , , , , , 20, 31]
,["O'NEAL JULIAN", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["PAYNE BARRY", "C", 49.8, 24.9, 11, 274, 17, , , 23, , 22, , , , , , 22, 25, 26, 26, , , , 34, 25, , 26, 28, ]
,["PRIOR NICK", "A", 63.4, 31.7, 7, 222, , , 37, 26, , , , 31, 31, 36, 33, 28, , , , , , , , , , , , ]
,["PVK", "B", 57.7, 28.8, 13, 375, , 33, , 32, , 27, , 29, , 27, 21, , 27, 27, 35, , , , 38, 28, 29, , 22, ]
,["SALUSSOLIA ALEXANDER", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["SHARP RUSSELL", "A", 55.9, 28.0, 14, 391, 17, 36, 38, 18, , , , 31, 35, , 33, , 27, , 34, 34, 26, 15, , , 16, , , 31]
,["STANLEY ALEX", "C", 40.0, 20.0, 1, 20, , , , , , , , 20, , , , , , , , , , , , , , , , ]
,["STANLEY WARWICK ", "C", 38.4, 19.2, 16, 307, , 26, , 11, 17, 14, 11, 16, 12, , 18, , , 25, 22, , , 18, 32, , 24, 24, 18, 19]
,["SULLIVAN MARTIN", "AA", 71.4, 35.7, 10, 357, , , , , 35, , , , 31, 41, , , , 34, , , 36, , 44, 35, , 35, 33, 33]
,["THOMAS DUNCAN", "A", 56.6, 28.3, 22, 623, 18, 40, , 28, 20, 28, 18, 21, 28, 27, 36, 29, 32, 29, 31, 33, 31, , 20, 33, 30, 32, 28, 31]
,["TRUIN FRANK", "A", 58.0, 29.0, 3, 87, , , 40, , , 23, , 24, , , , , , , , , , , , , , , , ]
,["TRUSTON MATT", "A", 62.5, 31.3, 12, 375, 29, 35, , , , , , , 29, , , 32, 31, , 26, , , , 44, 30, 30, 33, 26, 30]
,["TURNER CARLY", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["VAUGHAN-PAYNE RICHARD", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["VOWLER ALAN", "B", 41.6, 20.8, 21, 437, 14, 28, 29, 16, 19, , 8, 17, 17, 25, 28, , , 22, 18, 17, 25, 16, 27, 20, 19, 27, 21, 24]
,["WHITE GRAHAM", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["WHITE ROGER", "C", 38.5, 19.2, 21, 404, , , 29, , 12, 15, 9, 21, 21, 21, 20, 23, 13, 21, 20, 11, 23, 14, 24, 21, 15, 20, 24, 27]
,["WILLIAMS SID", "B", 43.0, 21.5, 22, 473, 14, 29, 21, 16, 16, 7, 16, 26, 30, 16, , , 24, 29, 17, 22, 27, 11, 37, 21, 22, 28, 21, 23]
,["WOODHAMS FRED", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["WOODHAMS JACKIE", "U", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 23, "average": 19.9, "high": 33.0, "low": 7.0},
{"numberOfEntrants": 27, "average": 32.0, "high": 46.0, "low": 7.0},
{"numberOfEntrants": 23, "average": 33.7, "high": 45.0, "low": 21.0},
{"numberOfEntrants": 19, "average": 25.1, "high": 38.0, "low": 11.0},
{"numberOfEntrants": 21, "average": 25.9, "high": 43.0, "low": 12.0},
{"numberOfEntrants": 19, "average": 25.2, "high": 37.0, "low": 7.0},
{"numberOfEntrants": 20, "average": 17.9, "high": 31.0, "low": 5.0},
{"numberOfEntrants": 22, "average": 24.9, "high": 40.0, "low": 14.0},
{"numberOfEntrants": 25, "average": 28.0, "high": 44.0, "low": 12.0},
{"numberOfEntrants": 17, "average": 26.3, "high": 41.0, "low": 15.0},
{"numberOfEntrants": 20, "average": 31.9, "high": 40.0, "low": 18.0},
{"numberOfEntrants": 22, "average": 28.1, "high": 40.0, "low": 13.0},
{"numberOfEntrants": 22, "average": 27.8, "high": 40.0, "low": 13.0},
{"numberOfEntrants": 23, "average": 26.3, "high": 36.0, "low": 17.0},
{"numberOfEntrants": 24, "average": 26.5, "high": 40.0, "low": 14.0},
{"numberOfEntrants": 19, "average": 25.6, "high": 41.0, "low": 10.0},
{"numberOfEntrants": 23, "average": 26.7, "high": 40.0, "low": 15.0},
{"numberOfEntrants": 18, "average": 21.1, "high": 34.0, "low": 11.0},
{"numberOfEntrants": 19, "average": 37.2, "high": 46.0, "low": 20.0},
{"numberOfEntrants": 22, "average": 28.3, "high": 49.0, "low": 14.0},
{"numberOfEntrants": 18, "average": 26.8, "high": 39.0, "low": 15.0},
{"numberOfEntrants": 23, "average": 31.6, "high": 44.0, "low": 14.0},
{"numberOfEntrants": 25, "average": 27.2, "high": 39.0, "low": 16.0},
{"numberOfEntrants": 21, "average": 29.9, "high": 47.0, "low": 12.0},
];
