import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import queryString from "query-string";
import Card from "./Card";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
  withStyles,
  createStyles,
  WithStyles,
  Button,
  Snackbar,
} from "@material-ui/core";
import { useLocalStorageState } from "../hooks/localStorageHook";

interface LoginProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<{ location: string }> {}

const styles = createStyles({
  password: {
    marginBottom: 10,
  },
  login: {
    marginBottom: 10,
  },
  error: {
    marginTop: 90,
    padding: 15,
    backgroundColor: "#f44336",
    color: "white",
  },
});

const Login = ({ history, location, classes }: LoginProps) => {
  const [password, setPassword] = React.useState<string>("");
  const [error, setError] = React.useState<boolean>(false);
  const [
    authenticatedTimestamp,
    setAuthenticatedTimestamp,
  ] = useLocalStorageState<number>("authenticatedTimestamp", 0, () => {
    const returnUrl = queryString.parse(location.search)["return"];
    history.push(returnUrl as string);
  });

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  const login = () => {
    if (atob(password) === "®$q©") {
      setAuthenticatedTimestamp(Date.now());
    } else {
      setPassword("");
      setError(true);
    }
  };

  return (
    <Card withPadding={true}>
      <Typography variant="h2" gutterBottom>
        Login
      </Typography>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <React.Fragment>
          <div className={classes.error}> Incorrect password</div>
        </React.Fragment>
      </Snackbar>
      <TextField
        id="password"
        label="Password"
        variant="outlined"
        type="password"
        color="secondary"
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            login();
          }
        }}
        className={classes.password}
      />
      <br />
      <Button
        variant="contained"
        color="secondary"
        onClick={(e) => login()}
        className={classes.login}
      >
        Login
      </Button>
      <Typography>
        We ask that members login to areas of the site that contain personal
        information. Once logged in your session will timeout after 2 hours,
        after which time you will be asked to login again.
      </Typography>
    </Card>
  );
};

export default withStyles(styles)(withRouter(Login));
