import { ShootStat } from "../types/model";

export const headers: (string | undefined)[] = [
  "NAME",
  "CLASS 01/01/10",
  "PERCENTAGE",
  "Average",
  "No. shoots",
  "Total score",
  "JAN 3rd",
  "JAN 17th",
  "FEB 7th",
  "FEB 21st",
  "MARCH 7th",
  "MARCH 21st",
  ,
  "APR 4th",
  "APR 18th",
  "MAY 2nd",
  "MAY16th",
  "JUNE 6th",
  "JUNE 20th",
  ,
  "JULY 4th",
  "JULY 18th",
  "AUG 1st",
  "AUG 15th",
  "SEPT 5th",
  "SEPT 19th",
  ,
  "OCT 3rd",
  "OCT 17th",
  "NOV 7th",
  "NOV14th",
  "DEC 5th",
  "DEC 19th"
];

export const results: (string | number | undefined)[][] = [
  [
    "ABRAHAM PETER",
    "B",
    64.8,
    32.4,
    5,
    162,
    30,
    35,
    ,
    ,
    ,
    34,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    34,
    ,
    ,
    ,
    ,
    29,
    ,
    ,
    ,
    ,
  ],
  [
    "ANDON JOHN",
    "C",
    46.0,
    23.0,
    1,
    23,
    ,
    ,
    23,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "ASHROFF ASH",
    "C",
    47.5,
    23.8,
    4,
    95,
    ,
    ,
    ,
    21,
    ,
    30,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    16,
    28,
    ,
    ,
    ,
  ],
  [
    "BAILEY MARCUS",
    "B",
    46.5,
    23.3,
    4,
    93,
    25,
    30,
    21,
    17,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "BAILEY MIKE",
    "B",
    62.0,
    31.0,
    9,
    279,
    30,
    ,
    29,
    35,
    34,
    31,
    ,
    ,
    ,
    24,
    ,
    35,
    ,
    ,
    ,
    ,
    ,
    ,
    31,
    ,
    ,
    30,
    ,
    ,
    ,
    ,
  ],
  [
    "BIRD ARTHUR",
    "C",
    50.7,
    25.3,
    3,
    76,
    ,
    ,
    ,
    22,
    ,
    ,
    ,
    ,
    ,
    21,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    33,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "BLOMLEY MARTIN",
    "A",
    76.0,
    38.0,
    1,
    38,
    38,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "BOYTON DES",
    "B",
    60.0,
    30.0,
    4,
    120,
    24,
    32,
    38,
    26,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "BROOKS DAVE",
    "U",
    43.0,
    21.5,
    2,
    43,
    ,
    ,
    ,
    ,
    ,
    19,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    24,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "CATLING RICHARD",
    "AA",
    56.4,
    28.2,
    10,
    282,
    26,
    ,
    29,
    24,
    ,
    28,
    ,
    34,
    ,
    ,
    ,
    29,
    ,
    ,
    26,
    ,
    ,
    26,
    ,
    ,
    ,
    28,
    ,
    32,
    ,
    ,
  ],
  [
    "CHRISTIE KEN",
    "AA",
    77.5,
    38.8,
    12,
    465,
    38,
    41,
    43,
    31,
    37,
    43,
    ,
    38,
    ,
    ,
    ,
    40,
    ,
    ,
    ,
    ,
    ,
    44,
    35,
    40,
    ,
    ,
    ,
    35,
    ,
    ,
  ],
  [
    "COLE BERNARD",
    "A",
    67.0,
    33.5,
    14,
    469,
    33,
    35,
    ,
    ,
    28,
    30,
    ,
    31,
    ,
    ,
    ,
    38,
    28,
    ,
    40,
    34,
    ,
    37,
    30,
    30,
    ,
    ,
    38,
    37,
    ,
    ,
  ],
  [
    "COOK ALAN",
    "C",
    50.0,
    25.0,
    4,
    100,
    23,
    29,
    26,
    22,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "DAWSON PETER",
    "AA",
    71.3,
    35.7,
    12,
    428,
    ,
    ,
    33,
    36,
    33,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    28,
    ,
    35,
    38,
    41,
    44,
    32,
    ,
    ,
    39,
    30,
    39,
    ,
    ,
  ],
  [
    "DI MOND HANK",
    "AA",
    70.4,
    35.2,
    18,
    634,
    ,
    31,
    33,
    ,
    39,
    37,
    ,
    35,
    ,
    29,
    33,
    39,
    40,
    ,
    27,
    38,
    40,
    47,
    33,
    32,
    ,
    36,
    33,
    32,
    ,
    ,
  ],
  [
    "DUFFELL ERIC",
    "B",
    68.0,
    34.0,
    7,
    238,
    ,
    34,
    34,
    31,
    ,
    37,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    36,
    ,
    ,
    ,
    34,
    ,
    32,
    ,
    ,
  ],
  [
    "DUKE NEIL",
    "C",
    61.8,
    30.9,
    13,
    402,
    24,
    ,
    30,
    29,
    31,
    37,
    ,
    ,
    ,
    ,
    ,
    ,
    26,
    ,
    35,
    26,
    36,
    ,
    ,
    33,
    ,
    38,
    26,
    31,
    ,
    ,
  ],
  [
    "ESCOTT RICHARD",
    "A",
    72.6,
    36.3,
    14,
    508,
    36,
    41,
    34,
    33,
    38,
    36,
    ,
    ,
    ,
    40,
    ,
    24,
    ,
    ,
    ,
    34,
    ,
    44,
    39,
    ,
    ,
    38,
    32,
    39,
    ,
    ,
  ],
  [
    "ESCOTT TIM",
    "AA",
    69.3,
    34.7,
    6,
    208,
    34,
    ,
    40,
    31,
    37,
    30,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    36,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "EUSTACE DAVE",
    "AA",
    76.3,
    38.1,
    16,
    610,
    37,
    38,
    41,
    34,
    ,
    39,
    ,
    38,
    ,
    39,
    ,
    ,
    34,
    ,
    39,
    44,
    28,
    45,
    37,
    30,
    ,
    ,
    42,
    45,
    ,
    ,
  ],
  [
    "FERGUSON PHILIP",
    "A",
    64.1,
    32.1,
    16,
    513,
    32,
    39,
    29,
    24,
    41,
    29,
    ,
    ,
    ,
    32,
    ,
    31,
    26,
    ,
    29,
    ,
    ,
    39,
    36,
    27,
    ,
    26,
    36,
    37,
    ,
    ,
  ],
  [
    "FIANDER PETER",
    "B",
    62.7,
    31.4,
    14,
    439,
    ,
    ,
    33,
    28,
    34,
    37,
    ,
    33,
    ,
    22,
    ,
    30,
    ,
    ,
    30,
    ,
    ,
    32,
    35,
    36,
    ,
    27,
    34,
    28,
    ,
    ,
  ],
  [
    "HEALEY LES",
    "AA",
    73.8,
    36.9,
    11,
    406,
    35,
    ,
    41,
    36,
    ,
    ,
    ,
    ,
    ,
    37,
    ,
    ,
    40,
    ,
    39,
    41,
    33,
    ,
    ,
    35,
    ,
    31,
    ,
    38,
    ,
    ,
  ],
  [
    "HICKMAN E",
    "U",
    41.3,
    20.7,
    6,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    17,
    ,
    ,
    20,
    ,
    ,
    ,
    ,
    23,
    29,
    ,
    ,
    16,
    19,
    ,
    ,
    ,
  ],
  [
    "HICKMAN J (Colt)",
    "U",
    34.7,
    17.3,
    6,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    19,
    ,
    ,
    7,
    ,
    ,
    ,
    ,
    20,
    27,
    ,
    ,
    12,
    19,
    ,
    ,
    ,
  ],
  [
    "HOLLOWAY NICK",
    "A",
    66.7,
    33.3,
    9,
    300,
    ,
    27,
    29,
    ,
    ,
    ,
    ,
    ,
    ,
    31,
    ,
    ,
    ,
    ,
    ,
    ,
    41,
    36,
    33,
    32,
    ,
    33,
    ,
    38,
    ,
    ,
  ],
  [
    "HOOLEY PETER",
    "C",
    60.7,
    30.3,
    9,
    273,
    31,
    ,
    ,
    ,
    36,
    ,
    ,
    28,
    ,
    ,
    ,
    ,
    26,
    ,
    ,
    34,
    ,
    ,
    33,
    28,
    ,
    ,
    22,
    35,
    ,
    ,
  ],
  [
    "HOWELL JOHN",
    "B",
    70.0,
    35.0,
    1,
    35,
    35,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "HUMPHREYS CLIFF",
    "B",
    63.9,
    31.9,
    16,
    511,
    ,
    34,
    30,
    29,
    32,
    ,
    ,
    34,
    ,
    32,
    ,
    34,
    25,
    ,
    34,
    24,
    36,
    33,
    32,
    ,
    ,
    30,
    32,
    40,
    ,
    ,
  ],
  [
    "JENNINGS OLIVER",
    "AA",
    51.3,
    25.7,
    3,
    ,
    ,
    ,
    ,
    ,
    21,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    23,
    ,
    ,
    ,
    ,
    33,
    ,
    ,
  ],
  [
    "JENNINGS THEO",
    "AA",
    54.0,
    27.0,
    4,
    ,
    ,
    ,
    ,
    ,
    23,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    26,
    25,
    ,
    ,
    ,
    34,
    ,
    ,
  ],
  [
    "KESHAVJEE RAHEEM",
    "C",
    41.7,
    20.8,
    6,
    125,
    ,
    ,
    ,
    14,
    ,
    ,
    ,
    ,
    ,
    18,
    ,
    ,
    ,
    ,
    ,
    27,
    ,
    ,
    ,
    19,
    ,
    19,
    28,
    ,
    ,
    ,
  ],
  [
    "LEJOUR RICHARD",
    "C",
    55.0,
    27.5,
    4,
    110,
    26,
    34,
    25,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    25,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "MADDOWS ALAN",
    "C",
    60.0,
    30.0,
    2,
    60,
    ,
    ,
    ,
    ,
    33,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    27,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "MARCHETTI ALAN",
    "B",
    69.6,
    34.8,
    5,
    174,
    ,
    35,
    ,
    ,
    ,
    ,
    ,
    37,
    ,
    ,
    ,
    36,
    27,
    ,
    ,
    39,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "MORGAN-RUSSELL TIM",
    "U",
    60.8,
    30.4,
    8,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    26,
    ,
    30,
    33,
    35,
    ,
    27,
    ,
    ,
    34,
    22,
    36,
    ,
    ,
  ],
  [
    "MUNRO ALISTAIR",
    "C",
    64.8,
    32.4,
    8,
    259,
    ,
    ,
    28,
    ,
    36,
    ,
    ,
    ,
    ,
    31,
    ,
    ,
    35,
    ,
    27,
    ,
    45,
    ,
    33,
    24,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "O'CONNELL PAUL",
    "A",
    50.0,
    25.0,
    1,
    25,
    ,
    ,
    ,
    ,
    25,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "O'CONNOR JOHN",
    "U",
    66.0,
    33.0,
    1,
    33,
    ,
    ,
    ,
    ,
    ,
    33,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "O'GORMAN ROY",
    "B",
    66.0,
    33.0,
    13,
    429,
    ,
    ,
    34,
    30,
    34,
    41,
    ,
    ,
    ,
    ,
    ,
    38,
    32,
    ,
    31,
    34,
    ,
    ,
    35,
    29,
    ,
    31,
    32,
    28,
    ,
    ,
  ],
  [
    "O'GORMAN T",
    "U",
    60.0,
    30.0,
    2,
    ,
    ,
    ,
    ,
    ,
    ,
    28,
    ,
    ,
    ,
    ,
    ,
    ,
    32,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "PASCOE JEREMY",
    "AA",
    66.6,
    33.3,
    7,
    233,
    ,
    ,
    ,
    ,
    24,
    38,
    ,
    ,
    ,
    ,
    ,
    29,
    38,
    ,
    33,
    ,
    ,
    ,
    34,
    ,
    ,
    ,
    37,
    ,
    ,
    ,
  ],
  [
    "PAYNE BARRIE",
    "AA",
    67.7,
    33.8,
    6,
    203,
    29,
    ,
    ,
    ,
    36,
    ,
    ,
    ,
    ,
    ,
    ,
    35,
    ,
    ,
    ,
    31,
    ,
    38,
    ,
    ,
    ,
    34,
    ,
    ,
    ,
    ,
  ],
  [
    "PEDDIE STEPHEN",
    "AA",
    71.0,
    35.5,
    14,
    497,
    33,
    ,
    33,
    27,
    37,
    38,
    ,
    40,
    ,
    29,
    ,
    ,
    40,
    ,
    33,
    ,
    38,
    42,
    ,
    ,
    ,
    29,
    38,
    40,
    ,
    ,
  ],
  [
    "RICKETTS DAVE",
    "B",
    59.5,
    29.8,
    12,
    357,
    25,
    28,
    33,
    34,
    31,
    ,
    ,
    35,
    ,
    32,
    ,
    ,
    27,
    ,
    20,
    29,
    ,
    41,
    ,
    ,
    ,
    22,
    ,
    ,
    ,
    ,
  ],
  [
    "SHARP RUSSELL",
    "U",
    53.8,
    26.9,
    10,
    269,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    24,
    ,
    ,
    ,
    27,
    32,
    ,
    19,
    29,
    ,
    30,
    26,
    32,
    ,
    18,
    32,
    ,
    ,
    ,
  ],
  [
    "TAGG GERALD",
    "AA",
    84.7,
    42.3,
    3,
    127,
    ,
    ,
    38,
    ,
    ,
    44,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    45,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "TAGG MARLENE",
    "C",
    52.0,
    26.0,
    2,
    52,
    ,
    ,
    28,
    ,
    ,
    24,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "TAYLOR PETER",
    "U",
    77.2,
    38.6,
    5,
    193,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    37,
    ,
    ,
    40,
    42,
    ,
    ,
    37,
    ,
    ,
    ,
    ,
    37,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "THOMAS DUNCAN",
    "AA",
    65.8,
    32.9,
    16,
    526,
    38,
    40,
    ,
    24,
    ,
    34,
    ,
    35,
    ,
    26,
    ,
    30,
    23,
    ,
    27,
    39,
    ,
    38,
    34,
    31,
    ,
    35,
    39,
    33,
    ,
    ,
  ],
  [
    "TRUIN FRANK",
    "AA",
    75.3,
    37.7,
    15,
    565,
    37,
    34,
    38,
    33,
    43,
    39,
    ,
    ,
    ,
    ,
    ,
    ,
    37,
    ,
    38,
    33,
    43,
    34,
    37,
    42,
    ,
    38,
    39,
    ,
    ,
    ,
  ],
  [
    "VERNON-KELL PETER  ",
    "U",
    81.3,
    40.7,
    3,
    122,
    ,
    ,
    ,
    ,
    ,
    41,
    ,
    43,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    38,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
  ],
  [
    "WALLACE ANDREW",
    "U",
    54.0,
    27.0,
    4,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    31,
    26,
    ,
    ,
    30,
    21,
    ,
    ,
    ,
  ]
];

export const shootStats: ShootStat[] = [
  { numberOfEntrants: 23, average: 31.3, high: 38.0, low: 23.0 },
  { numberOfEntrants: 18, average: 34.3, high: 41.0, low: 27.0 },
  { numberOfEntrants: 27, average: 32.3, high: 43.0, low: 21.0 },
  { numberOfEntrants: 24, average: 28.0, high: 36.0, low: 14.0 },
  { numberOfEntrants: 23, average: 33.2, high: 43.0, low: 21.0 },
  { numberOfEntrants: 25, average: 34.3, high: 44.0, low: 19.0 },
  { numberOfEntrants: 15, average: 34.8, high: 43.0, low: 24.0 },
  { numberOfEntrants: 17, average: 28.2, high: 40.0, low: 17.0 },
  { numberOfEntrants: 2, average: 36.5, high: 40.0, low: 33.0 },
  { numberOfEntrants: 16, average: 33.6, high: 42.0, low: 24.0 },
  { numberOfEntrants: 23, average: 29.4, high: 40.0, low: 7.0 },
  { numberOfEntrants: 20, average: 31.4, high: 40.0, low: 19.0 },
  { numberOfEntrants: 20, average: 33.4, high: 44.0, low: 24.0 },
  { numberOfEntrants: 13, average: 37.5, high: 45.0, low: 28.0 },
  { numberOfEntrants: 21, average: 36.2, high: 47.0, low: 20.0 },
  { numberOfEntrants: 25, average: 31.8, high: 39.0, low: 23.0 },
  { numberOfEntrants: 20, average: 31.6, high: 45.0, low: 19.0 },
  { numberOfEntrants: 26, average: 29.0, high: 39.0, low: 12.0 },
  { numberOfEntrants: 22, average: 30.9, high: 42.0, low: 19.0 },
  { numberOfEntrants: 21, average: 35.3, high: 45.0, low: 28.0 }
];
