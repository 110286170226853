import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", "CLASS 01/01/21", "PERCENTAGE", "Raw Average", "No. shoots", "Total score", "3rd Jan", "17th Jan", "7th Feb", "21st Feb", "7th Mar", "21st Mar", "4th Apr", "18th Apr", "2nd May", "16th May", "6th June", "20th June", "4th July", "18th July", "1st Aug", "15th Aug", "5th Sept", "19th Sept", "3rd Oct", "17th oct", "7th Nov", "21st Nov", "5th Dec", "19th Dec"];

export const results: (string | number | undefined)[][] = [
["ALLEN JOHN", "B", 43.1, 21.6, 10, 216, , , , , , , , , , , , , , , 25, 16, 20, 28, 17, 14, 25, 30, 18, 23]
,["BARROW DAVID", "B", 52.0, 26.0, 4, 104, , , , , , , , , , , , , , , , , , 27, 22, 25, , , , 30]
,["BEALING DAVID", "B", 57.8, 28.9, 12, 347, , , , , , , 28, 24, 23, 35, 38, 33, , 25, , , , 25, , 17, 36, 33, 30, ]
,["BIRD ARTHUR", "C", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["BROOKS DAVID", "NM", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["CAMERON DEAN", "A", 57.4, 28.7, 7, 201, , , , , , , , , , , , , , , 39, 23, 31, , , 32, 32, 13, 31, ]
,["CATLING RICHARD", "B", 55.3, 27.7, 6, 166, , , , , , , 23, 26, 30, , 30, , , , , , , 26, 31, , , , , ]
,["COLE BERNARD", "A", 55.1, 27.6, 16, 441, , , , , , , 30, 36, 25, 33, 35, , , 24, 31, 22, 21, 24, 23, 12, 35, 32, 32, 26]
,["DAWSON PETER", "A", 61.2, 30.6, 15, 459, , , , , , , 30, 24, 30, 40, 38, 30, 33, 40, 32, 26, , , 24, 24, , 32, 28, 28]
,["DE SILVA RICKY", "B", 56.8, 28.4, 17, 483, , , , , , , 24, 33, 32, 27, 34, 35, 30, 30, 31, 25, 24, 23, 32, 26, , 25, 27, 25]
,["DHIR VARINDER", "B", 49.9, 24.9, 14, 349, , , , , , , 23, 27, 23, 25, 31, , 19, 34, 27, , 24, 18, 21, 19, 31, 27, , ]
,["DUFFELL ERIC", "AA", 67.8, 33.9, 12, 407, , , , , , , 45, 32, 35, 33, , 33, , , , , 29, 26, 35, 32, 41, 34, 32, ]
,["DUKE NEIL", "B", 62.5, 31.3, 11, 344, , , , , , , , 35, , 35, 35, 45, 32, 35, 28, 26, 23, 25, , , , , , 25]
,["ESCOTT RICHARD", "AA", 75.2, 37.6, 17, 639, , , , , , , 40, 34, 35, 39, 36, , 39, 38, 36, 32, 38, 40, 38, 32, 43, 41, 38, 40]
,["ESCOTT TIM", "AAA", 77.9, 38.9, 14, 545, , , , , , , 45, , 40, 42, , , 45, 39, 31, 31, 41, 34, 44, 34, , 37, 43, 39]
,["GAYWOOD LINDA", "NM", 39.8, 19.9, 12, 239, , , , , , , , 20, 24, , 29, , 20, 18, 22, 15, , 14, 14, , , 14, 27, 22]
,["GRIMMER SIMON", "A", 60.0, 30.0, 4, 120, , , , , , , , 32, , 29, , , , , 28, , , , 31, , , , , ]
,["HEALEY LES", "AAA", 80.4, 40.2, 13, 522, , , , , , , , 46, , , , 48, 39, 33, , 40, 34, 42, 33, 34, 43, 46, 41, 43]
,["HICKMAN EDWARD", "B", 44.0, 22.0, 2, 44, , , , , , , , , , , 22, , , , , , , , , 22, , , , ]
,["HOLLOWAY NICK", "B", 57.8, 28.9, 12, 347, , , , , , , 32, 32, 28, 30, 37, 36, , 28, 27, , 21, 30, , 22, , , 24, ]
,["HOOLEY PETER", "A", 53.8, 26.9, 11, 296, , , , , , , , 30, 33, 27, , 35, 26, 24, , , 26, 25, 24, , , 26, 20, ]
,["HUMPHREYS CLIFF", "C", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["HUTCHINGS WILLIAM", "A", 59.3, 29.6, 17, 504, , , , , , , 35, 37, 27, 38, 35, , 27, 33, 29, 29, 26, 21, 30, 22, 30, 30, 25, 30]
,["HUTCHINGS LYNNE", "C", 33.6, 16.8, 16, 269, , , , , , , 6, 18, 16, 16, 28, , 12, 20, 16, 13, 15, 15, 20, 18, 12, 16, , 28]
,["JACKSON COLIN", "C", 43.9, 22.0, 13, 286, , , , , , , , 18, , 22, 26, 29, , 26, 27, 22, 25, 19, , , 20, 11, 18, 23]
,["JAY CHRIS", "AAA", 73.7, 36.8, 11, 405, , , , , , , 39, 44, , 39, 38, , 32, , , 29, , 40, , 36, 42, , 33, 33]
,["JOHNSON MICHAEL", "A", 57.3, 28.7, 10, 287, , , , , , , , 29, , 32, , , 32, 26, 32, , , 23, 33, , , 31, 21, 28]
,["KING JONATHAN", "B", 51.0, 25.5, 14, 357, , , , , , , 20, 26, , 23, 30, 33, 27, 26, 20, 31, 27, 23, 27, 21, , , , 23]
,["MARCHETTI ALAN", "A", 56.7, 28.3, 6, 170, , , , , , , 28, 34, , , , , , 28, 27, , 24, , , , , 29, , ]
,["OFFEN COLIN", "AA", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["O'CONNELL PAUL", "C", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["O'CONNOR JOHN", "C", 56.7, 28.3, 1, 28, , , , , , , , , , , , , , , , , , , , , , , , 28]
,["O'DRISCOLL GRAHAM", "B", 58.9, 29.4, 12, 353, , , , , , , 26, 33, 27, 32, 40, , , 31, 25, 18, 28, , , , , 27, 32, 34]
,["O'GORMAN ROY", "A", 67.5, 33.8, 4, 135, , , , , , , , , , 30, 35, 34, , , , , , , 36, , , , , ]
,["O'NEAL JULIAN", "C", 47.3, 23.7, 5, 118, , , , , , , , 26, , , 30, , , , , , , , 24, 15, , , , 23]
,["PAYNE BARRY", "A", 54.0, 27.0, 15, 405, , , , , , , 32, 24, 27, 37, 40, , 33, 26, 29, 18, 23, , 22, 20, 26, 26, 22, ]
,["PRIOR NICK", "A", 56.9, 28.4, 7, 199, , , , , , , , , , , , , , 31, 34, 18, , 21, , , 38, 32, 25, ]
,["PVK", "AA", 69.4, 34.7, 10, 347, , , , , , , , , 32, , 42, 40, 34, , 38, , , , 35, 28, , 22, 37, 39]
,["SALUSSOLIA ALEXANDER", "NM", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["SHARP RUSSELL", "A", 57.5, 28.8, 9, 259, , , , , , , 27, 31, 26, , 32, , 34, , 31, , , , , 29, 28, , , 21]
,["STANLEY ALEX", "C", 32.0, 16.0, 1, 16, , , , , , , , , , , , , , , , , , , , , , , 16, ]
,["STANLEY WARWICK ", "C", 38.7, 19.4, 9, 174, , , , , , , 16, 22, , , , , 20, , , 18, 23, 14, , 18, , , 19, 24]
,["SULLIVAN MARTIN", "A", 64.2, 32.1, 15, 482, , , , , , , 33, 40, 35, 34, 37, 39, 29, 33, , 27, , 23, 31, 24, , 32, 33, 32]
,["THOMAS DUNCAN", "A", 60.3, 30.1, 18, 543, , , , , , , 32, 29, 38, 36, 45, 41, 27, 34, 38, 20, 31, 21, 24, 16, 26, 31, 21, 33]
,["TRUIN FRANK", "A", 69.9, 35.0, 4, 140, , , , , , , , , , , , , , , , , , , , , 38, 36, 35, 31]
,["TRUSTON MATT", "B", 66.6, 33.3, 10, 333, , , , , , , , , 38, 26, 43, 37, , 39, , 27, , 26, 32, 29, , , 36, ]
,["TURNER CARLY", "B", 55.2, 27.6, 10, 276, , , , , , , 22, 28, , 32, 35, 37, , , 22, 21, , 20, 34, 25, , , , ]
,["VAUGHAN-PAYNE RICHARD", "C", 50.0, 25.0, 1, 25, , , , , , , , , , , , , , , , , , , , , , , , 25]
,["VOWLER ALAN", "C", 41.0, 20.5, 14, 287, , , , , , , 26, , 24, 29, 27, 18, , , 14, 17, 16, 13, 22, 13, 26, , 16, 26]
,["WHITE GRAHAM", "C", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["WHITE ROGER", "C", 41.8, 20.9, 12, 251, , , , , , , 23, 28, 19, , 28, 25, 17, , 16, 15, 16, 15, 28, , 21, , , ]
,["WILLIAMS SID", "B", 46.2, 23.1, 17, 392, , , , , , , 28, 20, 23, 30, 29, 29, 24, 19, 25, 18, , 20, 13, 16, 26, 18, 26, 28]
,["WOODHAMS FRED", "B", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["WOODHAMS JACKIE", "B", 0.0, 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 25, "average": 28.5, "high": 45.0, "low": 6.0},
{"numberOfEntrants": 31, "average": 29.6, "high": 46.0, "low": 18.0},
{"numberOfEntrants": 24, "average": 28.8, "high": 40.0, "low": 16.0},
{"numberOfEntrants": 27, "average": 31.5, "high": 42.0, "low": 16.0},
{"numberOfEntrants": 29, "average": 34.0, "high": 45.0, "low": 22.0},
{"numberOfEntrants": 19, "average": 34.6, "high": 48.0, "low": 18.0},
{"numberOfEntrants": 22, "average": 28.7, "high": 45.0, "low": 12.0},
{"numberOfEntrants": 25, "average": 29.6, "high": 40.0, "low": 18.0},
{"numberOfEntrants": 28, "average": 27.9, "high": 39.0, "low": 14.0},
{"numberOfEntrants": 26, "average": 23.0, "high": 40.0, "low": 13.0},
{"numberOfEntrants": 23, "average": 25.5, "high": 41.0, "low": 15.0},
{"numberOfEntrants": 30, "average": 24.0, "high": 42.0, "low": 13.0},
{"numberOfEntrants": 29, "average": 27.6, "high": 44.0, "low": 13.0},
{"numberOfEntrants": 29, "average": 23.3, "high": 36.0, "low": 12.0},
{"numberOfEntrants": 20, "average": 30.9, "high": 43.0, "low": 12.0},
{"numberOfEntrants": 26, "average": 28.1, "high": 46.0, "low": 11.0},
{"numberOfEntrants": 29, "average": 27.8, "high": 43.0, "low": 16.0},
{"numberOfEntrants": 28, "average": 28.9, "high": 43.0, "low": 21.0},
];
