import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", "CLASS 01/08/19", "PERCENTAGE", "Raw Average", "No. shoots", "Total score", "Jan 6th", "Jan 20th", "Feb 3rd", "Feb 17th", "Mar 3rd", "Mar 17th", "Apr 7th", "Apr 21st", "May 5th", "May 19th", "June 2nd", "June 16th", "July 7th", "July 21st", "Aug 18th", "Sept 1st", "Sept 15th", "Sept 29th", "Oct 6th", "Oct 20th", "Nov 3rd", "Nov 17th", "Dec 1st", "Dec 15th"];

export const results: (string | number | undefined)[][] = [
["ALLEN JOHN", "C", 39.3, 19.6, 8, 157, , , , , , , , , 15, 16, , , , 23, 22, 24, , , , 21, 18, 18, , ]
,["BIRD ARTHUR", "C", 39.0, 19.5, 2, 39, , , , , , , 16, , , , , , , , , 23, , , , , , , , ]
,["CAMERON DEAN", "B", 57.8, 28.9, 11, 318, , 28, , 30, 25, 27, 26, , 29, 32, , , , 27, , , , , 37, 28, , , , 29]
,["CATLING RICHARD", "B", 57.0, 28.5, 4, 114, 25, , , , , , , , , , , , , 28, , , , , , , 34, , 27, ]
,["COLE BERNARD", "A", 62.0, 31.0, 19, 589, 27, 35, , 30, 32, 33, 34, 28, , 34, 44, , , 30, 33, 29, 26, , 40, 25, 32, 24, 29, 24]
,["CUNNINGHAM SAM", "A", 65.3, 32.6, 16, 522, , 28, 35, 37, 33, 36, 27, 25, 29, 38, , 35, 26, , , 35, , , 41, , 35, 32, , 30]
,["DAWSON PETER", "A", 62.7, 31.3, 17, 533, 20, 32, 36, 31, 34, 27, 23, , , , 38, 36, 33, , 33, , 35, , 36, 32, , 25, 30, 32]
,["DE SILVA RICKY", "B", 53.6, 26.8, 14, 375, 22, 22, , 26, , , 24, 30, 24, , 29, 35, , , 22, 27, 28, , , , 37, , 25, 24]
,["DUFFELL ERIC", "A", 65.6, 32.8, 15, 492, 29, , 32, 31, , 27, 29, , 34, , , 42, 38, , 38, , 34, 31, , , 34, 29, 31, 33]
,["DUKE NEIL", "A", 62.9, 31.4, 8, 252, , 31, 27, , 34, , , , , , , , , , , , , , 31, , 39, 31, 31, 28]
,["EDWARDS STUART", "B", 58.0, 29.0, 1, 29, , , , , , , , , 29, , , , , , , , , , , , , , , ]
,["ELYSEE MICHAEL", "C", 42.0, 21.0, 2, 42, , , 16, , , , , , , , , , , , , , , , 26, , , , , ]
,["ESCOTT PAUL", "AA", 74.0, 37.0, 1, 37, , , , , , , 37, , , , , , , , , , , , , , , , , ]
,["ESCOTT RICHARD", "AAA", 77.9, 39.0, 23, 896, 36, 39, 40, 38, 38, 40, 40, 40, 42, 33, 36, 43, 40, , 44, 39, 42, 35, 45, 42, 41, 34, 34, 35]
,["ESCOTT TIM", "AAA", 82.1, 41.0, 22, 903, 38, 40, 45, 36, 44, 46, 40, 34, 48, 40, 42, 45, 41, , 37, , 40, 38, 47, 43, 41, 39, 39, 40]
,["FARAZAMAND J", "B", 60.0, 30.0, 2, 60, , 28, , , , , , , 32, , , , , , , , , , , , , , , ]
,["FARAZAMAND A", "B", 54.0, 27.0, 2, 54, , 27, , , , , , , 27, , , , , , , , , , , , , , , ]
,["FIANDER PETER", "B", 58.3, 29.1, 7, 204, , 32, , , , 28, , , , , 32, 29, 26, 23, , , , , , , 34, , , ]
,["HEALEY LES", "AAA", 84.4, 42.2, 23, 970, , 45, 36, 40, 38, 42, 42, 42, 48, 43, 44, 41, 46, 46, 43, 44, 43, 42, 45, 44, 47, 31, 39, 39]
,["HICKMAN EDWARD", "C", 45.3, 22.7, 6, 136, , , 16, , , , 17, , , , 24, , , , , 24, , , 26, , 29, , , ]
,["HICKMAN JACK (Colt)", "C", 47.3, 23.7, 3, 71, , , , , , , 25, , , , , , , , , 19, , , , , 27, , , ]
,["HOLLOWAY NICK", "A", 65.7, 32.8, 12, 394, 28, , , , , , 30, , , , 32, 35, 37, 28, 35, 29, 30, , 39, 31, 40, , , ]
,["HOOLEY PETER", "B", 52.3, 26.1, 15, 392, , , 26, 18, 20, , 20, 26, , 27, 31, 32, 26, 24, , 26, 27, , , , 31, , 28, 30]
,["HUMPHREYS CLIFF", "C", 12.0, 6.0, 1, 6, , , , , , , , , , , , , , , 6, , , , , , , , , ]
,["HUTCHINGS WILLIAM", "B", 55.9, 27.9, 20, 559, 24, 25, 26, , 33, 36, 24, 26, 24, , 28, 29, 27, 30, 23, 31, 27, , 32, , 37, 19, 27, 31]
,["HUTCHINGS LYNNE", "C", 32.2, 16.1, 16, 258, 17, 17, 16, 16, 16, 18, 27, , 21, , 9, 23, 17, , , , , , 14, , 12, 16, 8, 11]
,["JACKSON COLIN", "C", 45.5, 22.8, 17, 387, 20, 19, 19, 27, , , 19, 20, 19, , 26, 30, , , 27, 19, 23, , 28, 26, 24, 18, 23, ]
,["JAY CHRIS", "A", 71.8, 35.9, 18, 646, 36, 32, , 34, 23, 32, , 34, 38, 38, 40, 35, 40, , , 37, , 31, 46, 33, 41, 36, , 40]
,["JOHNSON MICHAEL", "A", 63.0, 31.5, 12, 378, 33, 37, 31, 26, , 28, 28, , , 29, , , , 38, , 28, , , 30, , 36, , 34, ]
,["KING JONATHAN", "NM", 50.0, 25.0, 2, 50, , , , , , , , , , , , , , , , , , 22, 28, , , , , ]
,["LEJOUR DICK", "B", 56.0, 28.0, 1, 28, , , , , , , , , , 28, , , , , , , , , , , , , , ]
,["MARCHETTI ALAN", "B", 52.0, 26.0, 4, 104, 27, , , , , , , , , , , , , 19, , , 26, 32, , , , , , ]
,["MORGAN CHRIS", "AA", 78.0, 39.0, 1, 39, , , , 39, , , , , , , , , , , , , , , , , , , , ]
,["O'CONNOR JOHN", "C", 39.3, 19.7, 3, 59, , 26, , 21, , , , , , , , , , 12, , , , , , , , , , ]
,["OFFEN COLIN", "AA", 73.8, 36.9, 15, 554, , 41, 42, 38, , 38, 36, , , 33, , , 38, 39, , 37, 36, 34, 36, 38, 40, , , 28]
,["O'DRISCOLL GRAHAM", "B", 52.7, 26.3, 18, 474, 17, 27, , 24, 18, 27, , 22, 28, , 27, 29, 28, 33, 26, 28, 25, , 33, , , 21, 31, 30]
,["O'GORMAN ROY", "A", 69.7, 34.9, 7, 244, , 39, 29, , , , 30, , , , 38, , 36, , , , , , 36, , 36, , , ]
,["PAYNE BARRY", "B", 55.3, 27.6, 11, 304, , 31, , , , 30, , , , 19, , , 26, 25, , 30, , , 35, , 36, 23, 29, 20]
,["PITT TIM", "AA", 78.0, 39.0, 2, 78, 40, , , , 38, , , , , , , , , , , , , , , , , , , ]
,["PRIOR NICK", "B", 53.6, 26.8, 19, 509, 25, 26, 26, 23, 22, 21, 23, 18, 25, 32, , , 35, 27, 22, 33, 28, , 34, 32, , 29, 28, ]
,["PVK", "A", 65.1, 32.5, 5, 163, , , , , , 30, , , , , , 27, 36, 33, , , , , , , , , , 37]
,["SCOTLAND DAVID", "C", 44.0, 22.0, 5, 110, , , , 31, , 18, , , , , , , 31, 16, , , , , , , , 14, , ]
,["SHARP RUSSELL", "A", 64.8, 32.4, 17, 551, 29, 25, , 31, 23, , , 36, 43, , 33, 32, , 35, 42, 33, 37, , 28, 31, 33, 25, , 35]
,["STANLEY ALEX", "NM", 38.0, 19.0, 1, 19, , , , , , , , , , , , , , , , 19, , , , , , , , ]
,["STANLEY WARWICK", "NM", 34.0, 17.0, 5, 85, , , , , , , , , , , , , , , , 23, 15, , 19, , 12, , 16, ]
,["SULLIVAN MARTIN", "A", 70.3, 35.2, 11, 387, , 33, 34, 37, , 36, , , , , 36, , , 36, , , , , 39, , 35, 35, 34, 32]
,["TAYLOR PETER", "AA", 70.5, 35.2, 12, 423, , 38, 34, 42, 34, 37, 35, 33, , , , 38, , 33, , 32, , , , , , 31, , 36]
,["THOMAS DUNCAN", "A", 60.8, 30.4, 21, 638, 30, 29, 35, 30, 28, , 29, , 30, 31, 19, 35, 40, 36, 36, 23, 22, 28, 40, 19, 36, 23, , 39]
,["TRUIN FRANK", "A", 68.0, 34.0, 6, 204, 31, 30, , , , , , , , 39, , , , , , , 31, , 43, , , , , 30]
,["TRUSTON MATT", "A", 63.5, 31.7, 15, 476, , , 33, 31, , 21, 25, , 31, , 32, , , 34, , 35, 33, 33, 41, 31, 36, , 31, 29]
,["TURNER CARLY", "A", 53.3, 26.6, 14, 373, 17, , 32, , 29, , 18, 21, 34, 26, , 31, , , 24, , 33, , , 27, 32, , 24, 25]
,["VARINDER DHIR", "C", 26.9, 13.5, 7, 94, , , , , , 14, , , , , , 13, 10, , , 13, , , , , 14, , 11, 19]
,["VOWLER ALAN", "C", 43.4, 21.7, 18, 391, 19, 27, 23, 32, 9, 19, 19, 22, , , 27, 28, 17, , 22, , 20, 20, 30, 21, 20, , , 16]
,["WARWICK A", "C", 27.0, 13.5, 3, 41, 12, , , , , , , , , , , , , , 11, , , , , , , , , 18]
,["WARWICK I", "C", 26.0, 13.0, 1, 13, 13, , , , , , , , , , , , , , , , , , , , , , , ]
,["WHITE ROGER", "C", 38.4, 19.2, 22, 423, 19, 15, 19, 20, , 19, 16, 18, 20, 21, 17, 24, 20, 20, 28, 18, 25, 11, 24, 14, 20, 19, , 16]
,["WILLIAMS SID", "B", 48.2, 24.1, 21, 506, 19, 28, 20, 27, 22, 20, 19, 17, 34, 28, 30, , 28, 21, 21, 21, 17, , , 27, 28, 25, 30, 24]
,["WOODHAMS FRED", "B", 58.0, 29.0, 1, 29, 29, , , , , , , , , , , , , , , , , , , , , , , ]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 27, "average": 25.3, "high": 40.0, "low": 12.0},
{"numberOfEntrants": 31, "average": 30.1, "high": 45.0, "low": 15.0},
{"numberOfEntrants": 25, "average": 29.1, "high": 45.0, "low": 16.0},
{"numberOfEntrants": 28, "average": 30.2, "high": 42.0, "low": 16.0},
{"numberOfEntrants": 21, "average": 28.2, "high": 44.0, "low": 9.0},
{"numberOfEntrants": 26, "average": 28.8, "high": 46.0, "low": 14.0},
{"numberOfEntrants": 29, "average": 26.8, "high": 42.0, "low": 16.0},
{"numberOfEntrants": 18, "average": 27.3, "high": 42.0, "low": 17.0},
{"numberOfEntrants": 23, "average": 30.6, "high": 48.0, "low": 15.0},
{"numberOfEntrants": 19, "average": 30.9, "high": 43.0, "low": 16.0},
{"numberOfEntrants": 23, "average": 31.0, "high": 44.0, "low": 9.0},
{"numberOfEntrants": 23, "average": 32.5, "high": 45.0, "low": 13.0},
{"numberOfEntrants": 24, "average": 30.9, "high": 46.0, "low": 10.0},
{"numberOfEntrants": 25, "average": 28.6, "high": 46.0, "low": 12.0},
{"numberOfEntrants": 21, "average": 28.3, "high": 44.0, "low": 6.0},
{"numberOfEntrants": 28, "average": 27.8, "high": 44.0, "low": 13.0},
{"numberOfEntrants": 24, "average": 29.3, "high": 43.0, "low": 15.0},
{"numberOfEntrants": 12, "average": 29.8, "high": 42.0, "low": 11.0},
{"numberOfEntrants": 30, "average": 34.3, "high": 47.0, "low": 14.0},
{"numberOfEntrants": 19, "average": 29.7, "high": 44.0, "low": 14.0},
{"numberOfEntrants": 33, "average": 31.7, "high": 47.0, "low": 12.0},
{"numberOfEntrants": 23, "average": 26.0, "high": 39.0, "low": 14.0},
{"numberOfEntrants": 23, "average": 27.8, "high": 39.0, "low": 8.0},
{"numberOfEntrants": 30, "average": 28.7, "high": 40.0, "low": 11.0},
];
