import React from "react";
import AuthenticatedLayout from "../components/AuthenticatedLayout";
import ResultsGrid from "../components/ResultsGrid";
import PageTitle from "../components/PageTitle";
import { defaultSort, getColumns } from "../data/results";
import ResultsGraph from "../components/ResultsGraph";
import _ from "lodash";
import { Select, MenuItem } from "@material-ui/core";
import {
  getResultsData,
  ResultsData,
  getStats,
  getYears,
} from "../data/results_data";

interface ResultsProps {}

interface FiltersContext {
  filters: string[];
  updateFilters: (values: string[], action: "add" | "remove") => void;
}

const defaultFiltersContext: FiltersContext = {
  filters: [],
  updateFilters: () => {},
};

const FiltersContext = React.createContext<FiltersContext>(
  defaultFiltersContext
);

const Results = ({}: ResultsProps) => {
  const [filters, setFilters] = React.useState<string[]>([]);
  const [year, setYear] = React.useState<number>(new Date().getFullYear());
  const [results, setResults] = React.useState<ResultsData>({
    headers: [],
    data: [],
  });

  React.useEffect(() => {
    setResults(getResultsData(year));
  }, [year]);

  const updateFilters = (values: string[], action: "add" | "remove") => {
    if (action === "add") {
      var toAdd = _.difference(
        values.map((x: string) => x.toLowerCase()),
        filters
      );

      if (toAdd.length > 0) {
        setFilters([...filters, ...toAdd]);
      }
    } else {
      const newFilters = _.filter(filters, (filter: string) => {
        return values.some((value: string) => value.toLowerCase() === filter);
      });

      setFilters(newFilters);
    }
  };

  const datePicker = ({}) => {
    const years = getYears().map((y) => (
      <MenuItem key={`year_${y}`} value={y}>
        {y}
      </MenuItem>
    ));

    return (
      <Select
        value={year}
        onChange={(e) => setYear(parseInt(String(e.target.value)))}
      >
        {years}
      </Select>
    );
  };

  return (
    <AuthenticatedLayout>
      <React.Fragment>
        <FiltersContext.Provider
          value={{ filters: filters, updateFilters: updateFilters }}
        >
          <PageTitle text="Results" render={datePicker} />
          <ResultsGrid
            year={year}
            columns={getColumns(year)}
            rowData={results.data}
            defaultSort={defaultSort(year)}
            filtersCallback={updateFilters}
          />
          <ResultsGraph
            columns={getColumns(year)}
            rowData={results.data}
            stats={getStats(year)}
            filters={filters}
          />
        </FiltersContext.Provider>
      </React.Fragment>
    </AuthenticatedLayout>
  );
};

export default Results;
