import { ShootStat } from "../types/model";
import * as results_2010 from "./results_data_2010";
import * as results_2011 from "./results_data_2011";
import * as results_2012 from "./results_data_2012";
import * as results_2013 from "./results_data_2013";
import * as results_2014 from "./results_data_2014";
import * as results_2015 from "./results_data_2015";
import * as results_2016 from "./results_data_2016";
import * as results_2017 from "./results_data_2017";
import * as results_2018 from "./results_data_2018";
import * as results_2019 from "./results_data_2019";
import * as results_2020 from "./results_data_2020";
import * as results_2021 from "./results_data_2021";
import * as results_2022 from "./results_data_2022";
import * as results_2023 from "./results_data_2023";
import * as results_2024 from "./results_data_2024";

export interface ResultsData {
  headers: (string | undefined)[];
  data: (string | number | undefined)[][];
}

export const getResultsData = (year: number): ResultsData => ({
  headers: getHeaders(year),
  data: getResults(year),
});

const headers: { [year: number]: (string | undefined)[] } = {
  2010: results_2010.headers,
  2011: results_2011.headers,
  2012: results_2012.headers,
  2013: results_2013.headers,
  2014: results_2014.headers,
  2015: results_2015.headers,
  2016: results_2016.headers,
  2017: results_2017.headers,
  2018: results_2018.headers,
  2019: results_2019.headers,
  2020: results_2020.headers,
  2021: results_2021.headers,
  2022: results_2022.headers,
  2023: results_2023.headers,
  2024: results_2024.headers,
};

const results: { [year: number]: (string | number | undefined)[][] } = {
  2010: results_2010.results,
  2011: results_2011.results,
  2012: results_2012.results,
  2013: results_2013.results,
  2014: results_2014.results,
  2015: results_2015.results,
  2016: results_2016.results,
  2017: results_2017.results,
  2018: results_2018.results,
  2019: results_2019.results,
  2020: results_2020.results,
  2021: results_2021.results,
  2022: results_2022.results,
  2023: results_2023.results,
  2024: results_2024.results,
};

const stats: { [year: number]: ShootStat[] } = {
  2010: results_2010.shootStats,
  2011: results_2011.shootStats,
  2012: results_2012.shootStats,
  2013: results_2013.shootStats,
  2014: results_2014.shootStats,
  2015: results_2015.shootStats,
  2016: results_2016.shootStats,
  2017: results_2017.shootStats,
  2018: results_2018.shootStats,
  2019: results_2019.shootStats,
  2020: results_2020.shootStats,
  2021: results_2021.shootStats,
  2022: results_2022.shootStats,
  2023: results_2023.shootStats,
  2024: results_2024.shootStats,
};

export const getYears = () => {
  var dates: number[] = Object.keys(stats).map((x) => parseInt(x));

  var thisYear = new Date().getFullYear();

  if (dates.indexOf(thisYear) === -1) {
    dates.push(thisYear);
  }

  dates.sort().reverse();

  return dates;
};

export const getHeaders = (year: number): (string | undefined)[] => {
  return headers[year];
};

const getResults = (year: number): (string | number | undefined)[][] => {
  return results[year];
};

export const getStats = (year: number): ShootStat[] => {
  return stats[year];
};
