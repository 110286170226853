import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", "CLASS 01/01/16", "PERCENTAGE", "Raw Average", "No. shoots", "Total score", "JAN 3rd", "JAN 17th", "FEB 7th", "FEB 21st", "MAR 6th", "MAR 20th", "APR 3rd", "APR 17th", "MAY 1st", "MAY 15th", "JUNE 5th", "JUNE 19th", "JULY 3rd", "JULY 17th", "AUG 7th", "AUG 21st", "SEPT 4th", "SEPT 18th", "OCT 2nd", "OCT 16th", "NOV 6th", "NOV 20th", "DEC 4th", "DEC 18th"];

export const results: (string | number | undefined)[][] = [
["BIRD ARTHUR", "C", 43.0, 21.5, 2, 43, , , , , , , , , , , 21, , , , , , 22, , , , , , , ]
,["CAMERON DEAN", "C", 59.5, 29.8, 13, 387, 29, 17, 38, 24, 29, , 29, , , , 30, 26, 37, 34, 34, , 26, 34, , , , , , ]
,["CAMERON JOHANNA", "C", 22.0, 11.0, 1, 11, 11, , , , , , , , , , , , , , , , , , , , , , , ]
,["CATLING RICHARD", "B", 54.0, 27.0, 9, 243, , , , , , 25, 28, 23, , , , 30, , , 32, , , 28, , , 26, , 27, 24]
,["COLE BERNARD", "A", 69.1, 34.6, 18, 622, , 37, , 33, 37, 37, 39, 33, 31, 36, 38, 40, , 32, 31, 35, , 37, , , 36, 38, 23, 29]
,["COZENS MORRIS", "NM", , 0.0, , 36, , , , , , , 36, , , , , , , , , , , , , , , , , ]
,["CULLINGHAM SAM", "B", 70.0, 35.0, 1, 35, , , , , , , , , , 35, , , , , , , , , , , , , , ]
,["DAWSON PETER", "A", 66.7, 33.4, 19, 634, , , 39, 27, 29, , 34, , 34, 41, 42, 38, 41, 27, 35, 28, , 37, 36, 25, 35, 29, 31, 26]
,["DE SILVA RICKY", "C", 60.6, 30.3, 16, 485, , 28, , , 33, 33, , 11, 27, , 41, , 40, 34, 36, 33, 28, , 29, 21, 34, 29, , 28]
,["DI MOND HANK", "A", 68.2, 34.1, 15, 512, , 35, 41, 29, 34, 39, 35, 30, 33, , , 37, , 33, 39, , , 42, , 26, 31, , , 28]
,["DOYLE PETER", "AA", 59.2, 29.6, 6, 178, , , 38, , , , 25, , , , , , 28, 26, , 29, , , , , , , , 32]
,["DOYLE RAY", "A", 60.4, 30.2, 5, 151, , , 28, , , , 29, , , , , , 34, 28, , 32, , , , , , , , ]
,["DUFFELL ERIC", "A", 74.5, 37.2, 16, 596, 34, 39, 42, , 36, 33, 37, , 33, , 39, 43, , 32, , , 42, 37, , , 42, 39, 36, 32]
,["DUKE NEIL", "A", 67.0, 33.5, 12, 402, , 38, 39, , , 35, 38, 31, , 28, , , 33, 31, , 40, , , 31, , , , 27, 31]
,["ELYSEE GEORGE (Colt)", "B", 64.0, 32.0, 2, 64, , , 32, , , , , , , , 32, , , , , , , , , , , , , ]
,["ELYSEE MICHAEL", "C", 44.0, 22.0, 2, 44, , , 18, , , , , , , , 26, , , , , , , , , , , , , ]
,["ESCOTT PAUL", "A", 56.0, 28.0, 3, 84, 21, , , , , , , , , , , 36, , 27, , , , , , , , , , ]
,["ESCOTT RICHARD", "AA", 74.0, 37.0, 19, 703, 37, 40, 36, 37, 44, 45, 40, 25, , , 44, 41, , 35, 38, 32, , 37, , 28, 37, 36, 42, 29]
,["ESCOTT TIM", "AAA", 82.9, 41.5, 13, 539, 45, 38, , 38, 41, 42, , , , , , , 48, 42, , 39, , 40, , 34, 45, 41, 46, ]
,["EUSTACE DAVE", "AAA", 83.2, 41.6, 21, 874, 42, 39, 45, , 39, 44, 40, 38, 41, 45, 41, 46, 46, 45, 43, , 44, 38, 39, 35, 39, 44, 41, ]
,["FARAZAMAND J", "A", 68.0, 34.0, 1, 34, 34, , , , , , , , , , , , , , , , , , , , , , , ]
,["FARAZAMAND A", "B", 74.0, 37.0, 1, 37, 37, , , , , , , , , , , , , , , , , , , , , , , ]
,["FARAZAMAND WARREN", "U", 88.0, 44.0, 2, 44, 44, , , , , , , , , , , , , , , , , , , , , , , ]
,["FIANDER PETER", "B", 62.8, 31.4, 14, 440, , 33, 32, 28, , 26, , , 33, , 36, , 34, , 36, , 30, , 31, , 34, 26, 35, 26]
,["GODDARD TREVOR", "B", 50.4, 25.2, 9, 227, 23, 31, , , , 26, , 22, , 32, 15, 35, , , , 19, , 24, , , , , , ]
,["GREEN K", "U", 48.0, 24.0, 1, 24, , 24, , , , , , , , , , , , , , , , , , , , , , ]
,["HEALEY LES", "AAA", 85.6, 42.8, 19, 813, , , 45, 46, 45, , 41, 45, 42, , 45, 47, 45, 46, 43, 40, 43, , 37, 40, 40, 40, 42, 41]
,["HICKMAN EDWARD", "B", 51.5, 25.8, 4, 103, , , 31, , , , , , , , 26, , , , , , 25, , 21, , , , , ]
,["HICKMAN JACK (Colt)", "C", 54.0, 27.0, 3, 81, , , , , , , , , , , 28, , , , , , 18, , 35, , , , , ]
,["HOLLOWAY NICK", "B", 66.2, 33.1, 13, 430, , 23, , , , , , , , 36, 44, 37, 32, 26, 37, 34, 30, 37, , 23, 39, 32, , ]
,["HOOLEY PETER", "B", 59.4, 29.7, 14, 416, , 23, , 31, , 28, , 18, 29, , 44, 31, 38, 29, 33, , 35, 26, , , 27, , 24, ]
,["HUMPHREYS CLIFF", "C", 28.0, 14.0, 5, 70, , , , , , , , , 10, 16, 14, 19, , , , , 11, , , , , , , ]
,["HUNT JAMES", "NM", 38.0, 19.0, 1, 19, , , , , , , , , , , , , , , , , , , , , 19, , , ]
,["HUNT STEPHEN", "NM", 40.0, 20.0, 1, 20, , , , , , , , , , , , , , , , , , , , , 20, , , ]
,["HUTCHINGS WILLIAM", "B", 52.1, 26.1, 11, 287, 20, 28, , , , 27, , 18, , 31, 32, 30, , 26, , 25, , 28, , , , , , 22]
,["HUTCHINGS LYNNE", "C", 24.9, 12.4, 9, 112, 10, , , , , 13, , , , 14, 13, 21, , 8, , 3, , 15, , , , , , 15]
,["JACKSON COLIN", "C", 50.0, 25.0, 18, 450, , 23, 21, 19, 16, , , 19, 32, 27, 32, 30, 32, 30, 29, 13, 31, 25, , 25, , , 24, 22]
,["JAY CHRIS", "AA", 76.8, 38.4, 17, 653, , 32, 41, 39, , 44, , , 37, 39, 37, 47, , 35, , 41, 40, 36, 41, 35, , 37, 37, 35]
,["JOHNSON MICHAEL", "A", 67.1, 33.5, 19, 637, 36, 31, 39, 30, 33, 39, 28, 31, 33, 40, 37, 32, 36, 31, 36, , , 29, 30, , 34, , 32, ]
,["KIRK DARREN", "B", 58.0, 29.0, 15, 435, , , , 31, 33, 26, , , , 35, 31, 37, , 27, 32, , , 29, 28, 23, 31, 22, 22, 28]
,["LOCKYER SIMON", "AA", 73.4, 36.7, 10, 367, , 31, 43, 36, , 31, 36, , , 40, , 37, , 38, , , , , , , 41, , 34, ]
,["MADDAMS LLOYD", "NM", 56.8, 28.4, 5, 142, , , , , 18, 27, 16, , 42, , 39, , , , , , , , , , , , , ]
,["MARCHETTI ALAN", "B", 53.1, 26.5, 6, 159, , , , , 25, 30, , , , , , , , 28, , , , , , , , 29, 23, 24]
,["O'GORMAN ROY", "A", 62.8, 31.4, 12, 377, , , , 30, 30, 29, 32, 25, 34, , 32, 38, , 31, , , , , , , 27, 36, 33, ]
,["PAYNE BARRIE", "A", 62.0, 31.0, 3, 93, , , , , , 31, 25, , , , , , , , , , , , 37, , , , , ]
,["PRIOR NICK", "C", 48.0, 24.0, 13, 312, , , 35, 20, 24, 23, 25, 22, 13, 32, 33, 24, 19, , , , , 21, 21, , , , , ]
,["PVK", "A", 61.7, 30.8, 1, 31, , , , , , , , , , , , , , , , , , , , , , , , 31]
,["RICKETTS DAVE", "C", 40.8, 20.4, 17, 347, , , 28, 20, 24, 20, 23, 20, 26, , 21, , 22, 15, 21, 13, 19, , 19, , 25, 17, 14, ]
,["SHARP RUSSELL", "A", 65.2, 32.6, 16, 522, , , 27, , 31, , , , 31, 44, 31, 37, , 42, 35, 29, 35, 33, 34, 21, 33, 27, , 32]
,["SULLIVAN MARTIN", "C", 62.5, 31.3, 4, 125, , 22, 32, , , , , , , , 35, , , , , , , , 36, , , , , ]
,["TAYLOR PETER", "A", 73.8, 36.9, 17, 627, , 40, 41, 37, 29, , 37, 30, 38, 49, , , , 41, 39, 37, 32, , 38, 28, , 43, 33, 35]
,["THOMAS DUNCAN", "A", 71.8, 35.9, 23, 826, 39, 36, 36, 35, 29, 36, 38, , 36, 39, 41, 44, 44, 38, 36, 34, 41, 30, 32, 27, 39, 31, 31, 34]
,["TOWNLEY JOHN", "A", 67.3, 33.6, 7, 236, , 30, , , , , , , , 31, 38, , , , , , 32, , , 31, , 36, , 38]
,["TRUIN FRANK", "A", 75.2, 37.6, 20, 752, , 38, 43, 33, 40, 39, 32, 33, 36, 39, 38, , 41, , 38, 42, 43, 36, 41, 27, , 40, 33, 40]
,["VOWLER ALAN", "NM", 39.1, 19.5, 16, 313, , , 20, , , 14, 21, 11, 16, 22, , 26, 23, , 22, 21, 29, 22, , 14, 17, , 23, 12]
,["WHITEHEAD JOHN", "B", 51.3, 25.7, 3, 77, , , , 25, , , , , , , , , , , , , , 28, , , , , 24, ]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 15, "average": 30.8, "high": 45.0, "low": 10.0},
{"numberOfEntrants": 24, "average": 31.5, "high": 40.0, "low": 17.0},
{"numberOfEntrants": 26, "average": 35.0, "high": 45.0, "low": 18.0},
{"numberOfEntrants": 21, "average": 30.9, "high": 46.0, "low": 19.0},
{"numberOfEntrants": 22, "average": 31.8, "high": 45.0, "low": 16.0},
{"numberOfEntrants": 27, "average": 31.2, "high": 45.0, "low": 13.0},
{"numberOfEntrants": 24, "average": 31.8, "high": 41.0, "low": 16.0},
{"numberOfEntrants": 19, "average": 25.5, "high": 45.0, "low": 11.0},
{"numberOfEntrants": 22, "average": 31.2, "high": 42.0, "low": 10.0},
{"numberOfEntrants": 22, "average": 34.1, "high": 49.0, "low": 14.0},
{"numberOfEntrants": 33, "average": 33.2, "high": 45.0, "low": 13.0},
{"numberOfEntrants": 26, "average": 35.0, "high": 47.0, "low": 19.0},
{"numberOfEntrants": 19, "average": 35.4, "high": 48.0, "low": 19.0},
{"numberOfEntrants": 29, "average": 31.6, "high": 46.0, "low": 8.0},
{"numberOfEntrants": 21, "average": 34.5, "high": 43.0, "low": 21.0},
{"numberOfEntrants": 21, "average": 29.5, "high": 42.0, "low": 3.0},
{"numberOfEntrants": 21, "average": 31.2, "high": 44.0, "low": 11.0},
{"numberOfEntrants": 24, "average": 31.2, "high": 42.0, "low": 15.0},
{"numberOfEntrants": 19, "average": 32.4, "high": 41.0, "low": 19.0},
{"numberOfEntrants": 17, "average": 27.2, "high": 40.0, "low": 14.0},
{"numberOfEntrants": 23, "average": 32.7, "high": 45.0, "low": 17.0},
{"numberOfEntrants": 20, "average": 33.6, "high": 44.0, "low": 17.0},
{"numberOfEntrants": 24, "average": 30.7, "high": 46.0, "low": 14.0},
{"numberOfEntrants": 24, "average": 28.9, "high": 41.0, "low": 12.0},
];
