import React from "react";
import PageTitle from "../components/PageTitle";
import { withStyles, createStyles, WithStyles, Grid } from "@material-ui/core";
import SetupTeams from "../components/SetupTeams";
import AuthenticatedLayout from "../components/AuthenticatedLayout";

interface SetupProps extends WithStyles<typeof styles> {}

const Setup: React.FunctionComponent<SetupProps> = (props: SetupProps) => {
  return (
    <AuthenticatedLayout>
      <PageTitle text={"Setup Teams"} />

      <SetupTeams />
    </AuthenticatedLayout>
  );
};

const styles = createStyles({});

export default withStyles(styles)(Setup);

Setup.defaultProps = {};
