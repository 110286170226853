import React from "react";
import { withStyles, createStyles, WithStyles } from "@material-ui/core";
import Card from "./Card";
import { getRandomInt } from "../utils/mathUtils";
import { thetaImageIds } from "../data/thetaImages";
import { ScriptElementKind } from "typescript";

interface ThetaImageProps extends WithStyles<typeof styles> {
  id?: string;
  random?: boolean;
  width: string;
  height?: string;
}

const ThetaImageProps: React.FunctionComponent<ThetaImageProps> = ({
  id,
  random,
  height,
  width,
  classes,
}: ThetaImageProps) => {
  const thetaImageId: string | undefined = random
    ? thetaImageIds[getRandomInt(thetaImageIds.length)]
    : id;

  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://theta360.com/widgets.js";
    script.async = true;

    const adHacking = document.createElement("script");
    adHacking.text =
      "window.setTimeout(() => {" +
      'document.getElementsByClassName("ricoh-theta-iframe").item(0).src = document.getElementsByClassName("ricoh-theta-iframe").item(0).src + "&is_hidden_banner=true";' +
      'console.log("*ad hacking*")' +
      "}, 500);";
    adHacking.async = true;

    document.body.appendChild(script);
    document.body.appendChild(adHacking);
  }, []);

  return (
    <Card>
      <blockquote
        data-width={width}
        data-height={height}
        className={"ricoh-theta-spherical-image"}
      >
        <a href={`https://theta360.com/s/${thetaImageId}`} target="_blank" />
      </blockquote>
    </Card>
  );
};

const styles = createStyles({});

export default withStyles(styles)(ThetaImageProps);
