export const rules = [
  "The name of the Club shall be the Phoenix Clay Pigeon Club (hereafter referred to as the Club)",

  "The objectives and aim of the club shall be to foster and encourage the sport of clay pigeon shooting in a safe and sensible manner by providing facilities for the said sport, competition, practice and where appropriate, coaching and inter-club matches.",

  "The club shall be affiliated to the Clay Pigeon Shooting Association (the C.P.S.A.) and any other body that a general meeting of the club shall decide as appropriate.",

  "The general management of the club shall be the responsibility of an elected committee (normally elected at an annual general meeting of the club) comprising the following Chairman, Secretary, Treasurer, Club Captain, Safety Officer and at least two other ordinary members.",

  "Management decisions shall be made by the committee in meeting, all having a vote.  Five duly elected members shall constitute a quorum.  The committee shall have the right to delegate interpretation and implementation of its decisions to an individual member.",

  "Any alteration to the constitution or rules of the club shall be made by a general meeting of the club and passed by a two-thirds majority of the members at that meeting.  The exception to this being any rule which the Safety Officer (and through him the committee) decides as in need of immediate implementation on the grounds of safety.",

  "Retiring committee members may offer themselves for re-election and any person seeking election onto the committee must be duly proposed and seconded by an existing club member at the annual general meeting.",

  "The annual general meeting of the club shall be held at the end of each financial year of the club.  All members shall receive 14 days notice of the meeting together with the proposed agenda.",

  "An extraordinary general meeting may be called on presentation of a written petition signed by at least fifteen members of the club, to the Chairman.  This meeting to be held within twenty-eight days of receipt of such a petition.  Members shall be given fourteen days notice of such a meeting together with details of the matter for discussion.",

  "The Treasurer shall maintain proper books of account and shall present summaries as required at each committee meeting.  The books of account shall be inspected annually by an independent person appointed by the whole committee and a balance sheet prepared and presented to the club members at the annual general meeting.  The books of account shall be available for inspection at the meeting.",

  "The financial year of the club shall be from the 1st November to the 31st October of the following year.",

  "Any two of the following officers shall have authority to draw on the club’s bank account as joint signatories on cheques: Chairman, Secretary, Treasurer and Club Captain.",

  "In the event of the dissolution of the club, after the payment of all debts, all assets shall be converted into cash and all monies divided equally amongst the club members.",

  "Any person making an application to join the club shall do so in writing on the appropriate form.  They must be proposed and seconded by paid up members of the club who must themselves have been a full member of the club for at least twelve months.  Membership is subject to approval by the committee.  Upon acceptance of an application for membership, a once only joining fee is payable, together with an appropriate portion of the annual membership fee.",

  "The annual membership fee and the joining fee shall be decided at the Annual General Meeting.",

  "The committee shall be empowered to request the resignation of any club member whose conduct they consider to be not in the best interest of the club (to their reasonable satisfaction).  Any such member failing to resign after being requested to do so, shall have their name struck off the membership role forthwith.  No refund of any membership fee shall be payable.",

  "Any member in arrears one calendar month after membership fees are due shall be considered to have left the club.",

  "The committee shall ensure that the club is insured against all liabilities as recommended by the CPSA or any other suitably qualified independent authority.",

  "The decision of the Safety Officer shall be final in all matters relating to safety and conduct on the club’s shooting grounds.",

  "All shooting on the club’s grounds will be under the relevant CPSA rules for the particular discipline being shot.",

  "All guns must be empty and broken at all times, except when a shot is about to be taken and the shooter is in the appropriate stand.",

  "Unless part of a visiting club team, participating in an approved inter-club competition, any visitor who wishes to shoot at the club’s ground must be signed in by a member and be accompanied by that member throughout. The member shall record in the book provided the visitors name and address. A member may only sign in a visitor on a limited number of occasions in a year (at present 4), as shall be decided by the committee from time to time.  The member is responsible at all times for the conduct of his/her guest.",
];
