import React, { Dispatch, SetStateAction } from "react";

export const useLocalStorageState: <T>(
  key: string,
  defaultValue: T,
  callback?: () => void
) => [T, Dispatch<SetStateAction<T>>] = <T>(
  key: string,
  defaultValue: T,
  callback?: () => void
) => {
  const [state, setState] = React.useState<T>(() => {
    let value;
    try {
      value =
        (JSON.parse(window.localStorage.getItem(key) || "") as T) ||
        defaultValue;
    } catch (e) {
      console.log("using default value: " + String(defaultValue));
      console.error(e);
      value = defaultValue;
    }
    return value;
  });

  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));

    if (callback && state !== defaultValue) {
      callback();
    }
    return () => {};
  }, [state]);

  return [state, setState];
};
