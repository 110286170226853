import React from "react";
import Layout from "../components/Layout";
import LoginComponent from "../components/Login";
import { createStyles, withStyles } from "@material-ui/core";

interface LoginProps {}

const styles = createStyles({});

const Login = () => {
  return (
    <Layout>
      <LoginComponent />
    </Layout>
  );
};

export default withStyles(styles)(Login);
