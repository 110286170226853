import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { Team, DatesConfig } from "../data/setupTeams";
import PersonIcon from "@material-ui/icons/Person";
import Card from "./Card";

interface SetupTeamProps extends WithStyles<typeof styles> {
  team: Team;
  dates: DatesConfig;
}

const SetupTeam: React.FunctionComponent<SetupTeamProps> = (
  props: SetupTeamProps
) => {
  const { classes, team, dates } = props;

  return (
    <Card>
      <div className={classes.container}>
        <Typography variant={"h5"} className={classes.teamName}>
          {team.name}
        </Typography>
        <br />
        <div className={classes.flex}>
          <div className={classes.members}>
            <List>
              <Tooltip title={"Captain"} placement={"right"}>
                <ListItem>
                  <ListItemIcon>
                    <PersonIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={team.captain} />
                </ListItem>
              </Tooltip>
              {team.members.map((member: string, index: number) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <PersonIcon color="action" />
                  </ListItemIcon>
                  <ListItemText secondary={member} />
                </ListItem>
              ))}
            </List>
          </div>

          <div className={classes.dates}>
            <Typography>
              Every <b>{dates.week}</b> week:
            </Typography>
            <List>
              {dates.months.map((month: string) => (
                <ListItem key={`month_${month}`}>
                  <ListItemText primary={month} />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </div>
    </Card>
  );
};

const styles = createStyles({
  flex: { display: "flex" },
  teamName: {
    margin: "25px 0px 0px 25px",
  },
  members: {
    width: "200px",
  },
  dates: {
    marginTop: "20px",
    marginRight: "16px",
  },
  container: {
    minHeight: "515px",
    paddingLeft: "17px",
    paddingRight: "1px",
  },
});

export default withStyles(styles)(SetupTeam);
