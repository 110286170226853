import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", "CLASS 01/01/23", "Raw Average", "No. shoots", "Total score", "1st Jan", "15th Jan", "5th Feb", "19th Feb", "5th Mar", "19th Mar", "2nd Apr", "16th Apr", "7th May", "21st May", "4th June", "18th June", "2nd July", "16th July", "6th Aug", "20th Aug", "3rs Sept", "17th Sept", "29th Oct", "5th Nov", "19th Nov", "3rd Dec", "17th Dec"];

export const results: (string | number | undefined)[][] = [
["ALLEN JOHN", "C", 54.6, 12, 656, 59, 59, , 57, , 67, , , , 54, 51, , , 47, , , 73, 44, 54, 50, , 40, ]
,["ALLEN JAMES", "C", 60.0, 1, 60, , , 60, , , , , , , , , , , , , , , , , , , , ]
,["BARROW DAVID", "C", 54.2, 7, 379, , , 60, , 46, 68, , 48, , 52, , , , , 68, , , , , , , 37, ]
,["BEALING DAVID", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , ]
,["BIRD ARTHUR", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , ]
,["BROOKS DAVID", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , ]
,["BROWN DAVID", "AA", 77.2, 12, 927, , , , , , 74, 79, 77, , , , , 67, 74, 87, 61, 91, 81, , , 73, 86, 76]
,["CAMERON DEAN", "C", 68.4, 15, 1026, , 59, , 71, 66, 86, , , 81, 66, , 66, 60, , , 71, 67, 71, , 70, 63, 63, 66]
,["CATLING RICHARD", "NM", 55.0, 2, 110, , , , , 54, , , 56, , , , , , , , , , , , , , , ]
,["CHECHOTKIN VLADYSLAV ", "NM", 72.4, 6, 434, 60, , , , , , , , , , 57, 83, 74, , , , , , , 77, 83, , ]
,["CLARENDON VICTOR", "NM", 59.0, 3, 177, , , , , , , , 64, 63, , , , 50, , , , , , , , , , ]
,["COLE BERNARD", "B", 58.6, 19, 1114, 51, 57, 57, , 41, 70, , 60, , 66, , 54, 71, 63, 73, 57, 66, 56, 49, 59, 57, 53, 54]
,["COPE NEALE", "NM", 73.9, 8, 591, , , 60, 80, 69, , , , 80, 71, 79, , 74, , 79, , , , , , , , ]
,["CULLINGHAM SAM", "A", 69.5, 5, 348, 70, , 66, 76, , , , , , , , , , 58, 78, , , , , , , , ]
,["CULLINGHAM TATE", "C", 18.3, 5, 92, 16, , 10, 18, , , , , , , , , , 22, 26, , , , , , , , ]
,["DAWSON PETER", "A", 61.7, 17, 1048, , 56, 64, 67, 63, 77, 63, , 76, , 47, 60, 66, 57, 69, 49, , 59, 60, 63, 54, , ]
,["DE SILVA RICKY", "B", 51.5, 16, 824, 51, 41, 41, 59, , , 57, 60, , 59, 51, 56, , 44, 59, 56, 46, 56, , , , 40, 49]
,["DHIR VARINDER", "A", 66.0, 18, 1188, 83, 54, 68, 70, , , 67, 68, 74, 78, 44, 51, , , 64, 66, 67, 67, 70, 76, 67, 53, ]
,["DUFFELL ERIC", "AA", 64.8, 13, 842, 64, , , , , 81, 69, 76, 68, 70, , , , , 56, 70, 71, , , 47, 57, 57, 56]
,["DUKE NEIL", "A", 64.6, 15, 970, 80, , 61, 60, 64, , , 63, , , , 69, 69, , 54, 60, 66, 74, , 64, 63, 66, 57]
,["DYER PAUL", "NM", 42.6, 10, 426, , , , , , , , , , , 43, 37, 43, 51, 43, 40, , 40, , 46, 43, , 40]
,["ESCOTT PAUL", "A", 62.9, 5, 314, , , 51, , , , 83, , 69, 67, , , , , , , , , , , , 44, ]
,["ESCOTT RICHARD", "AA", 75.3, 20, 1506, 80, 73, , 87, 71, 87, 73, 76, 71, 83, 70, 70, 66, 64, 79, 89, , , 69, 79, 73, 76, 71]
,["ESCOTT TIM", "AAA", 82.0, 18, 1476, 93, 89, 84, 90, 81, 90, 79, 71, , 77, 76, 80, 70, 76, 81, , 93, 80, 79, 87, , , ]
,["GAYWOOD LINDA", "C", 32.0, 1, 32, 32, , , , , , , , , , , , , , , , , , , , , , ]
,["FARAZAMAND A", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , ]
,["FARAZAMAND J", "B", 64.3, 2, 129, 56, , , , , , , 73, , , , , , , , , , , , , , , ]
,["HEALEY LES", "AAA", 78.5, 19, 1491, 79, 77, 71, 83, 79, 87, 80, , 74, 80, 83, 76, 84, , 86, 71, 71, , 84, , 76, 71, 79]
,["HOLLOWAY NICK", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , ]
,["HOOLEY PETER", "B", 47.3, 10, 473, 54, 46, , 53, 33, , , , , , , , 59, 52, , 42, 49, , , 50, , 37, ]
,["HPWARTH C", "NM", 40.0, 1, 40, , , , , , , , , , , , , , , , , , , , , 40, , ]
,["HUTCHINGS WILLIAM", "A", 64.3, 1, 64, 64, , , , , , , , , , , , , , , , , , , , , , ]
,["HUTCHINGS LYNNE", "C", 48.0, 1, 48, 48, , , , , , , , , , , , , , , , , , , , , , ]
,["JACKSON COLIN", "C", 43.1, 14, 603, 64, 36, 58, 36, 31, 34, 40, 49, , 40, , , 47, , , , 46, 49, , 24, , 50, ]
,["JAY CHRIS", "A", 72.0, 13, 936, , 71, , 77, , 71, 66, 71, 62, 77, , 74, 77, , 70, 73, , , , 66, , , 80]
,["KING JONATHAN", "NM", 57.5, 9, 517, , , , , 49, , , 57, , , 50, 61, 60, , 59, 56, 61, 64, , , , , ]
,["MANUEL RAYNOR", "AA", 69.0, 18, 1243, 79, , , 83, 80, 79, , 50, 81, , 73, 71, 76, 67, 80, 47, 56, 43, , 70, 76, 71, 61]
,["MARCHETTI ALAN", "B", 57.8, 3, 173, , , , 62, , , , , , , , , , 57, 54, , , , , , , , ]
,["OFFEN COLIN", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , ]
,["O'CONNIOR JOHN", "B", 44.3, 1, 44, 44, , , , , , , , , , , , , , , , , , , , , , ]
,["O'DRISCOLL GRAHAM", "A", 64.0, 4, 256, 60, , 54, 62, , 80, , , , , , , , , , , , , , , , , ]
,["O'GORMAN ROY", "A", 56.2, 7, 393, 54, , , 54, 62, 63, , , , , , , , , 56, 50, , , , 54, , , ]
,["O'NEIL JULIAN", "NM", 56.2, 3, 169, , , , , , , , , , , , , , , 54, 49, 66, , , , , , ]
,["PAYNE BARRY", "B", 49.5, 13, 643, , 43, 57, , 40, , , , 63, 60, 50, , 61, 47, 59, , 50, , , 37, 39, , 37]
,["PRIOR NICK", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , ]
,["PVK", "A", 55.3, 3, 166, , , , , 72, , , , , , , 52, , , 42, , , , , , , , ]
,["SHARP RUSSELL", "A", 64.1, 14, 897, 61, 79, 56, , , 83, , 64, 61, 59, 64, 57, 71, , 64, 66, , 53, , , , , 59]
,["STANLEY ALEX", "C", 37.1, 1, 37, , , , , , , , , , , , , , , , , , , , , , , 37]
,["STANLEY WARWICK ", "C", 49.3, 14, 690, 36, , 34, 72, , 70, 44, , , 42, , , , 39, 58, 46, 60, , 58, 46, 53, , 33]
,["SULLIVAN MARTIN", "AA", 64.5, 13, 839, , 71, , 84, , 71, 63, 57, , , 70, 53, 73, , 66, 43, 64, 63, , , 60, , ]
,["THOMAS DUNCAN", "A", 58.2, 20, 1164, 49, 54, 56, 69, 54, 79, 64, 71, 59, 51, 64, , 64, 66, 54, , 69, , 47, 49, 41, 51, 53]
,["TOWNLEY JOHN", "NM", 55.7, 1, 56, , , , , , , 56, , , , , , , , , , , , , , , , ]
,["TRUIN FRANK", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , ]
,["TRUSTHAM MATT", "A", 66.6, 16, 1066, 76, 54, 64, , 67, 80, 69, 71, 70, 70, 64, 69, , , 66, , 69, 64, , 63, , , 50]
,["VOWLER ALAN", "B", 45.4, 16, 726, 51, 50, 33, 51, , 54, 46, 69, , 37, 39, , 40, 34, 56, , 50, , , , 44, 42, 30]
,["WALL NICKY", "NM", 25.4, 2, 51, , , , , , , , , , , , , , , , 28, , , 23, , , , ]
,["WELLS COLIN", "NM", 75.4, 5, 377, , , 69, 73, 77, , , , 83, , , , , , 76, , , , , , , , ]
,["WESTON DAVE", "NM", 58.6, 3, 176, , , , , , , , , , , , 54, , 67, , 54, , , , , , , ]
,["WHITE GRAHAM", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , ]
,["WHITE ROGER", "C", 48.2, 19, 916, 66, 39, 46, 51, 29, 48, 48, 50, , 50, , , 51, 54, 59, 47, 63, 44, , 39, 58, 36, 39]
,["WILLIAMS SID", "B", 56.0, 1, 56, 56, , , , , , , , , , , , , , , , , , , , , , ]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 29, "average": 59.9, "high": 93.0, "low": 16.0},
{"numberOfEntrants": 19, "average": 58.3, "high": 89.0, "low": 36.0},
{"numberOfEntrants": 23, "average": 55.7, "high": 84.0, "low": 10.0},
{"numberOfEntrants": 25, "average": 65.8, "high": 90.0, "low": 18.0},
{"numberOfEntrants": 21, "average": 58.5, "high": 81.0, "low": 29.0},
{"numberOfEntrants": 22, "average": 72.7, "high": 90.0, "low": 34.0},
{"numberOfEntrants": 18, "average": 63.7, "high": 83.0, "low": 40.0},
{"numberOfEntrants": 22, "average": 63.7, "high": 77.0, "low": 48.0},
{"numberOfEntrants": 16, "average": 70.9, "high": 83.0, "low": 59.0},
{"numberOfEntrants": 21, "average": 62.3, "high": 83.0, "low": 37.0},
{"numberOfEntrants": 18, "average": 59.7, "high": 83.0, "low": 39.0},
{"numberOfEntrants": 19, "average": 62.8, "high": 83.0, "low": 37.0},
{"numberOfEntrants": 23, "average": 64.0, "high": 84.0, "low": 40.0},
{"numberOfEntrants": 19, "average": 54.7, "high": 76.0, "low": 22.0},
{"numberOfEntrants": 31, "average": 63.7, "high": 87.0, "low": 26.0},
{"numberOfEntrants": 23, "average": 56.1, "high": 89.0, "low": 28.0},
{"numberOfEntrants": 22, "average": 64.3, "high": 93.0, "low": 46.0},
{"numberOfEntrants": 17, "average": 59.3, "high": 81.0, "low": 40.0},
{"numberOfEntrants": 10, "average": 59.3, "high": 84.0, "low": 23.0},
{"numberOfEntrants": 21, "average": 57.9, "high": 87.0, "low": 24.0},
{"numberOfEntrants": 19, "average": 58.9, "high": 83.0, "low": 39.0},
{"numberOfEntrants": 18, "average": 54.1, "high": 86.0, "low": 36.0},
{"numberOfEntrants": 19, "average": 54.1, "high": 80.0, "low": 30.0},
];
