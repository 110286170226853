export interface Team {
  name: string;
  captain: string;
  members: string[];
}

export interface DatesConfig {
  week: "FIRST" | "THIRD";
  months: (
    | "January"
    | "February"
    | "March"
    | "April"
    | "May"
    | "June"
    | "July"
    | "August"
    | "September"
    | "October"
    | "November"
    | "December"
  )[];
}

export interface SetupTeamConfig {
  team: Team;
  dates: DatesConfig;
}

export const setupTeamConfigs: SetupTeamConfig[] = [
  {
    team: {
      name: "Team A",
      captain: "Les Healey",
      members: [
        "Barry Payne",
        "Colin Offen",
        "Dave Weston",
        "Paul Hibbert",
        "Paul Dyer",
      ],
    },
    dates: {
      week: "THIRD",
      months: ["April", "August", "December"],
    },
  },
  {
    team: {
      name: "Team B",
      captain: "Raynor Manuel",
      members: [
        "Fred Woodhams",
        "Johnathan Farzmand",
        "Roy O'Gorman",
        "Robert Prentice",
        "Vladyslav Chechotkin",
        "Stephen Smallbridge",
      ],
    },
    dates: {
      week: "FIRST",
      months: ["January", "May", "September"],
    },
  },
  {
    team: {
      name: "Team C",
      captain: "Chris Jay",
      members: [
        "Duncan	Thomas",
        "Wayne	Sewell",
        "Julian	O’Neill",
        "Paul	O'Connell",
        "Warrick	Stanley",
      ],
    },
    dates: {
      week: "THIRD",
      months: ["January", "May", "September"],
    },
  },
  {
    team: {
      name: "Team D",
      captain: "Peter Dawson",
      members: [
        "John Allen",
        "Jonathan King",
        "Sam Cullingham",
        "Richard	Catling",
        "Richard	Vaughan-Payne",
      ],
    },
    dates: {
      week: "FIRST",
      months: ["February", "June", "October"],
    },
  },
  {
    team: {
      name: "Team E",
      captain: "Russell	Sharp",
      members: [
        "Alan Vowler",
        "Colin Jackson",
        "Neil Duke",
        "Ricky De Silva",
        "Roger White",
      ],
    },
    dates: {
      week: "THIRD",
      months: ["February", "June", "October"],
    },
  },
  {
    team: {
      name: "Team G",
      captain: "Eric Duffell",
      members: [
        "Bernard Cole",
        "Dave Brown Jr",
        "Martin Sullivan",
        "John O'Connor",
        "Chris Howorth",
      ],
    },
    dates: {
      week: "THIRD",
      months: ["March", "July", "November"],
    },
  },
  {
    team: {
      name: "Team F",
      captain: "Richard Escott",
      members: [
        "Peter Hooley",
        "Peter Vernon-Kell",
        "Tim Escott",
        "Varinder Dhir",
        "Carr-Gorm Mah",
      ],
    },
    dates: {
      week: "FIRST",
      months: ["March", "July", "November"],
    },
  },
  {
    team: {
      name: "Team H",
      captain: "Alan Marchetti",
      members: [
        "David Barrow",
        "Jon Townley",
        "Nick	Holloway",
        "Matthew Trustam",
        "Dean Cameron",
      ],
    },
    dates: {
      week: "FIRST",
      months: ["April", "August", "December"],
    },
  },
];
