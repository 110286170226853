import { getHeaders } from "./results_data";
import _ from "lodash";
import { ColumnHoverService } from "ag-grid-community/dist/lib/rendering/columnHoverService";

interface ColumnStyle {
  bold?: boolean;
  color?: string;
}

export enum ColumnType {
  header,
}

export interface Column {
  header: string;
  isNumeric: boolean;
  type?: ColumnType;
  style?: ColumnStyle;
  comparator?(a: number | string, b: number | string): number;
  pinned?: "left" | "right";
}

export interface SortModel {
  colId: string;
  sort: string;
}

const staticColumnTemplate = {
  isNumeric: true,
  type: ColumnType.header,
  style: {
    bold: true,
    color: "#ff5722",
  },
};

const staticColumnConfig: {
  header: string;
  pinned?: "left" | "right";
  isNumeric?: boolean;
  comparator?(a: number | string, b: number | string): number;
}[] = [
  { header: "Name", pinned: "left", isNumeric: false },
  {
    header: "Class",
    isNumeric: false,
    comparator: (a: string | number, b: string | number) => {
      if (typeof a === "string" && typeof b === "string") {
        if (a.startsWith("A") && b.startsWith("A")) {
          if (a.length === b.length) {
            return 0;
          }

          return a.length > b.length ? 1 : -1;
        } else {
          return b.localeCompare(a);
        }
      } else {
        return 1;
      }
    },
  },
  { header: "Percentage" },
  { header: "Raw Average" },
  { header: "No. Shoots" },
  { header: "Total Score" },
];

const staticColumns: Column[] = staticColumnConfig.map((columnConfig) =>
  Object.assign(columnConfig, staticColumnTemplate)
);

function getDateColumns(year: number): Column[] {
  const columnHeaders: (string | undefined)[] = getHeaders(year);

  return _.takeRight(
    columnHeaders,
    columnHeaders.length -
      staticColumns.length +
      (scores_are_percentages(year) ? +1 : 0)
  ).map((text: string | number | undefined) => ({
    header: text as string,
    isNumeric: true,
  }));
}

function scores_are_percentages(year: number) {
  return year >= 2023;
}

export const getColumns = (year: number): Column[] => {
  var columns = [...staticColumns, ...getDateColumns(year)];

  return columns.filter(
    (x) => !scores_are_percentages(year) || x.header !== "Percentage"
  );
};

export const defaultSort: (year: number) => SortModel[] = (year) => [
  {
    colId: "1", //Class
    sort: "desc",
  },
  {
    colId: scores_are_percentages(year) ? "2" : "3", //Raw Average
    sort: "desc",
  },
];
