import React from "react";
import {
  createStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  withStyles,
  WithStyles,
  Button,
  Hidden,
} from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import logoZoom from "../images/logo_zoom.png";
import logoSmall from "../images/logo_small.png";
import { withRouter } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";

interface HeaderProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<{}> {
  openSidebar: () => void;
}

const Header = ({ openSidebar, classes, history }: HeaderProps) => {
  const navigate = (url: string) => {
    history.push(url);
  };

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={openSidebar}
        >
          <MenuIcon color="primary" />
        </IconButton>
        <Hidden smUp>
          <img
            className={classes.logo}
            src={logoSmall}
            onClick={() => navigate("/home")}
            alt="home"
          />
        </Hidden>
        <Hidden smDown>
          <img
            className={classes.logo}
            src={logoZoom}
            onClick={() => navigate("/home")}
            alt="home"
          />
        </Hidden>
        <Typography variant="h4" noWrap color="primary">
          Phoenix CPC
        </Typography>
        <Hidden smDown>
          <div className={classes.grow} />
          <Button onClick={() => navigate("/results")}>Results</Button>
          <Button onClick={() => navigate("/photos")}>Photos</Button>
          <Button onClick={() => navigate("/setup")}>Setup</Button>
          <Button onClick={() => navigate("/membership")}>Membership</Button>
          <Button onClick={() => navigate("/contact")}>Contact</Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

const styles = () =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    logo: {
      margin: "0px 20px 0 0",
      cursor: "pointer",
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    appbar: {
      backgroundColor: "#DC7301",
    },
  });

export default withRouter(withStyles(styles)(Header));
