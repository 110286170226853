import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", "CLASS 01/08/14", "PERCENTAGE", "Raw Average", "No. shoots", "Total score", "JAN 5th", "JAN 19th", "FEB 2nd", "FEB 16th", "MAR 2nd", "MAR 16th", "APR 6th", "APR 20th", "MAY 4th", "MAY 18th", "JUNE 1st", "JUNE 15th", "JULY 6th", "JULY 20th", "AUG 3rd", "AUG 17th", "SEPT 7th", "SEPT 21st", "OCT 5th", "OCT 19th", "NOV 2nd", "NOV 16th", "DEC 7th", "DEC 21st"];

export const results: (string | number | undefined)[][] = [
["ABRAHAM PETER", "C", 44.0, 22.0, 1, 22, , , , 22, , , , , , , , , , , , , , , , , , , , ]
,["ANDON JOHN", "AA", 72.0, 36.0, 1, 36, 36, , , , , , , , , , , , , , , , , , , , , , , ]
,["ASHROFF ASH", "C", 22.7, 11.3, 3, 34, , , , , , , , , , , , 11, , 11, , 12, , , , , , , , ]
,["ASHROFF SOPHIA (Colt)", "U", 16.0, 8.0, 1, 8, , , , , , , , , , , , , , , , 8, , , , , , , , ]
,["BAILEY MIKE", "B", 59.3, 29.7, 3, 89, , , , , 26, , 36, 27, , , , , , , , , , , , , , , , ]
,["BIGGS STEVE", "B", 54.8, 27.4, 15, 411, , , 21, 25, 28, 31, 25, 25, , 29, 30, 22, 35, , , 30, 33, , 29, , , , 28, 20]
,["BIRD ARTHUR", "B", 51.3, 25.7, 9, 231, 25, 18, , , , 26, , , 34, , 29, 24, , 25, , , , , 28, , , , , 22]
,["CATLING RICHARD", "B", 56.0, 28.0, 9, 252, , 21, , 31, , 28, , , 33, , , 24, , 28, 32, , , , , , , , 27, 28]
,["CHRISTIE KEN", "A", 70.0, 35.0, 1, 35, , 35, , , , , , , , , , , , , , , , , , , , , , ]
,["COLE BERNARD", "A", 63.8, 31.9, 17, 542, 29, 26, , 34, 24, 26, , , 33, , 38, , 26, 37, 41, 34, , 29, 31, , 27, 33, 36, 38]
,["DAWSON PETER", "A", 62.5, 31.3, 20, 625, 36, 19, 26, 35, 31, 28, , , 36, 31, 37, 36, , , 40, 33, 32, 26, 30, 27, 23, 30, 36, 33]
,["DE SILVA RICKY", "C", 44.8, 22.4, 17, 381, , 6, , 19, 19, 20, 22, 24, 25, 17, 28, 26, 33, , 27, , 15, 20, , 25, 26, , , 29]
,["DI MOND HANK", "B", 61.4, 30.7, 17, 522, 36, 31, 21, 33, , 26, 30, , 35, , 30, 20, 31, , 34, 33, 33, 33, , 36, 37, , , 23]
,["DUFFELL ERIC", "AA", 67.8, 33.9, 12, 407, 37, , 40, , 28, , 37, , 35, 28, , 34, , , , , 39, 30, 32, 35, , 32, , ]
,["DUKE NEIL", "B", 74.2, 37.1, 10, 371, 41, , , 40, , 38, , , , , , 29, , , , , 40, , 37, 38, , 37, 35, 36]
,["ELYSEE GEORGE (Colt)", "B", 54.8, 27.4, 5, 137, 26, , 21, , , , , , , , 34, 25, , , , 31, , , , , , , , ]
,["ELYSEE MICHAEL", "C", 44.3, 22.2, 6, 133, 29, , 23, , , , , , , , 24, , , 23, , 14, , , 20, , , , , ]
,["ESCOTT PAUL", "A", 62.7, 31.3, 3, 94, , 33, 35, , , , , , , , , , , , , , , , , , , 26, , ]
,["ESCOTT RICHARD", "AA", 73.5, 36.8, 21, 772, 43, 35, 32, 36, 37, , 37, 34, 39, 27, 40, 30, 36, 42, , 34, 43, 36, 43, 34, 36, , 41, 37]
,["ESCOTT TIM", "AAA", 76.5, 38.3, 15, 574, 36, , , 45, , , , , 38, , 39, 36, 38, 29, 35, 39, , 36, 37, , 43, 39, 43, 41]
,["EUSTACE DAVE", "AAA", 81.1, 40.6, 20, 811, 43, 37, 40, 43, 43, , 43, 34, 43, 31, 43, 42, 35, , 43, 38, 44, , 44, 45, 49, 37, , 34]
,["FARAZAMAND J", "A", 70.0, 37.5, 1, 35, 35, , 33, , , , , , , , , , , , , , 35, , , 47, , , , ]
,["FARAZAMAND A", "A", 80.0, 34.7, 1, 40, 40, , 29, , , , , , , , , , , , , , , , , 35, , , , ]
,["FERGUSON PHILIP", "A", 61.4, 30.7, 10, 307, 37, 27, 28, 26, 24, 30, 31, 35, 37, , , , , , , , , , , , , 32, , ]
,["FIANDER PETER", "A", 62.4, 31.2, 20, 624, 36, 33, , 32, 24, , 30, 38, 32, , 38, 27, 26, 31, 40, 27, 27, 26, 36, 30, 30, 35, , 26]
,["GODDARD TREVOR", "A", 57.8, 28.9, 8, 231, , , , , , , , , , , , , 32, , 35, 27, , 31, 23, , , 30, 24, 29]
,["HEALEY LES", "AAA", 80.4, 40.2, 18, 724, 45, 33, 35, 45, 38, 42, 39, 35, , , 47, 44, 36, 40, , , 41, 38, 42, 40, , 37, 47, ]
,["HICKMAN EDWARD", "B", 50.2, 25.1, 12, 301, 22, 17, 23, 29, , 21, , , 35, , 30, 23, , 19, , , , , 32, , , 27, , 23]
,["HICKMAN JACK (Colt)", "C", 38.9, 19.4, 7, 136, , 15, , 24, , , , , , , , 17, , 24, , , , , 19, , , 14, , 23]
,["HOLLOWAY NICK", "A", 68.6, 34.3, 7, 240, 34, , , , , , , , , , 34, 34, , , 39, 28, , , , 35, , , , 36]
,["HOOLEY PETER", "B", 52.0, 26.0, 5, 130, 30, 26, 21, , , , , , , , , , , , , , , , 29, , 24, , , ]
,["HUMPHREYS CLIFF", "C", 40.2, 20.1, 13, 261, 21, , 21, 26, 21, 24, , 16, , 12, , 21, 15, 18, , , , 15, , 28, , 23, , ]
,["JACKSON COLIN", "C", 40.8, 20.4, 13, 265, 19, 14, 27, 25, 15, , 25, , , , 31, , 17, , , , 19, , , 25, 19, 14, , 15]
,["JAY ANGELA", "C", 38.0, 19.0, 4, 76, , , 15, , , , , , , , 23, , , , , 13, , , , , , 25, , ]
,["JAY CHRIS", "AA", 72.3, 36.1, 21, 759, 36, 37, 37, 36, 31, 35, , 42, 28, 32, 36, 29, , 40, 38, 31, 43, 39, 41, 39, 42, 34, , 33]
,["JENNINGS OLIVER", "B", 57.0, 28.5, 2, 57, , , 27, 30, , , , , , , , , , , , , , , , , , , , ]
,["JENNINGS T", "B", 54.0, 27.0, 1, 27, , , , 27, , , , , , , , , , , , , , , , , , , , ]
,["JOHNSON MICHAEL", "B", 60.9, 30.5, 11, 335, , , , , , 26, , 29, , , , , , 28, 33, , 32, , 27, 32, 36, 27, 29, 36]
,["KESHAVJEE RAHEEM", "C", 34.0, 17.0, 1, 17, , , 17, , , , , , , , , , , , , , , , , , , , , ]
,["KING JONATHAN", "C", 43.0, 21.5, 4, 86, , , , , 21, , , 23, 17, 25, , , , , , , , , , , , , , ]
,["KIRK DARREN", "C", 53.2, 26.6, 17, 452, 33, 18, , 28, , 24, , , 22, 29, 24, 23, 22, , 35, 27, 23, 19, , 26, , 32, 32, 35]
,["LOCKYER SIMON", "NM", 66.4, 33.2, 5, 166, , , , , , , , , , , , , , , 34, , 33, , 32, 35, , , , 32]
,["MARCHETTI ALAN", "A", 60.7, 30.3, 6, 182, , , , , , 28, 33, , , , , 34, , , , , , 25, , 33, , , 29, ]
,["MORGAN-RUSSELL TIM", "A", 64.0, 32.0, 1, 32, , , 32, , , , , , , , , , , , , , , , , , , , , ]
,["NOLE BOB", "C", 36.2, 18.1, 20, 362, 23, 11, 14, 27, 15, 22, 15, 12, 15, 8, 34, 13, 14, 31, 23, 13, 17, 18, 17, 20, , , , ]
,["O'CONNOR JOHN", "B", 59.0, 29.5, 2, 59, , , , , , , , , , , 29, , , , , , , , , , 30, , , ]
,["OFFEN COLIN", "A", 64.0, 32.0, 3, 96, , , , 37, , , , , , 27, , , , , , , , , , , , 32, , ]
,["O'GORMAN ROY", "A", 66.9, 33.5, 11, 368, , , , 35, 31, 34, 33, 34, 37, , , 35, 28, , , , 34, , , , , 33, , 34]
,["PAYNE BARRIE", "A", 66.0, 33.0, 1, 33, , , , , , 33, , , , , , , , , , , , , , , , , , ]
,["PRIOR NICK", "C", 40.4, 20.2, 11, 222, , , , , , , , , , , , 20, 27, 17, 17, , , 17, 19, 20, 15, 26, 16, 28]
,["PVK", "NM", 74.0, 37.0, 1, 37, , , , , , , , , , , , , , , , , , , , , 37, , , ]
,["RICKETTS DAVE", "C", 50.8, 25.4, 19, 483, , 24, 26, 24, 22, 26, 28, 20, , 21, , 22, 27, , 24, 28, , 21, 38, 28, 27, 18, 32, 27]
,["SALUSSOLIA ALEX", "B", 53.0, 26.5, 4, 106, , , , , 19, , , , 29, , 33, , , , , , , , , , , 25, , ]
,["SHARP RUSSELL", "B", 57.1, 28.6, 18, 514, 30, 26, 33, , , , , 32, 27, 23, 29, 30, 21, 23, , 34, 24, 24, 37, , 32, 25, 33, 31]
,["TAYLOR PETER", "A", 71.7, 35.9, 15, 538, 36, 33, 35, 34, 35, 36, , 37, , 32, 38, 34, , , , 37, , 35, , , 40, , 40, 36]
,["THOMAS DUNCAN", "B", 64.4, 32.2, 19, 612, 36, , 27, 36, 31, 30, 31, 30, , 29, 32, 28, 31, , 36, 35, 34, , , 38, 34, 31, 28, 35]
,["TOWNLEY JOHN", "A", 74.2, 37.1, 11, 408, , , , 32, 31, , 37, 38, 39, , 31, 33, , 46, , 35, 45, , , 41, , , , ]
,["TRUIN FRANK", "A", 68.4, 34.2, 16, 547, 33, 31, 29, 36, , , 35, , 38, 35, , , 36, 30, 41, 34, 34, , 36, 35, , 34, , 30]
,["WHITE GRAHAM", "B", 60.0, 30.0, 1, 30, , , , , 30, , , , , , , , , , , , , , , , , , , ]
,["WHITEHEAD JOHN", "C", 53.6, 26.8, 9, 241, 30, 21, , 24, 24, 25, , 30, 28, , , , , , , , , 29, , , , 30, , ]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 30, "average": 33.1, "high": 45.0, "low": 19.0},
{"numberOfEntrants": 25, "average": 25.1, "high": 37.0, "low": 6.0},
{"numberOfEntrants": 28, "average": 27.4, "high": 40.0, "low": 14.0},
{"numberOfEntrants": 31, "average": 31.5, "high": 45.0, "low": 19.0},
{"numberOfEntrants": 24, "average": 27.0, "high": 43.0, "low": 15.0},
{"numberOfEntrants": 23, "average": 28.7, "high": 42.0, "low": 20.0},
{"numberOfEntrants": 18, "average": 31.5, "high": 43.0, "low": 15.0},
{"numberOfEntrants": 20, "average": 29.8, "high": 42.0, "low": 12.0},
{"numberOfEntrants": 23, "average": 32.0, "high": 43.0, "low": 15.0},
{"numberOfEntrants": 17, "average": 25.6, "high": 35.0, "low": 8.0},
{"numberOfEntrants": 26, "average": 33.1, "high": 47.0, "low": 23.0},
{"numberOfEntrants": 30, "average": 27.5, "high": 44.0, "low": 11.0},
{"numberOfEntrants": 20, "average": 28.3, "high": 38.0, "low": 14.0},
{"numberOfEntrants": 19, "average": 28.5, "high": 46.0, "low": 11.0},
{"numberOfEntrants": 19, "average": 34.1, "high": 43.0, "low": 17.0},
{"numberOfEntrants": 24, "average": 28.1, "high": 39.0, "low": 8.0},
{"numberOfEntrants": 22, "average": 32.7, "high": 45.0, "low": 15.0},
{"numberOfEntrants": 20, "average": 27.4, "high": 39.0, "low": 15.0},
{"numberOfEntrants": 24, "average": 31.6, "high": 44.0, "low": 17.0},
{"numberOfEntrants": 25, "average": 33.1, "high": 47.0, "low": 20.0},
{"numberOfEntrants": 19, "average": 31.9, "high": 49.0, "low": 15.0},
{"numberOfEntrants": 28, "average": 29.2, "high": 39.0, "low": 14.0},
{"numberOfEntrants": 17, "average": 32.7, "high": 47.0, "low": 16.0},
{"numberOfEntrants": 28, "average": 30.4, "high": 41.0, "low": 15.0},
];
