import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", "CLASS 01/01/11", "PERCENTAGE", "Raw Average", "No. shoots", "Total score", "JAN 2nd", "JAN 16th", "FEB 6th", "FEB 20th", "MARCH 6th", "MARCH 20th", "APR 3rd", "APR 17th", "MAY 1st", "MAY 15th", "JUNE 5th", "JUNE 19th", "JULY 3rd", "JULY 17th", "AUG 7th", "AUG 21st", "SEPT 4th", "SEPT 18th", "OCT 2nd", "OCT 16th", "NOV 6th", "NOV 13th", "DEC 4th", "DEC 18th"];

export const results: (string | number | undefined)[][] = [
["ABRAHAM PETER", "AAA", 71.7, 35.8, 6, 215, 33, 45, , , , , , , , , , 36, , , , , , 37, , , 32, , , 32]
,["ANDON JOHN", "B", 64.0, 32.0, 4, 128, 31, , , , , , , , , , 30, , , , , , , 41, , , , , , 26]
,["ASHROFF ASH", "C", 39.0, 19.5, 6, 117, , , , , , , , , , , 23, , 20, , , , , , 16, 17, , 15, , 26]
,["BAILEY MIKE", "B", 63.1, 31.6, 16, 505, 28, 31, 27, , 22, , 30, 31, , 25, 31, , 42, 30, 38, , 37, 43, , , 40, , 27, 23]
,["BIRD ARTHUR", "A", 60.0, 30.0, 3, 90, , , , , , , , , , , 28, 35, , , , , 27, , , , , , , ]
,["BROOKS DAVE", "U", 39.0, 19.5, 2, 39, , , , , , , , , , , , , , , , , , , , , 26, 13, , ]
,["CATLING RICHARD", "B", 56.4, 28.2, 16, 451, , 34, , 27, , 27, 27, 24, 27, , 31, 26, 39, 23, , 31, , , 29, , 27, 24, 30, 25]
,["CHRISTIE KEN", "A", 70.3, 35.2, 20, 703, 33, 37, 31, 32, 41, 33, 34, , 33, 35, 31, , 45, 33, 38, 32, , 34, 36, , 37, 34, 40, 34]
,["COLE BERNARD", "A", 64.6, 32.3, 17, 549, 36, , 11, 22, 40, 36, 38, 37, , 32, , , 38, 28, , , 41, , 37, 27, 36, 31, 30, 29]
,["DAWSON PETER", "A", 72.0, 36.0, 13, 468, 36, 40, , , 39, 37, 30, , , , 38, , 34, 36, 43, , , 43, 32, , 33, , 27, ]
,["DI MOND HANK", "A", 67.7, 33.8, 18, 609, 36, 38, 29, 27, 29, , 39, 33, 37, 38, 33, 39, 38, , , , 33, , 45, 23, 35, 27, , 30]
,["DI MOND SAMANTHA", "C", 52.0, 26.0, 4, 104, , 25, , , , , , , 30, 24, , , , , , , , , , , 25, , , ]
,["DUFFELL ERIC", "A", 67.3, 33.6, 11, 370, , 34, , 26, , 37, , , , , 37, 34, 38, 30, , , 44, , , , 40, 25, , 25]
,["DUFFELL STUART", "C", 30.0, 15.0, 2, 30, , , , 11, , , , , , , , , , , , , , , , , , 19, , ]
,["DUKE NEIL", "C", 58.6, 29.3, 14, 410, , 30, , 23, , , 25, 27, , , 30, 30, , 25, 32, 38, 34, , 32, 29, 36, 19, , ]
,["ESCOTT RICHARD", "AA", 73.2, 36.6, 21, 769, 35, 40, 28, 24, 42, 42, 41, 32, 36, 33, 39, 39, 44, , 37, 33, , 42, 39, , 41, 35, 38, 29]
,["ESCOTT TIM", "AA", 73.8, 36.9, 16, 590, , 36, , 26, 36, 37, 34, 34, , , 38, , 45, 38, 34, , , 44, 40, , 34, 34, 39, 41]
,["EUSTACE DAVE", "AA", 79.1, 39.5, 22, 870, 44, 44, 39, 31, 37, 40, 38, 33, 31, 35, 33, , 38, 35, 45, 46, , 46, 47, 41, 43, 45, 41, 38]
,["FERGUSON PHILIP", "A", 62.9, 31.4, 21, 660, , 36, 27, 26, 37, 33, 30, 31, 31, 35, 30, 33, 34, 36, , 34, 29, 37, 40, 19, 30, 23, 29, ]
,["FIANDER PETER", "B", 65.6, 32.8, 17, 558, 35, 35, , 22, 28, , , , , 29, 34, 28, 36, , 36, 33, 34, 42, 39, , 41, 28, 27, 31]
,["HARRIS DAVE", "C", 45.3, 22.7, 12, 272, , , 23, 17, 29, , , 17, 16, 18, , , 34, 21, , 18, , 37, , , , 20, , 22]
,["HEALEY LES", "AAA", 81.3, 40.7, 9, 366, 43, 44, , 31, , , , , 42, , 43, 38, 47, , , , 43, , , , , , , 35]
,["HICKMAN EDWARD", "C", 61.3, 30.7, 3, 92, , , , , , , , , , , 27, , , , , , 33, , 32, , , , , ]
,["HICKMAN JACK (Colt)", "C", 48.7, 24.3, 3, 73, , , , , , , , , , , 24, , , , , , 29, , , , , 20, , ]
,["HOLLOWAY NICK", "B", 67.6, 33.8, 14, 473, , , , , 35, 36, 35, , 34, 36, 33, , , 30, , 31, 35, 34, 39, , 37, , 34, 24]
,["HOOLEY PETER", "B", 61.7, 30.9, 15, 463, 32, 30, , 30, 31, , 31, , 30, 33, 35, , 34, 29, 37, 32, , , 27, , , 24, , 28]
,["HUMPHREYS CLIFF", "B", 56.0, 28.0, 2, 56, , 33, , 23, , , , , , , , , , , , , , , , , , , , ]
,["JENNINGS OLIVER", "C", 49.6, 24.8, 17, 422, 25, 19, 21, 24, 31, 25, , 25, 28, 19, , 22, 31, , 21, 27, , 23, 30, 18, , , 33, ]
,["JENNINGS THEO", "B", 62.9, 31.5, 15, 472, 29, , 29, , 25, 32, , 25, 26, , , 30, 38, , 34, 39, , 35, 42, 28, , , 33, 27]
,["KESHAVJEE RAHEEM", "C", 38.3, 19.2, 6, 115, , , 15, , 21, , , , , , 20, 24, , , , , , , 18, , , , 17, ]
,["MADDOWS ALAN", "C", 51.0, 25.5, 2, 51, , , , , 27, , , , , , , , 24, , , , , , , , , , , ]
,["MARCHETTI ALAN", "A", 67.1, 33.6, 7, 235, 32, 32, , , , , , 38, , , , 32, , , 37, , 28, , 36, , , , , ]
,["MORGAN-RUSSELL TIM", "A", 69.7, 34.9, 20, 697, 29, 31, 28, 31, 25, 42, , , , 36, 32, 41, 44, 38, 34, 42, , 36, 38, 27, 37, 31, 41, 34]
,["MUNRO ALISTAIR", "B", 56.2, 28.1, 13, 365, 35, 28, , 22, 37, 28, 32, , 27, 21, , , , , , , 32, 25, , , , 30, 27, 21]
,["O'CONNELL PAUL", "C", 52.0, 26.0, 1, 26, , , , , , , , 26, , , , , , , , , , , , , , , , ]
,["O'CONNOR JOHN", "B", 58.7, 29.3, 3, 88, , , , 26, , 33, , , , , , , , , , , , , , , , 29, , ]
,["O'GORMAN ROY", "A", 68.7, 34.3, 12, 412, 22, 37, , , 32, , 34, 31, , , 37, , 40, , , 32, 37, 37, , , 37, , 36, ]
,["O'GORMAN THOMAS", "B", 62.0, 31.0, 1, 31, 31, , , , , , , , , , , , , , , , , , , , , , , ]
,["PASCOE JEREMY", "C", 49.8, 24.9, 9, 224, , , 13, 21, 30, , , 18, 31, 24, , 26, 34, , , , , , , , 27, , , ]
,["PAYNE BARRIE", "A", 68.3, 34.2, 6, 205, , , , 26, , , , , , 32, 34, 41, , , , , 36, , , , 36, , , ]
,["PEDDIE STEPHEN", "AA", 71.8, 35.9, 8, 287, , 35, , , 37, , 39, 35, 33, , , 29, 45, , , , 34, , , , , , , ]
,["RICKETTS DAVE", "B", 57.0, 28.5, 16, 456, , , , , , , , 34, 21, 28, 31, 22, 28, 27, 31, 34, 29, 36, 34, 19, 33, 23, 26, ]
,["SALUSSOLIA ALEX", "C", 38.2, 19.1, 10, 191, , , 11, 14, 17, , , 15, 23, 13, , 26, 25, , , , , , , , 23, , , 24]
,["SHARP RUSSELL", "B", 57.6, 28.8, 11, 317, , 33, 33, , , 26, 27, , , , 25, , 31, , , , , , 38, , 30, 24, 31, 19]
,["TAYLOR PETER", "AA", 76.9, 38.4, 7, 269, , 41, , , , 31, 40, , , , 36, , 42, , , , , , , , 38, 41, , ]
,["THOMAS DUNCAN", "A", 66.5, 33.3, 22, 732, 40, 41, 25, 27, 40, 32, 37, 34, 28, 30, , 28, 44, 34, , 31, 38, 42, 39, 22, 38, 24, 33, 25]
,["TOWNLEY JOHN", "B", 62.7, 31.4, 14, 439, 29, , 30, , , , 26, 29, , , 24, 28, 43, 22, 35, 42, 29, 29, , , 34, , 39, ]
,["TRUIN FRANK", "A", 71.2, 35.6, 21, 748, 39, 39, 29, 33, 38, 33, 36, , 46, 34, 39, 35, 38, 30, 38, 36, 41, 36, , , 34, 30, 36, 28]
,["VERNON-KELL PETER  ", "AAA", 84.0, 42.0, 5, 210, 47, 42, 38, , , , , , , , , , , , 43, 40, , , , , , , , ]
,["WHITEHEAD JOHN", "A", 61.2, 30.6, 17, 520, , 36, 20, 27, 32, , 33, 27, 31, , 34, 38, 38, , , , , 41, 35, 25, 27, 22, 30, 24]
,["WOODHAMS FRED", "A", 70.8, 35.4, 5, 177, , , 32, , , , , , 30, , 39, , , , 35, , 41, , , , , , , ]
,["WOODHAMS JACKIE", "B", 64.0, 32.0, 4, 128, , , 32, , , , , , 32, , , , , , 33, , 31, , , , , , , ]
,["INGRAHAM MORGAN", "U", 23.0, 11.5, 4, 46, , , , , , , , , , , 14, , , , 7, 10, , , , , , , , 15]
,["WILLIAMSON ROB", "U", 68.0, 34.0, 1, 34, , , , , , , , , , , , 34, , , , , , , , , , , , ]
,["BIRD ED", "U", 74.0, 37.0, 1, 37, , , , , , , , , , , , 37, , , , , , , , , , , , ]
,["DAWSON NEIL", "U", 86.0, 43.0, 1, 43, , , , , , , , , , , , , 43, , , , , , , , , , , ]
,["HODGES STUART", "U", 61.0, 30.5, 2, 61, , , , , , , , , , , , , 33, , , , , , , , 28, , , ]
,["BREW SIMON", "U", 52.0, 26.0, 1, 26, , , , , , , , , , , , , 26, , , , , , , , , , , ]
,["J BOWERS", "U", 40.0, 20.0, 1, 20, , , , , , , , , , , , , , , , , 20, , , , , , , ]
,["WEBSTER DAVE", "U", 70.0, 35.0, 1, 35, , , , , , , , , , , , , , , , , , 35, , , , , , ]
,["M TURNER", "U", 62.0, 31.0, 2, 62, , , , , , , , , , , , , , , , , , 36, , , , , , 26]
,["ELYSEE MICHAEL", "U", 44.4, 22.2, 5, 111, , , , , , , , , , , , , , , , , , , 23, , 29, 22, 24, 13]
,["ELYSEE GEORGE", "U", 43.0, 21.5, 4, 86, , , , , , , , , , , , , , , , , , , 26, , 22, , 24, 14]
,["HALL J", "U", 26.0, 13.0, 1, 13, , , , , , , , , , , , , , , , , , , , , , 13, , ]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 25, "average": 33.5, "high": 47.0, "low": 22.0},
{"numberOfEntrants": 31, "average": 35.2, "high": 45.0, "low": 19.0},
{"numberOfEntrants": 24, "average": 25.8, "high": 39.0, "low": 11.0},
{"numberOfEntrants": 28, "average": 25.0, "high": 33.0, "low": 11.0},
{"numberOfEntrants": 28, "average": 32.0, "high": 42.0, "low": 17.0},
{"numberOfEntrants": 21, "average": 33.0, "high": 42.0, "low": 19.0},
{"numberOfEntrants": 24, "average": 33.0, "high": 41.0, "low": 22.0},
{"numberOfEntrants": 24, "average": 28.6, "high": 38.0, "low": 15.0},
{"numberOfEntrants": 25, "average": 30.3, "high": 46.0, "low": 16.0},
{"numberOfEntrants": 23, "average": 28.7, "high": 38.0, "low": 13.0},
{"numberOfEntrants": 34, "average": 31.7, "high": 43.0, "low": 14.0},
{"numberOfEntrants": 28, "average": 31.7, "high": 41.0, "low": 22.0},
{"numberOfEntrants": 36, "average": 36.7, "high": 47.0, "low": 20.0},
{"numberOfEntrants": 20, "average": 29.7, "high": 38.0, "low": 18.0},
{"numberOfEntrants": 22, "average": 33.8, "high": 45.0, "low": 7.0},
{"numberOfEntrants": 22, "average": 32.5, "high": 46.0, "low": 10.0},
{"numberOfEntrants": 26, "average": 33.6, "high": 44.0, "low": 20.0},
{"numberOfEntrants": 26, "average": 36.5, "high": 46.0, "low": 22.0},
{"numberOfEntrants": 28, "average": 33.9, "high": 47.0, "low": 16.0},
{"numberOfEntrants": 14, "average": 23.7, "high": 41.0, "low": 12.0},
{"numberOfEntrants": 34, "average": 33.2, "high": 43.0, "low": 22.0},
{"numberOfEntrants": 30, "average": 25.9, "high": 45.0, "low": 13.0},
{"numberOfEntrants": 27, "average": 31.4, "high": 41.0, "low": 17.0},
{"numberOfEntrants": 31, "average": 26.5, "high": 41.0, "low": 13.0},
];
