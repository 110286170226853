import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid, Button, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  info: { paddingLeft: "30px", paddingRight: "30px" },
  form: {
    backgroundColor: "#424242",
    padding: "30px",
  },
  download: {
    textDecoration: "None",
  },
  white: {
    color: "white",
  },
}));

const MembershipFormDownload: React.FunctionComponent = () => {
  const classes = useStyles();

  const Info = () => {
    return (
      <div className={classes.info}>
        <Typography>
          This page gives you access to download the membership form.
          <br />
          New and existing members can use this form to register their current
          contact details.
          <br />
          Membership is annual and runs from January-January.
          <br />
          Please complete your form and hand it to the club membership secretary
          or preferably email a photo or scan to{" "}
          <Link href="mailto:enquiries@phoenixcpc.co.uk">
            <Typography color="secondary">
              enquiries@phoenixcpc.co.uk
            </Typography>
          </Link>{" "}
          once you have made payment via BACS - bank details are on the form.
          <br />
          <u>
            When making payment please use your surname and initial as your
            reference
          </u>
          .
        </Typography>

        <br />
        <Typography color="primary">
          For queries email us at{" "}
          <Link href="mailto:enquiries@phoenixcpc.co.uk">
            <Typography color="secondary">
              enquiries@phoenixcpc.co.uk
            </Typography>
          </Link>
        </Typography>
        <br />
        <Grid container>
          <a
            href="/downloads/The Phoenix CPC Membership Form.pdf"
            target="_blank"
            className={classes.download}
            download
          >
            <Button variant="contained" color="secondary">
              Download
            </Button>
          </a>
        </Grid>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Typography variant="h5">Membership Form</Typography>
        <br />
        <div>
          <Grid container>
            <Info />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default MembershipFormDownload;
