import React from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GridReadyEvent,
  GridApi,
  ColumnApi,
  RowSelectedEvent,
  VirtualColumnsChangedEvent,
  RowNode,
} from "ag-grid-community";
import _ from "lodash";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { Column, ColumnType, SortModel } from "../data/results";
import { WithStyles, createStyles, withStyles } from "@material-ui/core";
import Card from "./Card";
import QuickFilter from "./QuickFilter";
import { squads, Squad } from "../data/squads";
import { ResultsBase } from "../types/model";
import { getHeaderColumns } from "../services/resultsService";

interface ResultsGridProps extends WithStyles<typeof styles>, ResultsBase {
  year: number;
  defaultSort: SortModel[];
  filtersCallback?: (values: string[], action: "add" | "remove") => void;
}

const ResultsGrid: React.FunctionComponent<ResultsGridProps> = ({
  classes,
  columns,
  rowData,
  defaultSort,
  filtersCallback,
  year,
}: ResultsGridProps) => {
  const [theGridApi, setTheGridApi] = React.useState<GridApi | undefined>(
    undefined
  );
  const [theColumnApi, setTheColumnApi] = React.useState<ColumnApi | undefined>(
    undefined
  );

  const filters = React.useRef<string[]>([]);

  const numberOfHeaderColumns: number = _.filter(
    columns,
    (x: Column) => x.type === ColumnType.header
  ).length;

  const maxValues: number[] = _.map(
    _.slice(
      _.zip(...rowData),
      numberOfHeaderColumns /**Chop off the header columns*/
    ),
    (x) => Number(_.max(x))
  );

  const columnDefs: ColDef[] = columns.map((column: Column, index: number) => {
    return {
      headerName: column.header,
      pinned: column.pinned,
      field:
        index.toString() /**Allows us to bind an [][] of values to ag-grid rather than an object*/,
      comparator: column.comparator,
      type: column.isNumeric ? "numericColumn" : undefined,
      cellStyle: (params: any) => {
        //TODO: use cellClass instead - easier to build a list of classes using logic than an object - also less stuff written to the dom

        return {
          backgroundColor: getBackgroundColor(params),
          border: getBorder(
            params.colDef,
            headerColDefIds,
            params.value,
            maxValues
          ),
          fontWeight: column.style && column.style.bold ? "bold" : "normal",
          color:
            column.style && column.style.color ? column.style.color : "white",
        };
      },
    };
  });

  const headerColumns: string[] = getHeaderColumns(columns).map(
    (column) => column.header
  );

  const headerColDefIds: string[] = _.filter(columnDefs, (columnDef) =>
    headerColumns.some((header) => header === columnDef.headerName)
  ).map((columnDef) => columnDef.field as string);

  const allColDefIds = columnDefs.map((columnDef) => columnDef.field as string);

  const onGridReady = (event: GridReadyEvent) => {
    if (event && event.api) {
      const gridApi: GridApi = event.api;
      const columnApi: ColumnApi = event.columnApi as ColumnApi;

      setTheGridApi(gridApi);
      setTheColumnApi(columnApi);

      window.addEventListener("resize", function () {
        setTimeout(function () {
          resizeColumns(columnApi, allColDefIds);
        });
      });

      gridApi.setColumnDefs(columnDefs);
      gridApi.setRowData(rowData);

      resizeColumns(columnApi, allColDefIds);

      gridApi.setSortModel(defaultSort);
    }
  };

  const onRowSelected = (event: RowSelectedEvent) => {
    if (event.api) {
      event.api.redrawRows({ rowNodes: [event.node] });
    }
  };

  const onVirtualColumnsChanged = (
    event: VirtualColumnsChangedEvent,
    colDefIds: string[]
  ) => {
    resizeColumns(event.columnApi, colDefIds);
  };

  function isExternalFilterPresent(): boolean {
    return filters.current.length !== 0;
  }

  function doesExternalFilterPass(node: RowNode): boolean {
    return _.some(
      filters.current,
      (filter: string) => node.data[0].toLowerCase().indexOf(filter) !== -1
    );
  }

  const resizeColumns = (
    columnApi: ColumnApi | null | undefined,
    colDefIds: string[]
  ): void => {
    if (!columnApi) {
      return;
    }

    columnApi.autoSizeColumns(colDefIds);
  };

  const getBorder = (
    colDef: ColDef,
    headerColDefIds: string[],
    value: string | number,
    maxValues: number[]
  ): string => {
    if (headerColDefIds.includes(colDef.field || "")) {
      return "";
    } else {
      return maxValues[Number(colDef.field) - headerColDefIds.length] ===
        value && value !== undefined /**usually for shoots not yet happened*/
        ? "1px solid #fff7aa"
        : "";
    }
  };

  const updateFilters = (values: string[], action: "add" | "remove"): void => {
    if (action === "add") {
      var toAdd = _.difference(
        values.map((x: string) => x.toLowerCase()),
        filters.current
      );

      if (toAdd.length > 0) {
        filters.current = [...filters.current, ...toAdd];
      }
    } else {
      var removed = _.difference(
        filters.current,
        values.map((x: string) => x.toLowerCase())
      );
      if (removed.length !== filters.current.length) {
        filters.current = removed;
      }
    }

    notifyGridFiltersChanged();

    if (filtersCallback) {
      filtersCallback(filters.current, action);
    }
  };

  const notifyGridFiltersChanged = (): void => {
    if (theGridApi) {
      var gridApi = theGridApi as GridApi;
      gridApi.onFilterChanged();
      gridApi.setSortModel(defaultSort);
    }

    resizeColumns(theColumnApi, allColDefIds);
  };

  const quickFilters: React.ReactNode[] = squads.map(
    (squad: Squad, index: number) => (
      <QuickFilter
        key={`quick_filter_${index}`}
        label={squad.name}
        values={squad.filterNames}
        updateFilters={updateFilters}
      />
    )
  );

  //  For grid refresh (rather than initial creation and data bind)
  if (theGridApi) {
    var gridApi = theGridApi as GridApi;
    gridApi.setColumnDefs(columnDefs);
    gridApi.setRowData(rowData);

    gridApi.setSortModel(defaultSort);
  }

  resizeColumns(theColumnApi, allColDefIds);

  return (
    <div>
      <div className={classes.filters}>{quickFilters}</div>
      <Card>
        <div className={`ag-theme-balham-dark ${classes.root}`}>
          <AgGridReact
            onGridReady={onGridReady}
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: true,
              sortingOrder: ["asc", "desc"],
              suppressMovable: true,
            }}
            pagination={false}
            rowSelection={"multiple"}
            rowMultiSelectWithClick={true}
            onRowSelected={onRowSelected}
            onVirtualColumnsChanged={(event: VirtualColumnsChangedEvent) => {
              onVirtualColumnsChanged(event, allColDefIds);
            }}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
          />
        </div>
      </Card>
    </div>
  );
};

// TODO: type the params for cellStyle
const getBackgroundColor = (params: any): string => {
  if (params.node.isSelected()) {
    return "";
  }

  const clazz: string = params.data[1];
  switch (clazz) {
    case "AAA":
      return "#3D4749";
    case "AA":
      return "#333b3d";
    case "A":
      return "#2a3133";
    case "B":
      return "#232a2b";
    case "C":
      return "#1b2021";
    case "NM":
      return "#0e1111";
    case "U":
      return "#020202";
    default:
      return "";
  }
};

const styles = createStyles({
  rootContainer: {},
  root: {
    height: "100vh",
    /** hide the sort direction icons and column sort order indicators in the ag-grid header*/
    "& span.ag-header-icon.ag-sort-order, .ag-icon-menu": {
      display: "none",
    },
    "& .ag-ltr .ag-cell-focus": {
      border: "1px solid red",
    },
    "& .ag-row-selected": {
      border: "1px solid red",
      background:
        "linear-gradient(90deg, rgba(200,0,0,0.3) 35%, rgba(255,69,0,0.2) 100%);",
    },
  },
  filters: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    marginBottom: "15px",
  },
});

export default withStyles(styles)(ResultsGrid);
