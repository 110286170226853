import React from "react";
import WithPassword from "./WithPassword";
import Layout from "./Layout";
import { withStyles, createStyles } from "@material-ui/core";

interface AuthenticatedLayoutProps {
  children?: React.ReactNode;
}

const styles = createStyles({});

const AuthenticatedLayout = ({ children }: AuthenticatedLayoutProps) => {
  return (
    <WithPassword>
      <Layout>{children}</Layout>
    </WithPassword>
  );
};

export default withStyles(styles)(AuthenticatedLayout);
