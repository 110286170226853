import React from "react";
import { withStyles, WithStyles, Chip, createStyles } from "@material-ui/core";

interface QuickFilterProps extends WithStyles<typeof styles> {
  label: string;
  values: string[];
  updateFilters: (filters: string[], action: "add" | "remove") => void;
}

const QuickFilter: React.FunctionComponent<QuickFilterProps> = ({
  classes,
  label,
  values,
  updateFilters
}: QuickFilterProps) => {
  const [active, setActive]: [
    boolean,
    (active: boolean) => void
  ] = React.useState<boolean>(false);

  const color: "primary" | "default" = active ? "primary" : "default";

  return (
    <Chip
      className={classes.chip}
      label={label}
      clickable
      onClick={() => {
        setActive(true);
        updateFilters(values, "add");
      }}
      color={color}
      onDelete={() => {
        updateFilters(values, "remove");
        setActive(false);
      }}
    />
  );
};

const styles = createStyles({
  chip: {
    margin: "5px",
    borderRadius: "11px",
    transition: "box-shadow .3s",
    "&:hover": {
      boxShadow: "0 0 11px rgba(11,11,11,1)"
    },
    "&:focus": {
      boxShadow: "0 0 11px rgba(11,11,11,1)"
    }
  }
}); 

export default withStyles(styles)(QuickFilter);
