import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", "CLASS 01/01/17", "PERCENTAGE", "Raw Average", "No. shoots", "Total score", "JAN 7th", "JAN 21st", "FEB 4th", "FEB 18th", "MAR 4th", "MAR 18th", "APR 1st", "APR 15th", "MAY 6th", "MAY 20th", "JUNE 3rd", "JUNE 17th", "JULY 1st", "JULY 15th", "AUG 5th", "AUG 19th", "SEPT 2nd", "SEPT 16th", "OCT 7th", "OCT 21st", "NOV 4th", "NOV 18th", "DEC 2nd", "DEC 16th"];

export const results: (string | number | undefined)[][] = [
["APLIN CHRIS", "A", 62.0, 31.0, 1, 31, , , , , , , , , , , 31, , , , , , , , , , , , , ]
,["BIRD ARTHUR", "B", 46.7, 23.3, 3, 70, 14, , , , , , , , , , 32, , , , , , , , , 24, , , , ]
,["CAMERON DEAN", "AA", 62.9, 31.5, 13, 409, 26, , , 34, 38, , , , , , 45, 29, , , 24, 26, 30, 36, , 35, 23, , 30, 33]
,["CATLING RICHARD", "B", 58.8, 29.4, 10, 294, , , , , , , 27, , 26, , 33, 31, , 27, 29, , 24, , 35, , 26, , 36, ]
,["COLE BERNARD", "B", 61.5, 30.8, 19, 585, 32, , 34, 31, , 27, 22, 27, 33, 26, 41, 32, 32, 33, , 34, , 34, 40, 30, , 27, 21, 29]
,["CUNNINGHAM SAM", "C", 52.4, 26.2, 16, 419, , , 22, 29, 25, 14, 24, 27, 24, , , 26, , 30, 28, 29, 30, , 27, 30, 21, , 33, ]
,["DAWSON PETER", "A", 63.1, 31.6, 19, 600, 32, 42, 31, 27, 36, 19, , 26, , 27, 43, 31, 33, 34, 36, 33, , 37, 35, 22, 27, , , 29]
,["DEJOUR DICK", "U", 52.0, 26.0, 1, 26, , , , , , , , , , , , , , , , , , , , , , , 26, ]
,["DE SILVA RICKY", "B", 49.4, 24.7, 15, 370, 21, , , 22, 32, 15, , 20, 23, , 36, 28, , 25, 23, , 31, 21, 31, , , 19, , 23]
,["DI MOND HANK", "A", 68.3, 34.1, 11, 376, , , 25, 32, , , 33, , , 29, , 33, 37, , , 40, 34, 44, 35, , , , , 34]
,["O'DRISCOL GRAHAM", "NM", 45.0, 22.5, 2, 45, , , , , , , , , , , , , , 28, , , , , , 17, , , , ]
,["DUFFELL ERIC", "AA", 75.3, 37.6, 11, 414, , , , 40, , , 39, , 37, , 43, 29, , 43, 32, , , 46, 32, , , , 37, 36]
,["DUKE NEIL", "B", 61.1, 30.5, 11, 336, 30, , 22, 30, , , 31, 30, 33, 25, 33, , , , 34, , , , , , , , 35, 33]
,["EBERS NEIL", "B", 58.0, 29.0, 1, 29, 29, , , , , , , , , , , , , , , , , , , , , , , ]
,["EDWARDS STUART", "A", 68.0, 34.0, 1, 34, , , , , 34, , , , , , , , , , , , , , , , , , , ]
,["ELYSEE MICHAEL", "B", 58.0, 29.0, 1, 29, , , , , , , , , , , 29, , , , , , , , , , , , , ]
,["ESCOTT PAUL", "C", 53.0, 26.5, 4, 106, 23, , , , , 18, , , , , , , , , 38, , , , , , , , , 27]
,["ESCOTT RICHARD", "AA", 75.4, 37.7, 21, 792, 36, 36, 30, 40, , 37, 41, 36, 36, 38, , 34, 43, , 37, 44, 36, 44, 43, 35, 34, 38, 34, 40]
,["ESCOTT TIM", "AAA", 82.6, 41.3, 21, 867, 42, 43, 38, 42, 46, 35, , 37, 39, 39, , 45, 45, 43, 37, 39, , 43, 40, 44, 44, 37, 43, 46]
,["EUSTACE DAVE", "AA", 78.0, 39.0, 1, 39, 39, , , , , , , , , , , , , , , , , , , , , , , ]
,["FARAZAMAND J", "A", 60.5, 30.3, 4, 121, 23, , , 34, , , , 33, 31, , , , , , , , , , , , , , , ]
,["FARAZAMAND A", "B", 62.0, 31.0, 4, 124, 29, , , 34, , , , 27, 34, , , , , , , , , , , , , , , ]
,["FIANDER PETER", "A", 61.3, 30.6, 8, 245, , , , , , , , , 25, 31, , 36, 33, , , , , 30, 31, , , 31, 28, ]
,["HEALEY LES", "AAA", 81.7, 40.9, 17, 695, 37, 41, 33, 44, , 40, , , 38, 43, , 37, , , 46, , 39, 43, 45, 40, 43, 44, 41, 41]
,["HICKMAN EDWARD", "C", 45.2, 22.6, 5, 113, 15, , 18, , , , , , , , 25, , , , , , , , 31, 24, , , , ]
,["HICKMAN JACK (Colt)", "C", 46.0, 23.0, 2, 46, 13, , , , , , , , , , , , , , , , , , 33, , , , , ]
,["HOLLOWAY NICK", "A", 62.1, 31.1, 13, 404, 20, , , , , , , 23, 28, , 37, 37, 35, 31, 33, 38, , 39, 33, 30, 20, , , ]
,["HOOLEY PETER", "C", 47.4, 23.7, 13, 308, 26, , , 23, 31, , , 21, , 17, 35, 18, 22, 22, 24, , 19, , , , 22, , 28, ]
,["HUMPHREYS CLIFF", "C", 33.0, 16.5, 6, 99, , 16, 17, 8, 18, , 11, , , , , , , , , , , 29, , , , , , ]
,["HUTCHINGS WILLIAM", "B", 59.5, 29.7, 13, 387, 29, 26, 29, 32, , , , 21, 28, , , 32, 31, 41, 42, , , 27, , 23, , 26, , ]
,["HUTCHINGS LYNNE", "C", 33.8, 16.9, 13, 220, 14, 20, 16, 18, , , , 7, 15, , , 20, 18, 21, 15, , , 14, , 22, , 20, , ]
,["JACKSON COLIN", "B", 50.3, 25.2, 13, 327, , , 26, 25, 30, 17, , , , , 33, 25, , 28, 16, , 24, 28, , 26, , 31, , 18]
,["JAY CHRIS", "AA", 75.2, 37.6, 16, 602, , 37, , , 41, 36, , 28, 34, , , 42, 38, 39, 36, 40, , 39, 42, , 37, 38, 33, 42]
,["JOHNSON MICHAEL", "A", 63.9, 31.9, 13, 415, , 29, 29, 33, 36, 23, 27, , , 37, 43, 29, , , , , , 35, , 36, , 35, , 23]
,["KIRK DARREN", "B", 52.0, 26.0, 5, 130, , 31, , , , , 24, 22, , 30, , , , , , , , 23, , , , , , ]
,["MARCHETTI ALAN", "C", 59.3, 29.7, 3, 89, , , , 25, , , , , , , , , , 38, , , , , , , 26, , , ]
,["O'CONNOR JOHN", "A", 66.0, 33.0, 1, 33, , , , , , , , , , , , , 33, , , , , , , , , , , ]
,["OFFEN COLIN", "AAA", 75.6, 37.8, 9, 340, 34, 45, 28, , 41, , , , , 40, 40, , 40, , , 39, , , 33, , , , , ]
,["O'DRISCOLL GRAHAM", "C", 36.4, 18.2, 9, 164, 19, 26, 14, 22, , , , 18, 19, , , , , , 17, , , 17, , , , 12, , ]
,["O'GORMAN ROY", "B", 62.0, 31.0, 10, 310, , , , 26, , , , , 26, , 31, 32, 33, , , , 29, , 36, 36, 29, 32, , ]
,["PAYNE BARRY", "B", 56.0, 28.0, 2, 56, , , , , , , , , , , , , , , , , , , , , 27, , 29, ]
,["PRIOR NICK", "B", 54.6, 27.3, 20, 546, 27, 35, 24, 24, 27, 15, 23, 22, 29, 22, 37, 28, 31, 35, 34, 20, 32, , 38, 25, 18, , , ]
,["PVK", "A", 74.0, 37.0, 2, 74, , , , , , 36, , 38, , , , , , , , , , , , , , , , ]
,["SCOTLAND DAVID", "C", 45.1, 22.6, 10, 226, , , , , , , , 16, , 27, 27, 22, , , 19, 18, , , 30, 19, , 27, 21, ]
,["SHARP RUSSELL", "A", 66.7, 33.4, 17, 567, 22, 30, 37, 36, 29, 32, 35, 25, 33, 38, 36, , , 36, 37, , , , , 42, 33, , 38, 28]
,["SULLIVAN MARTIN", "A", 70.0, 35.0, 1, 35, , , , , 35, , , , , , , , , , , , , , , , , , , ]
,["TAYLOR PETER", "AA", 72.9, 36.5, 16, 583, 28, 40, , , 40, 30, 37, 35, 32, 29, , 38, 40, , , 40, , 42, 38, 40, 35, , , 39]
,["THOMAS DUNCAN", "A", 59.5, 29.7, 19, 565, 23, , 22, 36, 24, , 31, 28, 27, 38, 37, 24, 32, 33, 31, , , 36, 26, 27, 32, , 33, 25]
,["TOWNLEY JOHN", "A", 66.7, 33.3, 1, 33, , , , , , , , , , , , 33, , , , , , , , , , , , ]
,["TRUIN FRANK", "A", 70.9, 35.4, 19, 673, , , , 40, 37, , 34, 32, 31, , 41, 34, 35, 37, 32, 34, 30, 36, 40, 36, 30, 38, 35, 41]
,["TRUSTON MATT", "B", 63.1, 31.5, 10, 315, , , , 30, 32, , , , , , , 28, , , , 30, 24, 34, 39, 36, , 32, 30, ]
,["TURNER CARLY", "C", 52.2, 26.1, 17, 444, , 27, , 21, 19, , , 18, 25, 27, 32, 28, 28, 30, , 19, , 43, 30, 25, , 29, 27, 16]
,["TURNER MICHAEL", "B", 62.8, 31.4, 2, 63, , , , , , , , , , , , 31, , , , , , , 32, , , , , ]
,["VARINDER", "C", 23.7, 11.8, 3, 36, , , , , , , , 8, , , , 13, , , 15, , , , , , , , , ]
,["VOWLER ALAN", "C", 42.1, 21.1, 21, 442, 18, 20, 22, 19, 23, 11, 21, 24, 22, , 29, 24, 24, 26, 24, 15, , 21, 24, 17, , 19, 16, 23]
,["WHITE ROGER", "C", 34.5, 17.2, 21, 362, 16, 17, 20, 17, 17, 11, 13, , 18, 22, 22, , 20, 16, 19, 11, 24, , 17, 19, 18, 12, 18, 15]
,["WHITE GRAHAM", "A", 67.5, 33.8, 2, 68, , , , , , , , , , , , 33, , , , , , , 35, , , , , ]
,["WILLIAMS SID", "B", 49.7, 24.9, 20, 497, 22, 26, 29, 22, 33, 16, 18, 22, , 27, 31, 23, 31, 23, 23, 21, 31, 26, , 27, , , 19, 27]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 29, "average": 25.5, "high": 42.0, "low": 13.0},
{"numberOfEntrants": 19, "average": 30.9, "high": 45.0, "low": 16.0},
{"numberOfEntrants": 22, "average": 25.7, "high": 38.0, "low": 14.0},
{"numberOfEntrants": 31, "average": 28.9, "high": 44.0, "low": 8.0},
{"numberOfEntrants": 23, "average": 31.5, "high": 46.0, "low": 17.0},
{"numberOfEntrants": 18, "average": 24.0, "high": 40.0, "low": 11.0},
{"numberOfEntrants": 18, "average": 27.3, "high": 41.0, "low": 11.0},
{"numberOfEntrants": 27, "average": 24.9, "high": 38.0, "low": 7.0},
{"numberOfEntrants": 26, "average": 28.7, "high": 39.0, "low": 15.0},
{"numberOfEntrants": 20, "average": 30.6, "high": 43.0, "low": 17.0},
{"numberOfEntrants": 26, "average": 34.7, "high": 45.0, "low": 22.0},
{"numberOfEntrants": 33, "average": 29.8, "high": 45.0, "low": 13.0},
{"numberOfEntrants": 22, "average": 32.5, "high": 45.0, "low": 18.0},
{"numberOfEntrants": 23, "average": 31.3, "high": 43.0, "low": 16.0},
{"numberOfEntrants": 27, "average": 28.9, "high": 46.0, "low": 15.0},
{"numberOfEntrants": 19, "average": 30.0, "high": 44.0, "low": 11.0},
{"numberOfEntrants": 15, "average": 29.1, "high": 39.0, "low": 19.0},
{"numberOfEntrants": 26, "average": 33.3, "high": 46.0, "low": 14.0},
{"numberOfEntrants": 28, "average": 34.0, "high": 45.0, "low": 17.0},
{"numberOfEntrants": 27, "average": 29.1, "high": 44.0, "low": 17.0},
{"numberOfEntrants": 19, "average": 28.7, "high": 44.0, "low": 18.0},
{"numberOfEntrants": 19, "average": 28.8, "high": 44.0, "low": 12.0},
{"numberOfEntrants": 23, "average": 30.0, "high": 43.0, "low": 16.0},
{"numberOfEntrants": 22, "average": 30.4, "high": 46.0, "low": 15.0},
];
