import React from "react";
import {
  WithStyles,
  createStyles,
  withStyles,
  Typography
} from "@material-ui/core";
import Card from "./Card";

interface HeroProps extends WithStyles<typeof styles> {
  title?: string;
  messages: string[];
}

const Hero: React.FunctionComponent<HeroProps> = ({
  classes,
  messages,
  title
}: HeroProps) => {
  const msgs = messages.map((message: string, index: number) => (
    <React.Fragment key={index}>
      <Typography component="p">{message}</Typography>
      <br />
    </React.Fragment>
  ));

  return (
    <Card withPadding={true}>
      {title && (
        <Typography
          component="h3"
          variant="h5"
          gutterBottom
          className={classes.title}
        >
          {title}
        </Typography>
      )}
      {msgs}
    </Card>
  );
};

const styles = createStyles({
  title: {
    marginBottom: "30px"
  }
});

export default withStyles(styles)(Hero);
