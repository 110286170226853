import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", "CLASS 01/07/13", "PERCENTAGE", "Raw Average", "No. shoots", "Total score", "JAN 6th", "JAN 20th", "FEB 3rd", "FEB 17th", "MARCH 3rd", "MARCH 17th", "APR 7th", "APR 21st", "MAY 5th", "MAY 19th", "JUNE 2nd", "JUNE 16th", "JULY 7th", "JULY 21st", "AUG 18th", "SEPT 1st", "SEPT 15th", "SEPT 29th", "OCT 6th", "OCT 20th", "NOV 3rd", "NOV 17th", "DEC 1st", "DEC 15th"];

export const results: (string | number | undefined)[][] = [
["ABRAHAM PETER", "A", 60.0, 30.0, 2, 60, , 33, , , , , , , , , , , , , , , 27, , , , , , , ]
,["ANDON JOHN", "AA", 59.6, 29.8, 5, 149, 38, 34, , 22, , 22, , , , , , , , , , , , , , , 33, , , ]
,["ASHROFF ASH", "C", 36.3, 18.2, 6, 109, , , , 11, , , , , , , 15, , , , , , , , 17, 24, 15, 27, , ]
,["BAILEY MIKE", "A", 63.8, 31.9, 10, 319, 36, , , 21, , , , 36, 31, , 30, , 39, , , 33, 25, , , , , , 29, 39]
,["BIGGS STEVE", "B", 45.4, 22.7, 16, 363, , 23, 20, 24, , , , , 36, 21, 20, , , 26, 23, 27, 25, , 26, 20, 13, 18, 10, 31]
,["BIRD ARTHUR", "C", 43.0, 21.5, 4, 86, , , , 21, 19, , , , , , , , , , , , , , 26, 20, , , , ]
,["BREN S", "B", 56.0, 28.0, 2, 56, , 30, , , , , , , , , , 26, , , , , , , , , , , , ]
,["BROOKS DAVE", "C", 40.0, 20.0, 1, 20, , , , , , , , , , , , , , , , , , , , , , , , 20]
,["CATLING RICHARD", "A", 53.6, 26.8, 9, 241, 31, , , , , , , , 34, 22, 19, , 31, , 25, , , , , , 24, 22, , 33]
,["CHRISTIE KEN", "A", 66.0, 33.0, 15, 495, 38, , 27, , 31, 31, 31, 30, 39, 36, , , 41, , 31, , 32, , , 27, 35, , 27, 39]
,["COLE BERNARD", "A", 62.3, 31.1, 14, 436, 34, , , 27, 31, 32, , 39, , 27, , 35, 29, , 25, , , 37, , , 32, 34, 20, 34]
,["DAWSON PETER", "A", 63.8, 31.9, 17, 542, 39, 31, , 26, 36, , 33, 31, 39, , 32, , 32, 24, 34, , , 39, 27, , 36, 30, 22, 31]
,["DE SILVA RICKY", "B", 46.4, 23.2, 18, 418, 25, 26, 29, 13, 16, , , 26, 30, , 24, 21, 24, , 30, , 20, 25, 25, 20, , 22, 16, 26]
,["DI MOND HANK", "A", 62.6, 31.3, 19, 595, , , , 29, 20, , 41, 32, 32, 35, 33, 25, 32, , 31, 36, 29, 25, 30, 29, 44, 30, 27, 35]
,["DI MOND SAMANTHA", "C", 26.0, 13.0, 1, 13, , , , , , , , , , , , , , , , 13, , , , , , , , ]
,["DUFFELL ERIC", "A", 64.3, 32.1, 14, 450, 33, , 40, 19, 33, 34, , , 38, 31, , 28, 34, , , 35, , , 31, , 33, 28, , 33]
,["DUKE NEIL", "B", 59.0, 29.5, 12, 354, 29, , , , 25, 24, , , , 29, 25, 31, 34, , , , 31, , , , 37, 33, 24, 32]
,["ELYSEE GEORGE (Colt)", "C", 44.5, 22.3, 4, 89, 23, , , , , 16, , , 25, , , , , , , , , , 25, , , , , ]
,["ELYSEE MICHAEL", "C", 41.5, 20.8, 4, 83, 21, , , , , 15, , , 23, , , , , , , , , , 24, , , , , ]
,["ESCOTT PAUL", "B", 53.0, 26.5, 4, 106, , 27, , , , , , , , , , 29, 22, , , , , , , , , 28, , ]
,["ESCOTT RICHARD", "A", 66.2, 33.1, 21, 695, 38, 39, 28, 30, 29, 27, 36, 38, 35, 30, , 30, 48, 26, 30, 38, 35, , , 29, 29, 31, 35, 34]
,["ESCOTT TIM", "A", 70.8, 35.4, 20, 708, 35, 33, , , 32, 38, 38, 35, , 31, 35, , 42, 26, 25, 37, 41, 39, 34, 29, 44, 36, 34, 44]
,["EUSTACE DAVE", "AAA", 74.4, 37.2, 22, 818, 42, 34, 35, 25, 35, 28, 40, 40, 44, 29, , 37, 36, 34, 42, 36, , 46, 42, 36, 41, 42, 31, 43]
,["FERGUSON PHILIP", "A", 64.8, 32.4, 10, 324, 32, 35, , , 29, , , 36, , 26, , , , , , 41, 34, , , , , 40, 22, 29]
,["FIANDER PETER", "B", 58.9, 29.5, 17, 501, , , , , 26, 27, 31, , 31, 32, , 28, 31, 28, 28, 34, 26, , 30, 27, 33, 29, 25, 35]
,["HEALEY LES", "AAA", 76.6, 38.3, 14, 536, 33, , , 33, 37, 33, 42, , 38, 36, 38, , 40, 34, 43, , , 43, , , , 42, , 44]
,["HICKMAN EDWARD", "C", 43.2, 21.6, 5, 108, , , 22, , , 18, , 29, , , 15, , , , , , , , 24, , , , , ]
,["HICKMAN JACK (Colt)", "C", 46.0, 23.0, 1, 23, , , , , , , , 23, , , , , , , , , , , , , , , , ]
,["HOLLOWAY NICK", "A", 62.0, 31.0, 1, 31, , , , , , , , , , , , , , , , , , , , , , 31, , ]
,["HOOLEY PETER", "B", 53.5, 26.8, 12, 321, , 22, 28, , , 23, , 23, 32, , , 27, 35, , 14, , , 39, , , 32, , 21, 25]
,["HUMPHREYS CLIFF", "C", 39.1, 19.6, 9, 176, , , 21, 16, 21, 22, , , 25, , , , 20, , , , , , 23, 18, 10, , , ]
,["JACKSON COLIN", "C", 35.1, 17.5, 11, 193, 20, , 21, 7, 19, , , 18, 22, , , , 20, 12, , , 23, , , 18, 13, , , ]
,["JAY ANGELA", "C", 33.5, 16.8, 4, 67, , , , 15, , , , , 21, 13, , , , , , 18, , , , , , , , ]
,["JAY CHRIS", "AA", 75.8, 37.9, 21, 796, 39, 35, 36, 35, 35, 35, 40, 39, 46, 32, , 41, 36, 38, 38, 44, 39, 45, , 30, 39, 36, 38, ]
,["JENNINGS OLIVER", "B", 47.3, 23.6, 8, 189, 27, , 24, 13, , , , 24, , , 18, 30, , , , , 30, , , , , , 23, ]
,["JOHNSON MICHAEL", "U", 46.4, 23.2, 18, 418, 24, , , 14, 22, 19, 15, 21, 21, 22, , 24, 31, , , 27, 21, 27, 24, 22, 27, , 22, 35]
,["KESHAVJEE RAHEEM", "C", 38.7, 19.3, 3, 58, , , , , , , , 22, , , , , , , , , , , 15, , , 21, , ]
,["KIRK DARREN", "B", 51.6, 25.8, 17, 439, 37, 32, , 30, 17, 22, , , 31, 22, 24, 22, , 20, 31, 25, , 17, 22, 21, , , 31, 35]
,["LAWRENCE SCOTT", "C", 41.0, 20.5, 2, 41, 25, , 16, , , , , , , , , , , , , , , , , , , , , ]
,["MARCHETTI ALAN", "AA", 67.1, 33.6, 7, 235, , , , 28, 37, , 38, , 34, , 32, , 39, , , , , , , , , , 27, ]
,["MORGAN-RUSSELL TIM", "B", 60.8, 30.4, 10, 304, 31, , , 29, 25, 32, , , , , 34, 27, , , , , , , 36, 30, , 32, 28, ]
,["NOLE BOB", "NM", 36.3, 18.1, 7, 127, , , , , , , , , , , , , , , 15, , , 22, 22, 23, 17, , 6, 22]
,["O'CONNOR JOHN", "C", 50.0, 25.0, 2, 50, , , , , , 26, , , , , , , , , , , , , , , , 24, , ]
,["OFFEN COLIN", "AA", 72.0, 36.0, 1, 36, , , , , , , , 36, , , , , , , , , , , , , , , , ]
,["O'GORMAN ROY", "AA", 65.0, 32.5, 10, 325, 38, , 29, 25, 29, 34, , , 36, 37, , , 36, 29, , 32, , , , , , , , ]
,["PAYNE BARRIE", "B", 67.0, 33.5, 2, 67, , , , , , , , , , , , , 36, , 31, , , , , , , , , ]
,["PEDDIE STEPHEN", "A", 69.0, 34.5, 4, 138, , , , , , , 33, , , , 30, , 41, , , 34, , , , , , , , ]
,["RICKETTS DAVE", "B", 52.6, 26.3, 22, 579, 28, , 30, 23, 33, 35, 25, 31, 29, 29, 21, 27, 29, 20, 27, 24, 21, , 26, 24, 24, 23, 26, 24]
,["SALUSSOLIA ALEX", "B", 59.0, 29.5, 6, 177, 34, 27, 32, , , , , 27, , , , 29, , , , , , , , 28, , , , ]
,["SHARP RUSSELL", "B", 57.4, 28.7, 17, 488, , 27, 36, 25, , , 33, 29, 27, , 24, 28, 27, 25, 31, 35, , , 30, 29, , 27, 26, 29]
,["TAYLOR PETER", "A", 66.5, 33.2, 13, 432, , , 31, 29, 22, , , 33, , , 32, , 35, , 33, 39, , 39, 31, 34, 39, 35, , ]
,["THOMAS DUNCAN", "A", 59.8, 29.9, 10, 299, 32, , , , 29, 27, 31, 35, , , 28, 31, 36, , , , 30, , , 20, , , , ]
,["TOWNLEY JOHN", "A", 62.8, 31.4, 12, 377, , , 31, , , , , 36, 35, , , 28, 37, 32, , 42, 29, , 27, , 28, 25, 27, ]
,["TRUIN FRANK", "A", 69.5, 34.8, 17, 591, 37, , 34, 27, 32, 34, 38, 35, , 34, , 33, 37, 31, , , 39, , 34, 34, 41, , 32, 39]
,["TUFT GRAHAM", "C", 46.0, 23.0, 2, 46, , 20, , , , , , , , , , 26, , , , , , , , , , , , ]
,["WHITE GRAHAM", "B", 48.0, 24.0, 2, 48, , , , , , , , , 31, , , , , , , , , , , , 17, , , ]
,["WHITEHEAD JOHN", "B", 58.9, 29.5, 11, 324, 32, 29, , 20, 27, , , 36, , , 29, , , , , 30, 31, 34, , , , 22, , 34]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 29, "average": 32.1, "high": 42.0, "low": 20.0},
{"numberOfEntrants": 18, "average": 29.8, "high": 39.0, "low": 20.0},
{"numberOfEntrants": 20, "average": 28.5, "high": 40.0, "low": 16.0},
{"numberOfEntrants": 28, "average": 22.8, "high": 35.0, "low": 7.0},
{"numberOfEntrants": 27, "average": 27.7, "high": 37.0, "low": 16.0},
{"numberOfEntrants": 24, "average": 27.2, "high": 38.0, "low": 15.0},
{"numberOfEntrants": 16, "average": 34.1, "high": 42.0, "low": 15.0},
{"numberOfEntrants": 27, "average": 31.1, "high": 40.0, "low": 18.0},
{"numberOfEntrants": 27, "average": 32.0, "high": 46.0, "low": 21.0},
{"numberOfEntrants": 20, "average": 28.7, "high": 37.0, "low": 13.0},
{"numberOfEntrants": 21, "average": 26.6, "high": 38.0, "low": 15.0},
{"numberOfEntrants": 23, "average": 28.8, "high": 41.0, "low": 21.0},
{"numberOfEntrants": 30, "average": 33.7, "high": 48.0, "low": 20.0},
{"numberOfEntrants": 15, "average": 27.0, "high": 38.0, "low": 12.0},
{"numberOfEntrants": 20, "average": 29.4, "high": 43.0, "low": 14.0},
{"numberOfEntrants": 21, "average": 32.4, "high": 44.0, "low": 13.0},
{"numberOfEntrants": 20, "average": 29.4, "high": 41.0, "low": 20.0},
{"numberOfEntrants": 14, "average": 34.1, "high": 46.0, "low": 17.0},
{"numberOfEntrants": 24, "average": 27.1, "high": 42.0, "low": 15.0},
{"numberOfEntrants": 23, "average": 25.7, "high": 36.0, "low": 18.0},
{"numberOfEntrants": 25, "average": 29.4, "high": 44.0, "low": 10.0},
{"numberOfEntrants": 26, "average": 29.5, "high": 42.0, "low": 18.0},
{"numberOfEntrants": 25, "average": 25.2, "high": 38.0, "low": 6.0},
{"numberOfEntrants": 25, "average": 33.0, "high": 44.0, "low": 20.0},
];
