import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", "CLASS 01/01/12", "PERCENTAGE", "Raw Average", "No. shoots", "Total score", "JAN 2nd", "JAN 15th", "FEB 5th", "FEB 19th", "MARCH 4th", "MARCH 18th", "APR 1st", "APR 15th", "MAY 6th", "MAY 20th", "JUNE 3rd", "JUNE 17th", "JULY 1st", "JULY 15th", "AUG 5th", "AUG 19th", "SEPT 2nd", "SEPT 16th", "OCT 7th", "OCT 21st", "NOV 4th", "NOV 18th", "DEC 2nd", "DEC 16th", , , ];

export const results: (string | number | undefined)[][] = [
["ABRAHAM PETER", "A", 50.0, 25.0, 4, 100, , 24, , , , , , , , 35, , , , , , , , 21, , , , , 20, , , , ]
,["ANDON JOHN", "A", 54.8, 27.4, 5, 137, 33, 18, , , , , , , , , , , , , , , , , 37, , 27, , , 22, , , ]
,["ASHROFF ASH", "C", 32.4, 16.2, 9, 146, 16, 11, , , , , , 9, , , , , , 17, 19, , , , 17, 19, 23, 15, , , , , ]
,["BAILEY MIKE", "B", 59.5, 29.8, 13, 387, , 25, , , , 19, 35, 23, 34, , , 29, , , , 37, 30, , , 30, 38, 27, 29, 31, , , ]
,["BIRD ARTHUR", "A", 42.0, 21.0, 4, 84, 22, , , , , , 20, 17, , , , 25, , , , , , , , , , , , , , , ]
,["BROOKS DAVE", "C", 36.0, 18.0, 2, 36, , , , , , 20, , , , , , , , 16, , , , , , , , , , , , , ]
,["CATLING RICHARD", "B", 56.0, 28.0, 13, 364, 29, 25, , 22, , , , 27, 26, 31, , 33, , , , 30, 23, , 37, , 27, , 30, 24, , , ]
,["CHRISTIE KEN", "A", 62.5, 31.3, 16, 500, 29, , 32, 14, 28, , 40, 22, , , , 29, 40, 34, 38, , 30, , , 34, 41, 36, 30, 23, , , ]
,["COLE BERNARD", "A", 63.6, 31.8, 16, 509, 28, 24, 44, 27, , , 34, 22, 38, 36, , , , 37, 35, 36, 34, 35, , , , 29, 25, 25, , , ]
,["DAWSON PETER", "A", 61.5, 30.8, 13, 400, 30, 20, 43, 26, , 27, 40, , 35, , , , , 29, 30, , , 32, , 27, , 39, 22, , , , ]
,["DE SILVA RICKY", "NM", 31.3, 15.7, 6, 94, , 18, , , , , , 14, , , , , , , , 19, , 15, , , , 18, 10, , , , ]
,["DI MOND HANK", "A", 69.4, 34.7, 7, 243, , , , , , , , , , , , , , , 40, 36, 29, , 43, 39, , , 25, 31, , , ]
,["DUFFELL ERIC", "A", 65.1, 32.5, 13, 423, 32, , 34, , , 26, , 18, , 40, , 29, , 33, , 38, 30, 35, , , 42, 40, , 26, , , ]
,["DUFFELL STUART", "C", 20.0, 10.0, 1, 10, , , , , , , , , , , , , , , , , , , , , , 10, , , , , 40.5]
,["DUKE NEIL", "B", 59.1, 29.5, 11, 325, , 29, , , , , , , , 30, , 28, , 27, , 34, 30, 35, 30, , 30, , 26, 26, , , ]
,["ELYSEE GEORGE", "C", 44.9, 22.4, 7, 157, , 14, , , , , , , 20, , , , 24, , , , , , 34, 26, 23, , , 16, , , ]
,["ELYSEE MICHAEL", "C", 39.1, 19.6, 7, 137, , 9, , , , , , , 28, , , , 23, , , , , , 28, 22, 17, , , 10, , , ]
,["ESCOTT RICHARD", "AA", 70.3, 35.2, 19, 668, 33, , 38, , 30, 28, 37, 27, 42, 33, , , 38, 38, 41, 37, 34, 36, , 37, 34, 37, 35, 33, , , ]
,["ESCOTT TIM", "AA", 72.8, 36.4, 18, 655, 32, , , 22, 33, 39, 39, 29, 42, , , 41, 44, 40, 40, 38, , 38, 36, , 37, 38, 32, 35, , , ]
,["ESCOTT PAUL", "NM", 47.3, 23.7, 6, 142, , , , 14, , 25, , , , , , 26, , , , , , , 31, , 24, , , 22, , , ]
,["EUSTACE DAVE", "AAA", 81.0, 40.5, 21, 850, 45, 34, 46, , 38, 37, 41, 35, 42, 47, , 41, 41, 43, , 41, 37, 45, 43, 39, 42, 44, 34, 35, , , ]
,["FERGUSON PHILIP", "B", 54.6, 27.3, 14, 382, 30, , , 23, 29, 25, 28, 27, , 27, , , 35, , , 35, 29, 30, , , , 23, 20, 21, , , ]
,["FIANDER PETER", "A", 59.1, 29.6, 9, 266, , 26, 39, 25, 17, 31, 29, , , 34, , , , , , 30, , , 35, , , , , , , , ]
,["HARRIS DAVE", "C", 51.5, 25.8, 8, 206, 30, 20, 24, , , , 25, , 31, 26, , , 17, , 33, , , , , , , , , , , , ]
,["HEALEY LES", "AAA", 77.0, 38.5, 14, 539, , 31, 42, , 39, 35, , , 34, , , , , 35, , 43, 46, 40, 35, 43, 43, 40, , 33, , , ]
,["HICKMAN EDWARD", "B", 38.8, 19.4, 8, 155, 24, 18, , , , , , 19, , , , 22, , , , , , , 18, 19, , 19, , 16, , , ]
,["HICKMAN JACK (Colt)", "C", 38.7, 19.3, 6, 116, 32, , , , , , , , , , , 16, , , , , , , 23, 15, , 14, , 16, , , ]
,["HOLLOWAY NICK", "A", 62.7, 31.3, 6, 188, 31, , , , , , , , 39, 36, , 21, 32, , , , 29, , , , , , , , , , ]
,["HOOLEY PETER", "B", 59.6, 29.8, 14, 417, 29, , 36, 23, , 25, , , , 38, , 29, 31, 32, 37, 30, , 29, , , , 30, 24, 24, , , ]
,["HUMPHREYS CLIFF", "B", 37.0, 18.5, 2, 37, , , , , , , , , , , , , , 23, , , , , , , , , , 14, , , ]
,["JACKSON C", "NM", 39.1, 19.6, 7, 137, , , , , , , 25, , 20, 18, , , 13, , 22, 30, , 9, , , , , , , , , ]
,["JAY ANGELA", "NM", 35.0, 17.5, 10, 175, , 17, , , 9, 24, , , 11, , , , 23, , 23, 23, 17, , , , , 21, , 7, , , ]
,["JAY CHRIS", "NM", 67.1, 33.6, 18, 604, , , 33, , 24, 29, 37, 25, 37, 37, , 36, 42, 40, 33, , 36, 32, 34, , 25, 41, 33, 30, , , ]
,["JENNINGS OLIVER", "C", 55.3, 27.6, 8, 221, 35, , , 21, , , , , 34, 30, , , , , , , 28, , , , 28, 28, , 17, , , ]
,["JENNINGS THEO", "A", 64.0, 32.0, 2, 64, 34, , , 30, , , , , , , , , , , , , , , , , , , , , , , ]
,["KESHAVJEE RAHEEM", "C", 38.0, 19.0, 5, 95, 23, 15, , 14, , , , , , , , , 19, , , 24, , , , , , , , , , , ]
,["KIRK DARREN", "NM", 52.4, 26.2, 11, 288, , , 31, , , , , 24, , 29, , 23, 25, 28, , 26, 26, 22, , , , 30, 24, , , , ]
,["LAWRENCE SCOTT", "NM", 43.6, 21.8, 5, 109, , , , 12, , , , , 27, , , 22, , 25, , , , , , 23, , , , , , , ]
,["MARCHETTI ALAN", "A", 52.7, 26.3, 9, 237, , , , 19, , 22, , , , 28, , 27, , 27, 39, , , , , , , 32, 21, 22, , , ]
,["MORGAN-RUSSELL TIM", "A", 59.8, 29.9, 10, 299, 23, 29, 31, 29, , , 39, 21, , 32, , , , , , , 26, , 33, 36, , , , , , , ]
,["MUNRO ALISTAIR", "B", 65.0, 32.5, 2, 65, 31, , 34, , , , , , , , , , , , , , , , , , , , , , , , ]
,["O'CONNELL PAUL", "C", 70.0, 35.0, 1, 35, , , , , , , , , , , , , 35, , , , , , , , , , , , , , ]
,["O'CONNOR JOHN", "B", 54.7, 27.3, 3, 82, 32, , , , , , , , , , , , , 27, , , , , , , , 23, , , , , ]
,["OFFEN COLIN", "NM", 70.5, 35.3, 4, 141, , , , , 31, , 40, , , , , , , , , , , , , , , 36, , 34, , , ]
,["O'GORMAN THOMAS", "B", 68.4, 34.2, 5, 171, , , , , , , , , , , , , , , , , 34, , 37, , 34, 37, , 29, , , ]
,["PASCOE JEREMY", "B", 44.5, 22.3, 4, 89, , 22, , , 16, , 22, , 29, , , , , , , , , , , , , , , , , , ]
,["PAYNE BARRIE", "A", 58.5, 29.3, 4, 117, , , , 27, , , , 29, , , , , , , , , , , 36, 25, , , , , , , ]
,["PEDDIE STEPHEN", "AA", 65.3, 32.7, 9, 294, 33, , , 29, , , , , , 35, , 26, , 29, , 39, 38, , 34, , 31, , , , , , ]
,["RICKETTS DAVE", "B", 52.0, 26.0, 21, 546, 31, 21, 38, 21, 19, 22, 27, 12, 28, 30, , , , 20, 24, 22, 33, 29, 27, 32, 30, 25, 28, 27, , , ]
,["SALUSSOLIA ALEX", "C", 54.7, 27.3, 12, 328, 19, 22, , 21, 21, , 29, , 29, 33, , , 34, , 39, , , 30, 31, , , , 20, , , , ]
,["SHARP RUSSELL", "B", 55.1, 27.6, 20, 551, 24, 24, 35, 10, 26, 27, 29, 20, 29, 33, , 27, 34, 32, 37, 30, , , 28, 27, 33, 27, 19, , , , ]
,["TAYLOR PETER", "AA", 72.0, 36.0, 4, 144, , , , , , , , , , , , , , , , , , 40, 39, , , 36, 29, , , , ]
,["THOMAS DUNCAN", "AA", 60.7, 30.4, 11, 334, 33, 24, , 25, , , 38, 18, , 39, , 29, 40, , , , 33, , , , 27, , 28, , , , ]
,["TOWNLEY JOHN", "B", 67.7, 33.8, 18, 609, 33, , , 24, 26, 28, 40, , 40, 39, , 31, 36, 36, 36, 32, 34, 36, 41, , 31, 29, 37, , , , ]
,["TRUIN FRANK", "AA", 69.3, 34.6, 16, 554, 41, , , 30, , 36, 38, 29, , 40, , , 30, 37, , , 32, 36, 36, 36, 36, 39, 29, 29, , , ]
,["TUFT GRAHAM", "NM", 55.0, 27.5, 6, 165, , 26, , 21, , , 34, , 34, , , , 30, , , , , , 20, , , , , , , , ]
,["PVK", "AA", 66.7, 33.3, 3, 100, , , , , , , , , , , , , , 33, , , 31, , 36, , , , , , , , ]
,["WHITEHEAD JOHN", "A", 50.8, 25.4, 12, 305, , , , 19, 18, 27, 30, 19, , 30, , 27, , , , 31, 32, , , , , 26, 24, 22, , 47, ]
,["WILLIAMSON ROB", "NM", 52.7, 26.3, 3, 79, , , , , , , , , , , , , 30, , , , , , 31, , , , 18, , , , ]
,["WOODHAMS FRED", "A", 70.0, 35.0, 3, 105, 32, , , , , , , , 39, , , , , , , , 34, , , , , , , , , , ]
,["WOODHAMS JACKIE", "B", 60.0, 30.0, 3, 90, 26, , , , , , , , 31, , , , , , , , 33, , , , , , , , , , ]
,["# shooters", "U", , 24, , , 32, 25, 16, 25, 16, 20, 24, 22, 25, 26, , 22, 23, 24, 17, 23, 27, 20, 28, 18, 23, 30, 26, 29, , 541, 27050]
,["average score", "U", , 29.3, , , 29.8, 21.8, 36.3, 21.9, 25.3, 27.6, 33.2, 22.1, 32.0, 33.3, , 28.0, 31.1, 30.8, 33.3, 32.2, 31.4, 31.3, 32.5, 29.4, 31.4, 29.6, 25.8, 24.1, , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["Guests", "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,[, "U", , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["WILLAMSON A", "U", 54.0, 27.0, 1, 27, , 27, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["DIPPENAAR M", "U", 4.0, 2.0, 1, 2, , , 2, , , , , , , , , , , , , , , , , , , , , , , , ]
,["BROWN D", "U", 32.0, 16.0, 1, 16, , , , 16, , , , , , , , , , , , , , , , , , , , , , , ]
,["BANYARD C", "U", 45.3, 22.7, 3, 68, , , , 21, , , , , 25, , , , , 22, , , , , , , , , , , , , ]
,["WILLIAMSON R", "U", 47.5, 23.8, 4, 95, , , , 23, 16, , , , 27, , , , , , , 29, , , , , , , , , , , ]
,["HARE B", "U", 50.0, 25.0, 1, 25, , , , 25, , , , , , , , , , , , , , , , , , , , , , , ]
,["GIELLY F", "U", 36.0, 18.0, 3, 54, , , , , 15, 18, , , 21, , , , , , , , , , , , , , , , , , ]
,["TURNER M", "U", 53.0, 26.5, 2, 53, , , , , , 33, , 20, , , , , , , , , , , , , , , , , , , ]
,["CUCKOW BRIAN", "U", 22.0, 11.0, 1, 11, , , , , , 11, , , , , , , , , , , , , , , , , , , , , ]
,["FOREMAN N", "U", 70.0, 35.0, 2, 70, , , , , , , 43, 27, , , , , , , , , , , , , , , , , , , ]
,["LUMLEY G", "U", 34.0, 17.0, 1, 17, , , , , , , 17, , , , , , , , , , , , , , , , , , , , ]
,["BROUGHTON B", "U", 46.0, 23.0, 1, 23, , , , , , , , , 23, , , , , , , , , , , , , , , , , , ]
,["WATKINS N", "U", 48.0, 24.0, 1, 24, , , , , , , , , 24, , , , , , , , , , , , , , , , , , ]
,["WILSON M", "U", 56.0, 28.0, 1, 28, , , , , , , , , 28, , , , , , , , , , , , , , , , , , ]
,["HOLLOWAY CREIG", "U", 55.0, 27.5, 2, 55, , , , , , , , , 28, , , , , , , , 27, , , , , , , , , , ]
,["JOHNSON M", "U", 40.7, 20.3, 6, 122, , , , , , , , , , , , 14, 26, 22, 27, , , , , , 19, , 14, , , , ]
,["DAVEY A", "U", 42.0, 21.0, 1, 21, , , , , , , , , , , , , , 21, , , , , , , , , , , , , ]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 34, "average": 29.9, "high": 45.0, "low": 16.0},
{"numberOfEntrants": 28, "average": 22.1, "high": 34.0, "low": 9.0},
{"numberOfEntrants": 19, "average": 33.4, "high": 46.0, "low": 2.0},
{"numberOfEntrants": 31, "average": 21.9, "high": 30.0, "low": 10.0},
{"numberOfEntrants": 20, "average": 23.8, "high": 39.0, "low": 9.0},
{"numberOfEntrants": 25, "average": 26.5, "high": 39.0, "low": 11.0},
{"numberOfEntrants": 28, "average": 32.6, "high": 43.0, "low": 17.0},
{"numberOfEntrants": 26, "average": 22.2, "high": 35.0, "low": 9.0},
{"numberOfEntrants": 34, "average": 30.4, "high": 42.0, "low": 11.0},
{"numberOfEntrants": 28, "average": 33.0, "high": 47.0, "low": 18.0},
{"numberOfEntrants": 25, "average": 27.2, "high": 41.0, "low": 14.0},
{"numberOfEntrants": 26, "average": 30.6, "high": 44.0, "low": 13.0},
{"numberOfEntrants": 29, "average": 29.6, "high": 43.0, "low": 16.0},
{"numberOfEntrants": 20, "average": 32.2, "high": 41.0, "low": 17.0},
{"numberOfEntrants": 26, "average": 31.7, "high": 43.0, "low": 19.0},
{"numberOfEntrants": 30, "average": 31.1, "high": 46.0, "low": 17.0},
{"numberOfEntrants": 22, "average": 30.7, "high": 45.0, "low": 9.0},
{"numberOfEntrants": 30, "average": 32.4, "high": 43.0, "low": 17.0},
{"numberOfEntrants": 20, "average": 28.8, "high": 43.0, "low": 15.0},
{"numberOfEntrants": 26, "average": 30.6, "high": 43.0, "low": 17.0},
{"numberOfEntrants": 32, "average": 29.6, "high": 44.0, "low": 10.0},
{"numberOfEntrants": 29, "average": 25.4, "high": 37.0, "low": 10.0},
{"numberOfEntrants": 31, "average": 24.3, "high": 35.0, "low": 7.0},
{"numberOfEntrants": 2, "average": 294.0, "high": 541.0, "low": 47.0},
{"numberOfEntrants": 2, "average": 13545.2, "high": 27050.0, "low": 40.5},
];
