import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", "CLASS 01/01/15", "PERCENTAGE", "Raw Average", "No. shoots", "Total score", "JAN 4th", "JAN 18th", "FEB 1st", "FEB 15th", "MAR 1st", "MAR 15th", "APR 5th", "APR 19th", "MAY 3rd", "MAY 17th", "JUNE 7th", "JUNE 21st", "JULY 5th", "JULY 19th", "AUG 16th", "AUG 30th", "SEPT 6th", "SEPT 20th", "OCT 4th", "OCT 18th", "NOV 1st", "NOV 15th", "DEC 6th", "DEC 20th"];

export const results: (string | number | undefined)[][] = [
["ASHROFF ASH", "C", 32.0, 16.0, 1, 16, , , , , , , , , , , , , , , , , 16, , , , , , , ]
,["ASHROFF SOPHIA (Colt)", "C", 14.0, 7.0, 1, 7, , , , , , , , , , , , , , , , , 7, , , , , , , ]
,["BAILEY MIKE", "B", 64.7, 32.3, 3, 97, , , , , , , , , , , , , 31, , 31, 35, , , , , , , , ]
,["BIRD ARTHUR", "B", 49.3, 24.7, 6, 148, 24, , , 20, , , , , , , 29, , , , , 24, , , 31, 20, , , , ]
,["CAMERON DEAN", "NM", 45.1, 22.6, 9, 203, , , , , , , , , , , , , , 18, 21, , 15, , 25, 19, 20, 28, 28, 29]
,["CAMERON JOHANNA", "NM", 25.7, 12.8, 6, 77, , , , , , , , , , , , , , , 3, , 16, , 17, 12, 18, , 11, ]
,["CATLING RICHARD", "B", 63.0, 31.5, 10, 315, 33, , , , , , , , , 37, 28, 30, , , , , 35, 26, 32, , , 33, 26, 35]
,["COLE BERNARD", "A", 71.5, 35.7, 19, 679, 37, 39, , 34, 37, 36, 32, 34, 39, 40, 29, 33, , 37, 36, , , 39, , 32, 41, 35, 30, 39]
,["CULLINGHAM SAM", "U", 62.0, 31.0, 1, 31, , , , , , , , , , , , , , , , , , , , 31, , , , ]
,["DAWSON PETER", "A", 67.2, 33.6, 17, 571, 35, 37, 30, 31, 38, , , , 26, 39, 29, , 34, 25, 34, 36, , 29, 42, 33, 36, , , 37]
,["DE SILVA RICKY", "C", 51.1, 25.5, 17, 434, , 30, 22, 24, 28, 21, 29, 22, 16, 28, , 30, , 28, 25, , , 25, 26, 27, , 27, , 26]
,["DI MOND HANK", "AA", 69.2, 34.6, 15, 519, , , , , , , 30, 31, 38, 37, 36, 29, 30, 29, 39, , , , 41, 31, 45, 37, 33, 33]
,["DOYLE PETER", "NM", 73.6, 36.8, 5, 184, , , , , , , , , , , , , , , , , , , 44, 29, 43, , 34, 34]
,["DOYLE RAY", "NM", 66.4, 33.2, 5, 166, , , , , , , , , , , , , , , , , , , 40, 26, 32, , 31, 37]
,["DUFFELL ERIC", "A", 67.8, 33.9, 8, 271, 41, , , , , , , , , , , 31, 29, , , 34, 35, , 34, , , 39, 28, ]
,["DUKE NEIL", "AA", 70.2, 35.1, 11, 386, 43, , 34, 31, , , , , , , , , 40, , 31, , 34, , 37, 32, 40, 30, 34, ]
,["ELYSEE MICHAEL", "C", 50.0, 25.0, 1, 25, , , , , 25, , , , , , , , , , , , , , , , , , , ]
,["ESCOTT PAUL", "A", 68.0, 34.0, 2, 68, , , , , , , , , , 32, , , , , , , , , , , 36, , , ]
,["ESCOTT RICHARD", "AA", 75.7, 37.9, 22, 833, 42, 39, 36, 36, , 33, 37, 35, 36, 43, 37, 26, 38, 38, 38, , 46, 35, 45, 40, 39, 41, 37, 36]
,["ESCOTT TIM", "AA", 80.1, 40.1, 18, 721, 41, , 41, 34, , , , 40, 39, 41, 38, 38, 42, 42, 38, , 43, , 38, 43, 41, 45, 38, 39]
,["EUSTACE DAVE", "AAA", 82.1, 41.0, 21, 862, 42, 48, 36, 41, 42, 38, 46, 42, 43, 45, 37, 33, 47, 40, , 39, 37, 32, 43, , , 40, 39, 52]
,["FARAZAMAND J", "AA", 74.4, 37.2, 1, 186, 34, , , 39, , , , 41, 38, , , 34, , , , , , , , , , , , ]
,["FARAZAMAND A", "AA", 63.5, 31.8, 1, 127, 30, , , 33, , , , 34, , , , 30, , , , , , , , , , , , ]
,["FERGUSON PHILIP", "A", 66.0, 33.0, 3, 99, , , , 30, 36, , , , , , , , , , , , , , , , , 33, , ]
,["FIANDER PETER", "A", 63.1, 31.5, 15, 473, 37, , 28, 29, , 23, 27, 30, 27, 35, , 33, 36, , , , 32, 35, 36, , 28, , , 37]
,["GODDARD TREVOR", "B", 60.1, 30.1, 17, 511, 34, 27, , 25, 30, 25, , , , 26, 32, 31, 23, , 23, 37, 37, , 36, 29, 27, 31, , 38]
,["HEALEY LES", "AAA", 82.8, 41.4, 19, 787, 48, 45, 36, 32, , 46, 42, 38, , , 41, 30, 42, 39, , 43, 48, 37, 43, 41, 43, 42, , 51]
,["HICKMAN EDWARD", "B", 54.3, 27.1, 7, 190, , , 26, 22, 26, , , , , , 26, , , , , 32, , , 32, , , , , 26]
,["HICKMAN JACK (Colt)", "C", 53.3, 26.7, 3, 80, , , , , 30, , , , , , 26, , , , , , , , , , , , , 24]
,["HOLLOWAY NICK", "A", 63.3, 31.7, 15, 475, 32, , , , , , , 24, 33, 41, 28, 36, 27, 39, 27, 35, , 26, , 35, 34, 33, , 25]
,["HOOLEY PETER", "B", 57.8, 28.9, 16, 462, 27, 29, , 28, 34, 27, 23, , 26, , , 29, 26, 24, , , 31, , 34, , 36, 29, 23, 36]
,["HUMPHREYS CLIFF", "C", 39.0, 19.5, 2, 39, , , , , , 18, , 21, , , , , , , , , , , , , , , , ]
,["HUTCHINGS WILLIAM", "NM", 50.5, 25.3, 16, 404, , 21, , 20, 23, 16, , , , 28, 26, , 17, , 29, 31, 28, , 31, 23, 28, 28, 32, 23]
,["HUTCHINGS LYNNE", "NM", 18.9, 9.4, 7, 66, , , , , , , , , , , , , , , 5, 9, 6, , , 8, 10, , 13, 15]
,["JACKSON COLIN", "C", 42.0, 21.0, 15, 315, , 21, 15, , , 17, 18, , 18, 25, , 26, , 24, 14, , , , 26, 29, 23, 27, 18, 14]
,["JAY CHRIS", "AA", 78.8, 39.4, 18, 709, 46, 44, 37, 43, 44, , , 40, , 45, 37, 37, 40, 33, 38, , , 35, 43, 32, , 34, 31, 50]
,["JOHNSON MICHAEL", "B", 64.3, 32.2, 12, 386, 38, 31, 30, , , 35, , , 29, 38, 34, , , , , 36, 30, 24, , , , 35, 26, ]
,["KESHAVJEE RAHEEM", "C", 48.0, 24.0, 3, 72, , , , , , , , , , 27, , 26, , , , , , 19, , , , , , ]
,["KING JONATHAN", "C", 38.5, 19.3, 4, 77, , 18, , , , , , 19, , , , , , , , , 19, 21, , , , , , ]
,["KIRK DARREN", "B", 60.0, 30.0, 16, 480, 28, 21, 22, 29, 31, , , , , 39, 38, , 24, 32, , 31, , 32, 35, 32, , 28, 27, 31]
,["LOCKYER SIMON", "B", 75.0, 37.5, 10, 375, 36, 38, , , 41, , , , 31, 44, 38, , , , , 43, 35, 30, , , , , , 39]
,["MARCHETTI ALAN", "B", 55.7, 27.8, 6, 167, , , , 25, , , 31, , , , , , 28, , , 30, , , , , , 27, 26, ]
,["O'CONNOR JOHN", "B", 59.0, 29.5, 2, 59, , 28, , , , 31, , , , , , , , , , , , , , , , , , ]
,["OFFEN COLIN", "A", 80.7, 40.3, 3, 121, 40, , , 41, , , , , , , , , , , , , 40, , , , , , , ]
,["O'GORMAN ROY", "A", 71.8, 35.9, 12, 431, , 34, , , , 30, , , , 38, 32, , 41, , 39, , 36, , 40, 32, 39, 33, , 37]
,["PAYNE BARRIE", "A", 69.3, 34.7, 3, 104, , , , , , , , 28, , 41, , , , , , , 35, , , , , , , ]
,["PRIOR NICK", "C", 49.4, 24.7, 19, 469, 25, , , 26, 28, 20, , , , 32, 36, 22, 17, 22, 20, 32, 31, 18, 24, 24, 29, 21, 19, 23]
,["PVK", "A", 76.0, 38.0, 3, 114, , , , , , , , , , 37, , , , , , 32, , , , , , 45, , ]
,["RICKETTS DAVE", "B", 51.2, 25.6, 17, 435, 28, 27, 16, 24, 30, , 32, 29, 24, 28, , 27, , , 16, 29, 24, 25, 25, 22, 29, , , ]
,["SALUSSOLIA ALEX", "C", 68.7, 34.3, 3, 103, , , , , , , , , , 41, 34, , , , , , , , , , 28, , , ]
,["SHARP RUSSELL", "B", 63.5, 31.8, 20, 635, 36, 35, 25, 26, 43, 20, , 27, 29, 41, , 26, 36, 32, 36, 33, , 29, , 28, 38, 32, 26, 37]
,["SULLIVAN MARTIN", "NM", 55.0, 27.5, 2, 55, , 29, , , , , , , , , , , , , , , , 26, , , , , , ]
,["TAYLOR PETER", "A", 73.0, 36.5, 16, 584, 34, 39, 34, 30, " ", 37, 26, , 39, 38, , , 40, , , , , 33, 46, 36, 40, 40, 33, 39]
,["THOMAS DUNCAN", "A", 70.1, 35.1, 20, 701, 40, , 28, 39, 36, 30, 34, 31, 32, , , 26, 39, 35, 42, 40, , 33, 43, 35, 39, 35, 29, 35]
,["TOWNLEY JOHN", "AAA", 65.5, 32.7, 11, 360, , , , , 34, , 41, 32, 33, 44, 38, , 32, 36, , , , 16, , , , , 22, 32]
,["TRUIN FRANK", "A", 75.5, 37.8, 16, 604, 35, 37, 35, , 40, , , 36, , 43, 35, , 36, , 41, 44, 35, 33, 43, 35, 33, , , 43]
,["WHITE GRAHAM", "B", 51.2, 25.6, 5, 128, , 31, 16, 24, " ", , , , , 25, , , , , , , , , , , 32, , , ]
,["WHITEHEAD JOHN", "B", 65.1, 32.6, 9, 293, , 34, , 29, 35, , , , , , , , , , 28, 34, 35, 29, , , , 37, , 32]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 27, "average": 35.8, "high": 48.0, "low": 24.0},
{"numberOfEntrants": 24, "average": 32.6, "high": 48.0, "low": 18.0},
{"numberOfEntrants": 19, "average": 28.8, "high": 41.0, "low": 15.0},
{"numberOfEntrants": 28, "average": 30.2, "high": 43.0, "low": 20.0},
{"numberOfEntrants": 21, "average": 33.9, "high": 44.0, "low": 23.0},
{"numberOfEntrants": 18, "average": 27.9, "high": 46.0, "low": 16.0},
{"numberOfEntrants": 14, "average": 32.0, "high": 46.0, "low": 18.0},
{"numberOfEntrants": 20, "average": 31.7, "high": 42.0, "low": 19.0},
{"numberOfEntrants": 19, "average": 31.4, "high": 43.0, "low": 16.0},
{"numberOfEntrants": 30, "average": 36.6, "high": 45.0, "low": 25.0},
{"numberOfEntrants": 23, "average": 33.2, "high": 41.0, "low": 26.0},
{"numberOfEntrants": 22, "average": 30.1, "high": 38.0, "low": 22.0},
{"numberOfEntrants": 24, "average": 33.1, "high": 47.0, "low": 17.0},
{"numberOfEntrants": 18, "average": 31.8, "high": 42.0, "low": 18.0},
{"numberOfEntrants": 23, "average": 28.4, "high": 42.0, "low": 3.0},
{"numberOfEntrants": 22, "average": 33.6, "high": 44.0, "low": 9.0},
{"numberOfEntrants": 26, "average": 30.2, "high": 48.0, "low": 6.0},
{"numberOfEntrants": 24, "average": 28.6, "high": 39.0, "low": 16.0},
{"numberOfEntrants": 29, "average": 35.6, "high": 46.0, "low": 17.0},
{"numberOfEntrants": 28, "average": 29.1, "high": 43.0, "low": 8.0},
{"numberOfEntrants": 28, "average": 33.1, "high": 45.0, "low": 10.0},
{"numberOfEntrants": 28, "average": 33.8, "high": 45.0, "low": 21.0},
{"numberOfEntrants": 25, "average": 27.8, "high": 39.0, "low": 11.0},
{"numberOfEntrants": 32, "average": 33.9, "high": 52.0, "low": 14.0},
];
