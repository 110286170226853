import { Column, ColumnType } from "../data/results";
import _ from "lodash";

export const isHeader = (column: Column): boolean => {
  return column.type === ColumnType.header;
};

export const getHeaderColumns = (columns: Column[]): Column[] => {
  return _.filter(columns, column => isHeader(column));
};
