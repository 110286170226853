import _ from "lodash";

export interface Squad {
  name: string;
  filterNames: string[];
}

const unorderedSquads: Squad[] = [
  { name: "Escotts", filterNames: ["ESCOTT", "O'CONNOR JOHN", "CHRISTIE KEN"] },
  {
    name: "Little and Offen",
    filterNames: [
      "PVK",
      "HANK",
      "THOMAS DUNCAN",
      "TAYLOR PETER",
      "OFFEN COLIN",
      "DUKE NEIL",
      "TURNER CARLY",
      "HEALEY LES",
    ],
  },
  { name: "Hickmans", filterNames: ["HICKMAN", "BIRD ARTHUR"] },
  {
    name: "The Good, the Bad and the Ugly",
    filterNames: [
      "SHARP RUSSELL",
      "JACKSON COLIN",
      "WHITE ROGER",
      "JAY CHRIS",
      "DE SILVA RICKY",
      "VOWLER ALAN",
    ],
  },
  {
    name: "Hooley's Last Stand",
    filterNames: [
      "COLE BERNARD",
      "CUNNINGHAM SAM",
      "HOOLEY PETER",
      "WILLIAMS SID",
      "DAWSON PETER",
    ],
  },
  // {
  //   name: "In and Out",
  //   filterNames: ["TRUIN FRANK"]
  // },
  // {
  //   name: "Alan Marchetti",
  //   filterNames: [
  //     "MARCHETTI ALAN",
  //     "CATLING RICHARD",
  //     "DUFFELL ERIC",
  //     "FIANDER PETER",
  //     "O'GORMAN ROY"
  //   ]
  // }
  // {
  //   name: "Dean Cameron",
  //   filterNames: ["CAMERON DEAN"]
  // }
];

export const squads = _.sortBy(unorderedSquads, (squad: Squad) => squad.name);
