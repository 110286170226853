import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", "CLASS 01/01/17", "PERCENTAGE", "Raw Average", "No. shoots", "Total score", "JAN 2nd", "JAN 15th", "FEB 5th", "FEB 19th", "MAR 5th", "MAR 19th", "APR 2nd", "APR 16th", "MAY 7th", "MAY 21st", "JUNE4th", "JUNE 18th", "JULY 2nd", "JULY 16th", "AUG 6th", "AUG 13th", "SEPT 3rd", "SEPT 17th", "OCT 1st", "OCT 15th", "NOV 5th", "NOV 19th", "DEC 3rd", "DEC 17th"];

export const results: (string | number | undefined)[][] = [
["BIGGS STEVE", "U", 62.0, 31.0, 2, 62, , , , , , 31, , , , , , , , , , , 31, , , , , , , ]
,["BIRD ARTHUR", "C", 30.0, 15.0, 1, 15, , , , , , , , , , , 15, , , , , , , , , , , , , ]
,["CAMERON DEAN", "B", 65.5, 32.8, 8, 262, , , 27, 28, , 32, , 35, , , 45, , , , , , 35, , , , 31, 29, , ]
,["CANNON DEREK", "U", 66.7, 33.3, 3, 100, , , , , , , , , , , 40, , , 32, , , , , , , 28, , , ]
,["CATLING RICHARD", "B", 56.9, 28.4, 7, 199, , , , , , 22, , 28, , , , , , 30, , 29, 31, , , 27, , , , 32]
,["COLE BERNARD", "A", 63.7, 31.8, 19, 605, 38, , 34, 26, 27, 35, 29, 36, 26, 30, 40, 26, , , , 36, 32, 37, 29, 35, 31, 28, 30, ]
,["DAWSON PETER", "A", 63.9, 31.9, 15, 479, , 28, , , 29, 38, 25, , 33, 31, 40, 36, , , 37, 25, , 36, , 30, 38, 25, , 28]
,["DE SILVA RICKY", "B", 50.4, 25.2, 19, 479, 34, 20, 29, 19, 21, 34, , 29, 20, 19, 33, 28, 23, 21, , 22, 26, , , , 23, 25, 22, 31]
,["DI MOND HANK", "A", 64.0, 32.0, 9, 288, , , 40, 27, 32, 27, , , 26, , , , 35, , , , , 34, , , 34, , 33, ]
,["DOYLE PETER", "B", 63.8, 31.9, 8, 255, , , , 26, , 34, , , , , 41, , 32, 32, 28, , , 29, 33, , , , , ]
,["DOYLE RAY", "B", 61.5, 30.8, 8, 246, , , , 29, , 34, , , , , 41, , 31, 31, 23, , , 37, 20, , , , , ]
,["O'DRISCOL GRAHAM", "NM", 35.3, 17.7, 3, 53, 22, 20, , , , , , , , , 11, , , , , , , , , , , , , ]
,["DUFFELL ERIC", "AA", 66.8, 33.4, 13, 434, , , , , 38, , , 29, , 31, 41, 31, , 35, , 29, 39, 33, , 34, , 26, 29, 39]
,["DUKE NEIL", "A", 64.5, 32.3, 12, 387, 34, , 33, 25, , 35, 30, , 38, 25, 35, , 34, , 27, 33, 38, , , , , , , ]
,["EBERS NEIL", "NM", 53.6, 26.8, 14, 375, 32, 39, 33, 23, , 31, , , 29, , 36, , 21, 20, , 20, 19, , , 33, 21, 18, , ]
,["ELYSEE MICHAEL", "C", 43.0, 21.5, 2, 43, , , 28, , , , , , , , , , , , , , , , 15, , , , , ]
,["ESCOTT PAUL", "B", 53.0, 26.5, 2, 53, 38, , , 15, , , , , , , , , , , , , , , , , , , , ]
,["ESCOTT RICHARD", "AA", 69.1, 34.5, 19, 656, 38, , 33, 26, 32, 36, 35, 36, 32, 38, , 36, 32, 36, 36, 36, , , 37, 39, 31, 34, 33, ]
,["ESCOTT TIM", "AAA", 78.3, 39.2, 18, 705, , , 39, 28, 41, 42, 40, 43, 39, 41, , 41, , 42, 35, 44, , 43, 34, , 38, 36, 37, 42]
,["EUSTACE DAVE", "AAA", 82.2, 41.1, 13, 534, 44, 39, 42, , , , , , 40, 45, 40, 38, 42, 43, , , 31, , , 45, 46, 39, , ]
,["FARAZAMAND J", "A", 76.7, 38.3, 3, 115, 43, , , , , , , 40, 32, , , , , , , , , , , , , , , ]
,["FARAZAMAND A", "A", 82.0, 41.0, 1, 41, 41, , , , , , , , , , , , , , , , , , , , , , , ]
,["FARAZAMAND WARREN", "AA", 82.0, 41.0, 1, 41, 41, , , , , , , , , , , , , , , , , , , , , , , ]
,["FIANDER PETER", "B", 56.3, 28.1, 15, 422, 39, , 34, , 25, 26, 30, 33, , 25, , 25, , , 24, 25, 28, 32, , 29, 26, 21, , ]
,["HEALEY LES", "AAA", 79.9, 39.9, 18, 719, , , 44, 32, 43, 37, 34, , 44, 39, 46, , , 28, 40, 43, 38, , 44, 48, 36, 36, 43, 44]
,["HICKMAN EDWARD", "C", 38.8, 19.4, 5, 97, , , 27, , , , 18, , , , 18, , , , 16, , , , 18, , , , , ]
,["HICKMAN JACK (Colt)", "B", 43.0, 21.5, 2, 43, , , , , , , 19, , , , , , , , 24, , , , , , , , , ]
,["HOLLOWAY NICK", "A", 58.4, 29.2, 9, 263, 31, , , , , , , 35, , 23, 34, , , , , 29, 26, , 28, 34, 23, , , ]
,["HOOLEY PETER", "B", 55.3, 27.7, 6, 166, 24, , , , , , , , , , , , 31, 28, 22, , , , , , 35, 26, , ]
,["HUMPHREYS CLIFF", "C", 37.7, 18.8, 6, 113, , , , , , , , , , , 24, , , 19, 18, , , , , 17, , 18, 17, ]
,["HUNT JAMES", "C", 62.0, 31.0, 1, 31, , , 31, , , , , , , , , , , , , , , , , , , , , ]
,["HUNT STEPHEN", "C", 46.0, 23.0, 1, 23, , , 23, , , , , , , , , , , , , , , , , , , , , ]
,["HUTCHINGS WILLIAM", "B", 53.3, 26.6, 14, 373, 31, 21, , 20, , 24, , , 30, 23, 33, 32, 26, , , 29, , 29, , 31, , 21, , 23]
,["HUTCHINGS LYNNE", "C", 27.8, 13.9, 10, 139, , 14, , , , 17, , , 14, 18, , 10, 15, , , 16, , , , 13, , 14, , 8]
,["JACKSON COLIN", "B", 45.9, 23.0, 20, 459, 34, 22, 25, 19, 26, , 18, 27, 24, 21, 27, 34, 21, 21, 20, 18, 20, , 16, , , 22, 19, 25]
,["JAY CHRIS", "AA", 71.9, 35.9, 16, 575, 46, 32, , , 35, , 36, , 33, 35, 43, 28, 36, 35, 41, 35, , 36, , , 34, , 38, 32]
,["JOHNSON MICHAEL", "A", 60.4, 30.2, 15, 453, , 25, , 31, , 37, 26, , 32, 28, , 28, , 28, , , , 27, 29, 37, 35, 25, 31, 34]
,["KIRK DARREN", "B", 55.3, 27.7, 15, 415, 28, 29, , , , 32, 34, 26, 23, 30, , 24, , 20, 31, 24, 23, 28, 27, , , , , 36]
,["MADDAMS LLOYD", "A", 32.0, 16.0, 1, 16, , , , , 16, , , , , , , , , , , , , , , , , , , ]
,["MARCHETTI ALAN", "B", 57.7, 28.8, 6, 173, , , , , , 26, , , , , 30, , , 32, , , , , , , 34, 26, , 25]
,["OFFEN COLIN", "AA", 77.8, 38.9, 11, 428, , , , , , , , , , , 40, 46, , 41, , 34, 39, 43, 41, 43, 37, 39, 25, ]
,["O'CONNOR JOHN", "U", 48.0, 24.0, 2, 48, , , , , , , , , , , , , 20, , , , , , , , , , 28, ]
,["O'DRISCOLL GRAHAM", "U", 42.3, 21.2, 6, 127, , , , , , , , , , 30, , , 12, , , 19, , 25, , , , 22, , 19]
,["O'GORMAN ROY", "B", 63.1, 31.6, 14, 442, 37, , 34, , 32, 28, 26, 36, , 28, 40, 31, , 31, , 32, , , , 34, , 25, 28, ]
,["PRIOR NICK", "C", 48.9, 24.4, 14, 342, , , , , , , , 27, 21, 24, 27, , , , 17, 25, 17, 27, 27, 38, 25, 22, 19, 26]
,["PVK", "NM", 72.0, 36.0, 5, 180, , , 40, , , , 33, , , , , , , 31, , 41, , , , , , , , 35]
,["SHARP RUSSELL", "A", 60.6, 30.3, 17, 515, 41, 28, 33, 23, 29, 36, , 36, , 26, 32, 25, " ", 22, 26, 34, , 38, , , 34, 28, , 24]
,["SULLIVAN MARTIN", "A", 63.0, 31.5, 2, 63, , , 37, 26, , , , , , , , , , , , , , , , , , , , ]
,["TAYLOR PETER", "A", 71.3, 35.6, 17, 606, 38, 34, 40, 29, 31, , 42, 40, 34, 33, , 30, 38, , 30, 39, , , 41, 38, , , 37, 32]
,["THOMAS DUNCAN", "A", 59.2, 29.6, 21, 622, 33, , 27, 23, 21, 28, 36, 37, 32, 31, , 26, 26, 27, 32, 33, 29, 29, 31, , 35, 28, 27, 31]
,["TOWNLEY JOHN", "A", 62.0, 31.0, 5, 155, , , , , , 26, 30, , 36, , , , , , , 33, , 30, , , , , , ]
,["TRUIN FRANK", "AA", 68.7, 34.4, 14, 481, , , 41, , , 31, 35, 34, , 39, 43, 23, 34, 31, , 32, 36, 33, , 42, , , 27, ]
,["TURNER CARLY", "NM", 46.2, 23.1, 10, 231, , , , 19, 16, 16, , , 20, 25, 35, , 25, , 27, 28, 20, , , , , , , ]
,["TURNER MICHAEL", "NM", 56.0, 28.0, 6, 168, , , , 25, 31, 29, , , , 27, 32, , , , , , 24, , , , , , , ]
,["VOWLER ALAN", "C", 40.2, 20.1, 18, 362, 23, 19, 23, , 11, 22, 21, 24, 22, 16, 27, 22, 12, , 17, , 22, 23, 14, , , , 20, 24]
,["WHITE ROGER", "NM", 28.8, 14.4, 5, 72, , , , , , , , , , , , , , , , , , 20, 16, , 13, 9, , 14]
,["WHITE GRAHAM", "B", 51.0, 25.5, 2, 51, , , , , , , 19, , , , , , , , , , , , , , 32, , , ]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 23, "average": 35.2, "high": 46.0, "low": 22.0},
{"numberOfEntrants": 14, "average": 26.4, "high": 39.0, "low": 14.0},
{"numberOfEntrants": 24, "average": 33.2, "high": 44.0, "low": 23.0},
{"numberOfEntrants": 21, "average": 24.7, "high": 32.0, "low": 15.0},
{"numberOfEntrants": 19, "average": 28.2, "high": 43.0, "low": 11.0},
{"numberOfEntrants": 28, "average": 30.2, "high": 42.0, "low": 16.0},
{"numberOfEntrants": 21, "average": 29.3, "high": 42.0, "low": 18.0},
{"numberOfEntrants": 19, "average": 33.2, "high": 43.0, "low": 24.0},
{"numberOfEntrants": 23, "average": 29.6, "high": 44.0, "low": 14.0},
{"numberOfEntrants": 27, "average": 28.9, "high": 45.0, "low": 16.0},
{"numberOfEntrants": 29, "average": 34.1, "high": 46.0, "low": 11.0},
{"numberOfEntrants": 21, "average": 29.5, "high": 46.0, "low": 10.0},
{"numberOfEntrants": 20, "average": 27.3, "high": 42.0, "low": 12.0},
{"numberOfEntrants": 24, "average": 29.8, "high": 43.0, "low": 19.0},
{"numberOfEntrants": 21, "average": 27.2, "high": 41.0, "low": 16.0},
{"numberOfEntrants": 28, "average": 30.1, "high": 44.0, "low": 16.0},
{"numberOfEntrants": 21, "average": 28.8, "high": 39.0, "low": 17.0},
{"numberOfEntrants": 21, "average": 31.9, "high": 43.0, "low": 20.0},
{"numberOfEntrants": 18, "average": 27.8, "high": 44.0, "low": 14.0},
{"numberOfEntrants": 19, "average": 34.1, "high": 48.0, "low": 13.0},
{"numberOfEntrants": 23, "average": 31.3, "high": 46.0, "low": 13.0},
{"numberOfEntrants": 25, "average": 25.7, "high": 39.0, "low": 9.0},
{"numberOfEntrants": 19, "average": 28.6, "high": 43.0, "low": 17.0},
{"numberOfEntrants": 21, "average": 28.8, "high": 44.0, "low": 8.0},
];
